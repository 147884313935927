import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Table,
  Tag,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { voices } from "../../../data/constants";
import { selectUser } from "../../../redux/auth/selectors";
import CrudService from "../../../service/CrudService";

const { Option } = Select;

const CallAgents = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [assistants, setAssistants] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [phoneModalVisible, setPhoneModalVisible] = useState(false);
  const [activateModalVisible, setActivateModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [phoneForm] = Form.useForm();
  const [editingAssistant, setEditingAssistant] = useState(null);
  const [activatingAssistant, setActivatingAssistant] = useState(null);

  const vapiAxios = axios.create({
    baseURL: "https://api.vapi.ai",
    headers: { Authorization: `Bearer ${process.env.REACT_APP_VAPI_TOKEN}` },
  });

  useEffect(() => {
    fetchAssistants();
    fetchPhoneNumbers();
  }, []);

  const fetchAssistants = async () => {
    setLoading(true);
    try {
      const dbAssistantsResponse = await CrudService.search(
        "Assistant",
        100,
        1,
        {
          filters: { user_id: user._id },
        }
      );
      const dbAssistants = dbAssistantsResponse.data.items;

      const mergedAssistants = await Promise.all(
        dbAssistants.map(async (dbAssistant) => {
          try {
            const vapiResponse = await vapiAxios.get(
              `/assistant/${dbAssistant.vapi_id}`
            );
            return { ...vapiResponse.data, ...dbAssistant };
          } catch (error) {
            console.error(
              `Error fetching VAPI assistant ${dbAssistant.vapi_id}:`,
              error
            );
            return dbAssistant;
          }
        })
      );

      setAssistants(mergedAssistants);
    } catch (error) {
      console.error("Error fetching assistants:", error);
      message.error(t("Failed to fetch assistants"));
    } finally {
      setLoading(false);
    }
  };

  const fetchPhoneNumbers = async () => {
    try {
      const response = await CrudService.search("PhoneNumber", 100, 1, {
        filters: { user_id: user._id },
      });
      setPhoneNumbers(response.data.items);
    } catch (error) {
      console.error("Error fetching phone numbers:", error);
      message.error(t("Failed to fetch phone numbers"));
    }
  };

  const handleCreate = () => {
    setEditingAssistant(null);
    form.resetFields();
    setModalVisible(true);
  };

  const handleEdit = (assistant) => {
    setEditingAssistant(assistant);

    const language = assistant.transcriber?.language;
    const voiceId = assistant.voice?.voiceId;
    const gender = voiceId === voices[language]?.male ? "male" : "female";

    form.setFieldsValue({
      ...assistant,
      gender,
      language,
      speed: assistant.voice?.speed,
      taskDescription: assistant.model?.messages?.[0]?.content,
      forwardNumber: assistant.model?.tools?.[0]?.destinations?.[0]?.number,
    });
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const dbAssistant = assistants.find((a) => a._id === id);
      if (!dbAssistant?.vapi_id) {
        throw new Error("VAPI ID not found");
      }

      await vapiAxios.delete(`/assistant/${dbAssistant.vapi_id}`);
      await CrudService.delete("Assistant", id);

      message.success(t("Assistant deleted successfully"));
      fetchAssistants();
    } catch (error) {
      console.error("Error deleting assistant:", error);
      message.error(t("Failed to delete assistant"));
    }
  };

  const handleSubmit = async (values) => {
    try {
      let vapiResponse;
      const {
        gender,
        language,
        speed,
        taskDescription,
        forwardNumber,
        ...rest
      } = values;
      const voice = voices[language];
      const assistantData = {
        ...rest,
        transcriber: {
          language,
          keywords: [],
          provider: "deepgram",
          model: "nova-2-general",
        },
        model: {
          provider: "openai",
          model: "gpt-4o",
          fallbackModels: ["gpt-4", "gpt-3.5-turbo"],
          messages: [
            {
              role: "system",
              content: taskDescription,
            },
          ],
          tools: forwardNumber
            ? [
                {
                  type: "transferCall",
                  destinations: [
                    {
                      type: "number",
                      number: forwardNumber,
                    },
                  ],
                },
              ]
            : [],
        },
        voice: {
          speed,
          provider: voice.provider,
          voiceId: voice[gender],
        },
        recordingEnabled: true,
        backgroundSound: "office",
      };

      if (editingAssistant) {
        vapiResponse = await vapiAxios.patch(
          `/assistant/${editingAssistant.vapi_id}`,
          assistantData
        );
        await CrudService.update("Assistant", editingAssistant._id, {
          ...assistantData,
        });
        message.success(t("Assistant updated successfully"));
      } else {
        vapiResponse = await vapiAxios.post("/assistant", assistantData);
        await CrudService.create("Assistant", {
          ...assistantData,
          user_id: user._id,
          vapi_id: vapiResponse.data.id,
        });
        message.success(t("Assistant created successfully"));
      }
      setModalVisible(false);
      fetchAssistants();
    } catch (error) {
      console.error("Error submitting assistant:", error);
      message.error(t("Failed to submit assistant"));
    }
  };

  const handleAddPhone = async (values) => {
    try {
      // Create the number in Twilio

      // Attach the assistant's vapi_id to the phone number in VAPI
      const res = await vapiAxios.post(`/phone-number`, {
        provider: "twilio",
        assistantId: activatingAssistant.vapi_id,
        ...values,
      });

      // Add the phone number to our database
      await CrudService.create("PhoneNumber", {
        ...values,
        user_id: user._id,
        vapi_id: res.data.id,
      });
      message.success(t("Phone number added successfully"));
      setPhoneModalVisible(false);
      fetchPhoneNumbers();
    } catch (error) {
      console.error("Error adding phone number:", error);
      message.error(t("Failed to add phone number"));
    }
  };

  const handleActivate = async (phoneId) => {
    try {
      // Attach the assistant's vapi_id to the phone number in VAPI
      await vapiAxios.patch(`/phone-number/${phoneId}`, {
        assistantId: activatingAssistant.vapi_id,
      });

      // Update the assistant's status in our database
      await CrudService.update("Assistant", activatingAssistant._id, {
        status: "activated",
        phoneId: phoneId,
      });

      message.success(t("Assistant activated successfully"));
      setActivateModalVisible(false);
      fetchAssistants();
    } catch (error) {
      console.error("Error activating assistant:", error);
      message.error(t("Failed to activate assistant"));
    }
  };

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("First Message"),
      dataIndex: "firstMessage",
      key: "firstMessage",
    },
    {
      title: t("Language"),
      dataIndex: ["voice", "language"],
      key: "language",
      render: (_, record) => {
        const language = record.transcriber?.language;
        return language;
      },
    },
    {
      title: t("Gender"),
      dataIndex: ["voice", "voiceId"],
      key: "gender",
      render: (_, record) => {
        const voiceId = record.voice?.voiceId;
        const language = record.transcriber?.language;
        return voiceId === voices[language]?.male ? t("Male") : t("Female");
      },
    },
    {
      title: t("Speed"),
      dataIndex: ["voice", "speed"],
      key: "speed",
    },

    {
      title: t("Actions"),
      key: "actions",
      render: (_, record) => (
        <div className="flex gap-1">
          <Button size="small" onClick={() => handleEdit(record)}>
            {t("Edit")}
          </Button>
          <Button
            size="small"
            onClick={() => handleDelete(record._id)}
            danger
            className="ml-2"
          >
            {t("Delete")}
          </Button>
        </div>
      ),
    },
    {
      title: t("Status"),
      key: "status",
      render: (_, record) => (
        <span>
          {record.phoneId ? (
            <Tag color="green">{t("Active")}</Tag>
          ) : (
            <Button
              onClick={() => {
                setActivatingAssistant(record);
                setActivateModalVisible(true);
              }}
              className="ml-2"
              size="small"
            >
              {t("Activate")}
            </Button>
          )}
        </span>
      ),
    },
  ];

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">{t("Call Agents")}</h1>
      <Button onClick={handleCreate} type="primary" className="mb-4">
        {t("Create New Assistant")}
      </Button>

      <Table
        columns={columns}
        dataSource={assistants}
        loading={loading}
        rowKey="_id"
      />
      <Modal
        title={editingAssistant ? t("Edit Assistant") : t("Create Assistant")}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="name"
            label={t("Name")}
            rules={[{ required: true, message: t("Please input the name") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="firstMessage"
            label={t("First Message")}
            rules={[
              { required: true, message: t("Please input the first message") },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="gender"
            label={t("Gender")}
            rules={[{ required: true, message: t("Please select the gender") }]}
          >
            <Select>
              <Option value="male">{t("Male")}</Option>
              <Option value="female">{t("Female")}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="language"
            label={t("Language")}
            rules={[
              { required: true, message: t("Please select the language") },
            ]}
          >
            <Select>
              {Object.keys(voices).map((lang) => (
                <Option key={lang} value={lang}>
                  {t(lang)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="speed"
            label={t("Speed")}
            rules={[{ required: true, message: t("Please input the speed") }]}
          >
            <InputNumber min={0.5} max={2} step={0.1} />
          </Form.Item>
          <Form.Item
            name="taskDescription"
            label={t("Task Description")}
            rules={[
              {
                required: true,
                message: t("Please input the task description"),
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item name="forwardNumber" label={t("Forward Call Number")}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingAssistant ? t("Update") : t("Create")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={t("Add Phone Number")}
        visible={phoneModalVisible}
        onCancel={() => setPhoneModalVisible(false)}
        footer={null}
      >
        <Form form={phoneForm} onFinish={handleAddPhone} layout="vertical">
          <Form.Item
            name="number"
            label={t("Phone Number")}
            rules={[
              { required: true, message: t("Please input the phone number") },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="twilioAccountSid"
            label={t("Twilio Account SID")}
            rules={[
              {
                required: true,
                message: t("Please input the Twilio Account SID"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="twilioAuthToken"
            label={t("Twilio Auth Token")}
            rules={[
              {
                required: true,
                message: t("Please input the Twilio Auth Token"),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item name="name" label={t("Name (Optional)")}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("Add Phone Number")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={t("Activate Assistant")}
        visible={activateModalVisible}
        onCancel={() => setActivateModalVisible(false)}
        footer={null}
      >
        <Select
          style={{ width: "100%" }}
          placeholder={t("Select a phone number")}
          onChange={(value) => handleActivate(value)}
        >
          {phoneNumbers.map((phone) => (
            <Option key={phone.vapi_id} value={phone.vapi_id}>
              {phone.name ? `${phone.name} (${phone.number})` : phone.number}
            </Option>
          ))}
        </Select>

        <Button onClick={() => setPhoneModalVisible(true)} className="mt-5">
          {t("Add Phone Number")}
        </Button>
      </Modal>
    </div>
  );
};

export default CallAgents;
