import { Button, Form, Input, Modal, Progress, Select, Spin } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { STANDARD_MOMENT_FORMAT } from "../../../data/constants";
import { selectUser } from "../../../redux/auth/selectors";
import AuthService from "../../../service/AuthService";
import CrudService from "../../../service/CrudService";
import PublicService from "../../../service/PublicService";
import SMTPModal from "../SMTPModal";

const data = [
  { icon: "😊", formtitle: "Friendly & Conversational" },
  { icon: "💼", formtitle: "Professional & Authoritative" },
  { icon: "💡", formtitle: "Innovative & Inspirational" },
  { icon: "😄", formtitle: "Quirky & Humorous" },
];

export default function WelcomeUser() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [tasks, setTasks] = useState([]);
  const [progress, setProgress] = useState(0);
  const [targetCustomerModal, setTargetCustomerModal] = useState(false);
  const [adConfigModal, setAdConfigModal] = useState(false);
  const [smtpModal, setSmtpModal] = useState(false);
  const [firstFunnel, setFirstFunnel] = useState(null);
  const [firstAdCampaign, setFirstAdCampaign] = useState(null);
  const [softValue, setSoftValue] = useState(null);
  const [smtp, setSmtp] = useState(null);
  const [me, setMe] = useState(null);
  const [loading, setLoading] = useState(false);

  const getProps = (fieldKey) => ({
    value: softValue?.[fieldKey],
    onChange: (e) =>
      setSoftValue((current) => ({
        ...current,
        [fieldKey]: e?.target?.value ?? e,
      })),
  });

  useEffect(() => {
    setSoftValue(me);
    setSmtp(me?.smtp ?? null);
  }, [me]);

  useEffect(() => {
    fetchFirstFunnel();
    fetchFirstAd();
  }, []);

  const updateTasks = useCallback(
    (userData) => {
      const newTasks = [
        { id: 1, title: t("Business setup"), completed: true },
        { id: 2, title: t("Create your first funnel"), completed: true },
        {
          id: 3,
          title: t("Setup your target customer"),
          completed:
            !!userData.customerPersonaAge?.[0] &&
            !!userData.customerPersonaGender?.[0] &&
            !!userData.customerPersonaInterest?.[0] &&
            !!userData.customerPersonaIndustry?.[0] &&
            !!userData.customerPersonaKeywords?.[0],
        },
        {
          id: 5,
          title: t("Connect your email sender"),
          completed: !!userData.smtp?.connectionStatus,
        },
        {
          id: 6,
          title: t("Configure your first funnel"),
          completed: userData.clickedonConfigureFirstFunnel,
        },
        {
          id: 4,
          title: t("Setup your advertisement configuration"),
          completed:
            !!userData?.themeColor &&
            !!userData?.catchCopy &&
            !!userData?.adSize &&
            !!userData?.brandToneVoice,
        },
        {
          id: 44,
          title: t("Create your first ad"),
          show:
            !!userData?.themeColor &&
            !!userData?.catchCopy &&
            !!userData?.adSize &&
            !!userData?.brandToneVoice,
          completed: firstAdCampaign,
        },
      ];
      setTasks(newTasks);
      const completedCount = newTasks.filter((task) => task.completed).length;
      setProgress(Math.ceil((completedCount / newTasks.length) * 100));
    },
    [firstAdCampaign, t]
  );

  const fetchUserData = useCallback(async () => {
    try {
      const res = await AuthService.me();
      setMe(res.data.me);
      updateTasks(res.data.me);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [updateTasks]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const fetchFirstFunnel = async () => {
    try {
      const { data } = await CrudService.search("Vacancy", 1, 1, {
        filters: { user_id: user._id },
      });
      setFirstFunnel(data.items?.[0]);
    } catch (error) {
      console.error("Error fetching first funnel:", error);
    }
  };

  const fetchFirstAd = async () => {
    try {
      const { data } = await CrudService.search("AdCampaign", 1, 1, {
        filters: { user_id: user._id },
      });
      setFirstAdCampaign(data.items?.[0]);
    } catch (error) {
      console.error("Error fetching first funnel:", error);
    }
  };

  const handleUpdate = useCallback(async () => {
    await AuthService.updateMe(softValue);
    const res = await AuthService.me();
    setMe(res.data.me);
    updateTasks(res.data.me);
    document.dispatchEvent(new CustomEvent("REFRESH.PROFILE"));
  }, [softValue, updateTasks]);

  const handleTaskClick = (taskId) => {
    switch (taskId) {
      case 3:
        setTargetCustomerModal(true);
        break;
      case 4:
        setAdConfigModal(true);
        break;
      case 5:
        setSmtpModal(true);
        break;
      case 6:
        handleConfigureFirstFunnel();
        break;
      case 44:
        handleCreateAds();
        break;
      default:
        break;
    }
  };

  const handleConfigureFirstFunnel = async () => {
    try {
      await AuthService.updateMe({
        clickedonConfigureFirstFunnel: true,
      });
      if (firstFunnel) {
        window.location.href = `/dashboard/vacancydetails?id=${firstFunnel._id}`;
      }
    } catch (error) {
      console.error("Error configuring first funnel:", error);
    }
  };

  const handleCreateAds = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      let result;
      try {
        const res = await PublicService.aiPrompt({
          content: `
For the following company:
Service Details: ${user.serviceDetails}
Customer Persona Age: ${user.customerPersonaAge}
Customer Persona Gender: ${user.customerPersonaGender}
Customer Persona Interest: ${user.customerPersonaInterest}
Customer Persona Industry: ${user.customerPersonaIndustry}
Customer Persona Keywords: ${user.customerPersonaKeywords}
Customer Catch Copy: ${user.catchCopy}
Customer Brand Tone Voice: ${user.brandToneVoice}

We need to create ad copy: ad primary text, headline, description
Each copy should be no longer than 50 characters!

    The content of your generated copy must be in ${Cookies.get("i18next")}

Your answer must be in JSON format that can be parsed with JSON.parse function of Javascript without errors in the following format:

{
adPrimaryText: String,
adHeadline: String,
adDescription: String,
}
`,
        });

        result = JSON.parse(
          res.data?.output?.replace?.(/```json/g, "")?.replace?.(/```/g, "")
        );
      } catch (e) {
        console.error("Error with AI:", e);
      }

      const current = await CrudService.create("AdCampaign", {
        user_id: user._id,
        name: t("New Ad Campaign {{date}}", {
          date: moment().format(STANDARD_MOMENT_FORMAT),
        }),
        adPrimaryText: result?.adPrimaryText ?? "",
        adHeadline: result?.adHeadline ?? "",
        adDescription: result?.adDescription ?? "",
        vacancy: firstFunnel._id,
      });

      window.location.href = `/dashboard/createAd?id=${current?.data?.result?._id}`;
    } catch (error) {
      console.error("Error configuring first funnel:", error);
    } finally {
      setLoading(false);
    }
  }, [loading, firstFunnel, user, t]);

  const realTasks = tasks.filter((x) => x?.show !== false);
  if (progress >= 100 || realTasks.length === 0) return null;

  return (
    <div className="mb-8 bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-4">
        {t("Welcome, {{name}}...", { name: user?.firstName })}
      </h2>
      <h3 className="text-xl font-semibold mb-2">{t("Your next steps")}</h3>
      <Progress
        percent={progress}
        className="mb-4"
        format={(percent) =>
          t("{{completed}} of {{total}}", {
            completed: realTasks.filter((te) => te.completed).length,
            total: realTasks.length,
          })
        }
      />
      <ul className="space-y-2">
        {realTasks.map((task) => (
          <li
            key={task.id}
            className="flex items-center cursor-pointer"
            onClick={() => {
              if (task.id === 44 && firstAdCampaign) return;
              if (!task.completed) handleTaskClick(task.id);
            }}
          >
            {loading && task.id === 44 ? (
              <Spin className="mr-2" />
            ) : task.id === 44 && firstAdCampaign ? (
              <FaCheckSquare className="text-green-500 mr-2" />
            ) : task.completed ? (
              <FaCheckSquare className="text-green-500 mr-2" />
            ) : (
              <FaRegSquare className="text-gray-300 mr-2" />
            )}
            <span
              className={task.completed ? "text-gray-600" : "text-gray-800"}
            >
              {task.title}
            </span>
          </li>
        ))}
      </ul>

      <Modal
        title={t("Setup Target Customer")}
        open={targetCustomerModal}
        onCancel={() => setTargetCustomerModal(false)}
        footer={null}
      >
        <form
          className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <div className="flex gap-3 items-center">
                  <label
                    className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                  >
                    {t("Describe your service details")}
                  </label>
                </div>
                <div className="mt-2">
                  <textarea
                    rows={5}
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                    {...getProps("serviceDetails")}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <div className="flex gap-3 items-center">
                  <label
                    className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                  >
                    {t("Target Age")}
                  </label>
                </div>
                <div className="mt-2">
                  <Select
                    mode="multiple"
                    options={[
                      { value: "0-13", label: "0-13" },
                      { value: "14-18", label: "14-18" },
                      { value: "19-24", label: "19-24" },
                      { value: "25-34", label: "25-34" },
                      { value: "35-44", label: "35-44" },
                      { value: "45-54", label: "45-54" },
                      { value: "55-64", label: "55-64" },
                      { value: "65+", label: "65+" },
                    ]}
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                    {...getProps("customerPersonaAge")}
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <div className="flex gap-3 items-center">
                  <label
                    className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                  >
                    {t("Target Gender")}
                  </label>
                </div>
                <div className="mt-2">
                  <Select
                    mode="multiple"
                    options={[
                      { value: "man", label: t("Man") },
                      { value: "woman", label: t("Woman") },
                      { value: "diverse", label: t("Diverse") },
                    ]}
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                    {...getProps("customerPersonaGender")}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <div className="flex gap-3 items-center">
                  <label
                    className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                  >
                    {t("Target Interests")}
                  </label>
                </div>
                <div className="mt-2">
                  <Select
                    mode="tags"
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                    {...getProps("customerPersonaInterest")}
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <div className="flex gap-3 items-center">
                  <label
                    className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                  >
                    {t("Target Industries")}
                  </label>
                </div>
                <div className="mt-2">
                  <Select
                    mode="tags"
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                    {...getProps("customerPersonaIndustry")}
                  />
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div className="flex gap-3 items-center">
                <label
                  className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                >
                  {t("Target Keywords")}
                </label>
              </div>
              <div className="mt-2">
                <Select
                  mode="tags"
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                  {...getProps("customerPersonaKeywords")}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button
              onClick={handleUpdate}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t("Save")}
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        title={t("Setup Advertisement Configuration")}
        open={adConfigModal}
        onCancel={() => setAdConfigModal(false)}
        footer={null}
      >
        <form
          className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="">
              {/* Catch Copy */}
              <div className="sm:col-span-6">
                <div className="flex gap-3 items-center">
                  <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">
                    {t("Catch Copy")}
                  </label>
                </div>
                <div className="mt-2">
                  <textarea
                    rows={5}
                    placeholder={t("Automate all your sales for free!")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                    {...getProps("catchCopy")}
                  />
                </div>
              </div>

              {/* Ad Size */}
              <div className="sm:col-span-3">
                <div className="flex gap-3 items-center">
                  <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">
                    {t("Ad Size (px x px)")}
                  </label>
                </div>
                <div className="mt-2">
                  <Select
                    options={[
                      { value: "1080x680", label: "1080x680" },
                      { value: "1280x720", label: "1280x720" },
                      { value: "1920x1080", label: "1920x1080" },
                      { value: "300x250", label: "300x250" },
                      { value: "728x90", label: "728x90" },
                    ]}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400 shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                    {...getProps("adSize")}
                  />
                </div>
              </div>

              {/* Brand Tone of Voice */}
              <div className="">
                <div className="flex gap-3 items-center">
                  <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">
                    {t("Select Brand Tone of Voice")}
                  </label>
                </div>
                <div className="mt-2 ">
                  {data.map((d, index) => (
                    <div
                      key={"listproduct" + index}
                      className={`flex w-full  items-center gap-[11px] smx:w-full border border-1 p-2 rounded-lg cursor-pointer ${
                        d.formtitle === softValue?.brandToneVoice
                          ? "border-gray-900"
                          : ""
                      }`}
                      onClick={() => {
                        setSoftValue((x) => ({
                          ...x,
                          brandToneVoice: d.formtitle,
                        }));
                      }}
                    >
                      <div className="w-full rounded-[12px] object-cover flex justify-center">
                        {d.icon}
                      </div>
                      <p className="tracking-[-0.13px] text-center">
                        {t(d.formtitle)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Theme Color */}
              <div className="container-xs mt-10 flex  gap-3 p-5">
                <p className="font-normal tracking-tight text-gray-700">
                  <span className="font-medium">
                    {t("Select your brand color:")}
                  </span>
                </p>
                <div className="flex flex-wrap gap-2 mt-5">
                  {[
                    "#FF5733",
                    "#C70039",
                    "#900C3F",
                    "#571845",
                    "#2E86C1",
                    "#48C9B0",
                    "#F39C12",
                    "other",
                  ].map((color, index) =>
                    color === "other" ? (
                      <label key={index} className="flex items-center">
                        <input
                          type="color"
                          value={softValue?.themeColor}
                          onChange={(e) =>
                            setSoftValue({
                              ...softValue,
                              themeColor: e.target.value,
                            })
                          }
                          className="w-10 h-10 rounded-full cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                          {t("Other")}
                        </span>
                      </label>
                    ) : (
                      <button
                        key={index}
                        className="w-10 h-10 rounded-full"
                        style={{ backgroundColor: color }}
                        onClick={() =>
                          setSoftValue({ ...softValue, themeColor: color })
                        }
                      />
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                onClick={handleUpdate}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </form>
      </Modal>

      <SMTPModal
        SMTPModal={smtpModal}
        setSMTPModal={setSmtpModal}
        setOnboardingStatus={() => {}}
        smtp={smtp}
        setSmtp={setSmtp}
        handleUpdate={handleUpdate}
        setMe={setMe}
      />
    </div>
  );
}
