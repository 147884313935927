import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Skeleton, Space, Switch } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MultiStepComponent from "../../../components/MultiStepComponent";
import { selectDarkMode, selectLoading } from "../../../redux/auth/selectors";
import CrudService from "../../../service/CrudService";
import PublicService from "../../../service/PublicService";

const StepDefinitionForm = ({ onStepsChange, initialSteps = [] }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onStepsChange(values.steps);
  };

  return (
    <Form
      form={form}
      name="step_definition"
      onFinish={onFinish}
      initialValues={{ steps: initialSteps }}
      autoComplete="off"
    >
      <Form.List name="steps">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{ display: "flex", marginBottom: 8 }}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  name={[name, "description"]}
                  rules={[{ required: true, message: "Missing description" }]}
                >
                  <Input.TextArea
                    placeholder="Step description"
                    style={{ width: "250px" }}
                  />
                </Form.Item>
                <Form.Item {...restField} name={[name, "restrictions"]}>
                  <Input.TextArea
                    placeholder="Restrictions (optional)"
                    style={{ width: "120px" }}
                  />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Step
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

const PhoneAutomation = ({ workflowData, handleConfigChange, workflow }) => {
  const { t } = useTranslation();
  const loading = useSelector(selectLoading);
  const [aiCallModal, setAICallModal] = useState(null);
  const darkMode = useSelector(selectDarkMode);
  const [callObjective, setCallObjective] = useState("");
  const [generatedScript, setGeneratedScript] = useState("");
  const [editMode, setEditMode] = useState(null);
  const [callSteps, setCallSteps] = useState([]);

  const mentionData = [
    { id: "candidateFirstname", display: t("Lead's Firstname") },
    { id: "candidateLastname", display: t("Lead's Lastname") },
    { id: "candidateEmail", display: t("Lead's Email") },
    { id: "candidatePhone", display: t("Lead's Phone") },
    { id: "companyName", display: t("Company Name") },
    { id: "companyWebsite", display: t("Company Website") },
    { id: "companyAddress", display: t("Company Address") },
    { id: "userCalendarLink", display: t("User Calendar Link") },
    { id: "currentDate", display: t("Current Date") },
    { id: "currentTime", display: t("Current Time") },
    { id: "userFirstname", display: t("User Firstname") },
    { id: "userLastname", display: t("User Lastname") },
    { id: "userEmail", display: t("User Email") },
    { id: "userPhone", display: t("User Phone") },
  ];

  const id = workflow.stageId;
  const onFinish = () => {
    setAICallModal(null);
    setEditMode(null);
  };

  const generateAIScript = async () => {
    try {
      const stepsPrompt =
        callSteps.length > 0
          ? `\nFollow these specific steps:\n${callSteps
              .map(
                (step, idx) =>
                  `Step ${idx}: ${step.description}${
                    step.restrictions
                      ? `\nRestrictions: ${step.restrictions}`
                      : ""
                  }`
              )
              .join("\n")}`
          : "";

      const res = await PublicService.aiPrompt({
        content: `
Generate a phone call script for an AI agent with the following objective: ${callObjective}
${stepsPrompt}

In the generated content (task description or first message), you can use following variables too if necessary:
${mentionData.map((x) => `@[${x.display}](${x.id})\n`)}

When using variables, it is imminent that you insert the variable exactly as it is: @[xxx](yyy)

Respond in the following JSON format:
{
  "taskDescription": "The description of task in natural language for the AI call agent",
  "firstMessage": "The first sentence to be spoken upon call start",
  "steps": [
    {
      "stepNumber": 1,
      "content": "Detailed script for this step",
      "expectedResponses": ["possible response 1", "possible response 2"]
    }
  ]
}
        `,
      });

      const result = JSON.parse(
        res.data?.output?.replace(/```json/g, "")?.replace(/```/g, "")
      );

      setGeneratedScript(result.script);
      setVacancyData((prevData) => ({
        ...prevData,
        onEnterCallScript: result.taskDescription,
        firstMessage: result.firstMessage,
        callSteps: result.steps,
      }));
    } catch (error) {
      console.error("Error generating AI script:", error);
    }
  };

  const steps = () => [
    {
      id: "step1",
      name: t("Task Description"),
      form: [
        {
          fieldName: "firstMessage",
          label: t("First message"),
          type: "textarea-mention",
          placeholder: t("Enter the first message to say in the phone call"),
          rows: 2,
          mentionData,
        },
        {
          fieldName: "onEnterCallScript",
          label: t("Describe the task of your call agent"),
          type: "textarea-mention",
          placeholder: t("Enter task description"),
          rows: 6,
          mentionData,
        },
      ],
    },
    {
      id: "step4",
      name: t("Call Configuration"),
      form: [
        {
          fieldName: "aiSpeed",
          label: t("Speed"),
          type: "select",
          options: [
            { value: 0.85, label: t("Slow") },
            { value: 1, label: t("Normal") },
            { value: 1.35, label: t("Fast") },
            { value: 1.6, label: t("Ultra Fast") },
          ],
          placeholder: t("Select the speed of speech"),
        },
        {
          fieldName: "aiLanguage",
          label: t("Language"),
          type: "select",
          options: [
            { value: "en-US", label: t("American English") },
            { value: "en-GB", label: t("British English") },
            { value: "nl", label: t("Dutch") },
            { value: "fr", label: t("French") },
            { value: "de", label: t("German") },
            { value: "it", label: t("Italian") },
            { value: "ja", label: t("Japanese") },
            { value: "pl", label: t("Polish") },
            { value: "pt", label: t("Portuguese") },
            { value: "ru", label: t("Russian") },
            { value: "es", label: t("Spanish") },
            { value: "sv", label: t("Swedish") },
            { value: "tr", label: t("Turkish") },
            { value: "zh", label: t("Chinese") },
          ],
          placeholder: t("Select the language"),
        },
        {
          fieldName: "aiMaxDuration",
          label: t("Maximum Call Duration"),
          type: "inputNumber",
          placeholder: t(
            "Specify the maximum duration of an interview in minutes"
          ),
        },
        {
          fieldName: "aiRecord",
          label: t("Record Call"),
          type: "switch",
          placeholder: t(
            "Specify whether you would like to create a voice recording of your call"
          ),
        },
      ],
    },
  ];

  const [vacancyData, setVacancyData] = useState(null);
  const vidRef = useRef();

  useEffect(() => {
    if (vidRef.current) vidRef.current.play();
  }, [vidRef]);

  useEffect(() => {
    if (!id) return;

    CrudService.getSingle("VacancyStage", id).then((res) => {
      if (!res.data) return;
      setVacancyData(res.data);
    });
  }, [id]);

  if (!vacancyData) return <Skeleton active />;

  return (
    <>
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t("When lead enters stage {{stageName}}, send them", {
            stageName: workflowData?.name,
          })}{" "}
          <span className="text-indigo-500">{t("an AI call")}</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterCall}
          onChange={(value) => handleConfigChange("onEnterCall", value)}
        />
      </div>

      <button
        className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
        onClick={async () => {
          if (loading) return;
          CrudService.getSingle("VacancyStage", id).then((res) => {
            if (!res.data) return;
            setVacancyData(res.data);
            setAICallModal("onEnterCallScript");
          });
        }}
      >
        {t("Change Call Script")}
      </button>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!aiCallModal}
        onCancel={() => {
          setAICallModal(null);
          setEditMode(null);
        }}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        {!editMode ? (
          <div className="flex flex-col gap-4">
            <Button onClick={() => setEditMode("manual")}>
              {t("Edit Manually")}
            </Button>
            <Button onClick={() => setEditMode("ai")}>{t("Use AI")}</Button>
          </div>
        ) : editMode === "ai" ? (
          <div className="mb-5 space-y-4">
            <Input
              placeholder={t("Enter the objective of the phone call")}
              value={callObjective}
              onChange={(e) => setCallObjective(e.target.value)}
            />
            <div className="border-t pt-4">
              <h3 className="text-lg font-medium mb-2">
                {t("Define Call Steps")}
              </h3>
              <StepDefinitionForm
                onStepsChange={setCallSteps}
                initialSteps={callSteps}
              />
            </div>
            <Button
              onClick={async () => {
                await generateAIScript();
                setEditMode("manual");
              }}
              disabled={!callObjective}
              loading={loading}
              className="bg-indigo-500 text-white"
            >
              {t("Generate AI Script")}
            </Button>
          </div>
        ) : (
          <MultiStepComponent
            AIEnhancements={true}
            displayUndoRedo
            steps={steps()}
            defaultFormData={{
              ...vacancyData,
              onEnterCallScript:
                generatedScript || vacancyData.onEnterCallScript,
            }}
            onFinish={async (formData) => {
              if (!id) return;
              await CrudService.update("VacancyStage", id, {
                ...formData,
              });
              onFinish();
            }}
            onNext={async (formData) => {
              if (!id) return;
              await CrudService.update("VacancyStage", id, {
                ...formData,
              });
            }}
            buttomLineWrapperClass=" w-full bg-white dark:bg-gray-900 rounded-t-sm pl-[20px] pt-[20px] pb-[20px] pr-[80px] right-0	bottom-0"
            buttomLineInnerClass="items-end flex flex-col"
          />
        )}
      </Modal>
    </>
  );
};

export default PhoneAutomation;
