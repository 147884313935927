import { Card, Divider, Select, Skeleton } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdRefresh } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ATSService from "../../../service/ATSService";
import UserService from "../../../service/UserService";

const adStatMapping = {
  "AD spent": "spend",
  "AD CPC": "cpc",
  "AD CPM": "cpm",
  "AD CTR": "ctr",
  "AD impressions": "impressions",
  "AD Clicks": "clicks",
  "AD frequency": "frequency",
};
const adStatMappingReverse = {
  spend: "AD spent",
  cpc: "AD CPC",
  cpm: "AD CPM",
  ctr: "AD CTR",
  impressions: "AD impressions",
  clicks: "AD Clicks",
  frequency: "AD frequency",
};

const FunnelStage = ({ width, height, label, value, percentage }) => {
  const { t } = useTranslation();
  return (
    <div
      className="relative mb-2"
      style={{ width: `${width}%`, height: `${height}px` }}
    >
      <div className="absolute inset-0 bg-blue-500 rounded-sm"></div>
      <div className="absolute inset-0 flex items-center justify-between px-4 text-white">
        <span className="font-semibold">{label}</span>
        <span>{value}</span>
      </div>
      <div className="absolute left-full ml-4 top-1/2 transform -translate-y-1/2 text-sm">
        {percentage}% (
        {label === t("Optins (Leads)")
          ? t("New Leads (Optin)")
          : t("{{label}}/ Optin", { label })}
        )
      </div>
    </div>
  );
};

export default function FunnelStats() {
  const { t } = useTranslation();
  const [timeframe, setTimeframe] = useState("day");
  const [trafficSource, setTrafficSource] = useState("All");
  const [funnelData, setFunnelData] = useState(null);
  const [adMetrics, setAdMetrics] = useState(null);
  const [metricsTimeline, setMetricsTimeline] = useState(null);
  const [adStats, setAdStats] = useState(null);
  const [googleAdStats, setGoogleAdStats] = useState(null);
  let [searchParams] = useSearchParams();

  const loadData = useCallback(() => {
    const id = searchParams.get("id");
    if (!id) return;
    setFunnelData(null);

    ATSService.getFunnelStats(id).then((res) => {
      if (!res.data) return;
      setFunnelData(res.data.funnelData);
      setAdMetrics(res.data.adMetrics);
      console.log(res.data.metricsTimeline);
      setMetricsTimeline(res.data.metricsTimeline); // Set timeline data
    });

    UserService.getVacancyCampaignStats(id).then((res) => {
      console.log(res.data);
      if (res.data?.insights?.[0]?._data)
        setAdStats(res.data.insights[0]._data);
      else setAdStats("Delivering");

      if (res.data?.insightsGoogle?.[0]?._data)
        setGoogleAdStats(res.data.insightsGoogle[0]._data);
      else setGoogleAdStats("Delivering");
    });
  }, [searchParams]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleTimeframeChange = (value) => {
    setTimeframe(value);
    // Fetch and update data based on new timeframe
  };

  const handleTrafficSourceChange = (value) => {
    setTrafficSource(value);
    // Fetch and update data based on new traffic source
  };

  if (!funnelData || !adMetrics || !metricsTimeline) return <Skeleton active />;

  return (
    <div className="card w-full max-w-4xl">
      <div className="card-content">
        <div className="flex justify-end gap-1 mb-4">
          <IoMdRefresh
            size={25}
            title={t("Refresh")}
            className="cursor-pointer"
            onClick={() => loadData()}
          />
        </div>

        {/* Funnel Stages */}
        <div className="relative" style={{ paddingRight: "200px" }}>
          {funnelData.map((stage, index) => (
            <FunnelStage
              key={stage.name}
              width={100 - index * 15}
              height={80}
              label={t(stage.name)}
              value={stage.value}
              percentage={stage.percentage}
            />
          ))}
        </div>

        {/* Timeline Chart */}
        <div className="mt-8" style={{ height: 400 }}>
          {metricsTimeline && metricsTimeline.length > 0 ? (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={metricsTimeline}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />

                <Line type="monotone" dataKey={t("Engaged")} stroke="#cf7300" />
                <Line
                  type="monotone"
                  dataKey={t("Nurtured")}
                  stroke="#af7300"
                />
                <Line type="monotone" dataKey={t("Webinar")} stroke="#8884d8" />
                <Line
                  type="monotone"
                  dataKey={t("Converted")}
                  stroke="#82ca9d"
                />
                <Line type="monotone" dataKey={t("Lost")} stroke="#ff7300" />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <div>{t("No data available for the selected timeframe.")}</div>
          )}
        </div>

        {/* Ad Metrics */}
        {adStats !== "Delivering" && typeof adMetrics === "object" && (
          <>
            <Divider />
            <h1 className="font-bold text-xl">{t("Meta Ads")}</h1>
            <div className="mt-8 grid grid-cols-2 md:grid-cols-4 gap-4">
              {Object.entries(adMetrics).map(([key, value]) => (
                <Card className="card" key={key}>
                  <div className="card-title text-sm font-medium">{t(key)}</div>
                  <div className="card-content">
                    <p className="text-2xl font-bold">
                      {adStats?.[adStatMapping[key]]
                        ?.toFixed?.(2)
                        ?.replace?.(".00", "")}{" "}
                      {!["frequency", "impressions", "clicks", "ctr"].includes(
                        adStatMapping[key]
                      ) && adStats?.account_currency}
                    </p>
                  </div>
                </Card>
              ))}

              {adStats?.["spend"] && (
                <Card className="card">
                  <div className="card-title text-sm font-medium">
                    {t("Ad Spent / Conversion")}
                  </div>
                  <div className="card-content">
                    <p className="text-2xl font-bold">
                      {(
                        adStats["spend"] /
                        (funnelData?.find?.((a) => a.name === "Converted")
                          ?.value || 1)
                      )?.toFixed?.(2)}{" "}
                      {adStats?.account_currency}
                    </p>
                  </div>
                </Card>
              )}
            </div>
          </>
        )}

        {googleAdStats !== "Delivering" &&
          Object.keys(googleAdStats).length > 0 && (
            <>
              <Divider />
              <h1 className="font-bold text-xl">{t("Google Ads")}</h1>
              <div className="mt-8 grid grid-cols-2 md:grid-cols-4 gap-4">
                {["spend", "cpc", "cpm", "ctr", "impressions", "clicks"].map(
                  (key) => (
                    <Card className="card" key={key}>
                      <div className="card-title text-sm font-medium">
                        {t(adStatMappingReverse[key])}
                      </div>
                      <div className="card-content">
                        <p className="text-2xl font-bold">
                          {googleAdStats?.[key]
                            ?.toFixed?.(2)
                            ?.replace?.(".00", "")}{" "}
                          {![
                            "frequency",
                            "impressions",
                            "clicks",
                            "ctr",
                          ].includes(key) &&
                            (adStats?.account_currency ?? "JPY")}
                        </p>
                      </div>
                    </Card>
                  )
                )}

                {googleAdStats?.["spend"] && (
                  <Card className="card">
                    <div className="card-title text-sm font-medium">
                      {t("Ad Spent / Conversion")}
                    </div>
                    <div className="card-content">
                      <p className="text-2xl font-bold">
                        {(
                          googleAdStats["spend"] /
                          (funnelData?.find?.((a) => a.name === "Converted")
                            ?.value || 1)
                        )?.toFixed?.(2)}{" "}
                        {adStats?.account_currency ?? "JPY"}
                      </p>
                    </div>
                  </Card>
                )}
              </div>
            </>
          )}
      </div>
    </div>
  );
}
