import { Modal, Skeleton, Typography } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MultiStepComponent from "../components/MultiStepComponent";
import { templates } from "../pages/Dashboard/vacancies/templates";
import Onboarding2 from "../pages/auth/Onboarding";
import { getPartner } from "../redux/auth/selectors";
import PublicService from "./PublicService";
import UploadService from "./UploadService";
import UserService from "./UserService";
import { Button, Heading, Img, Input, Text } from "./components";
import { Textarea } from "./components/Textarea";

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(
    localStorage?.selectedTemplate ?? "default"
  );
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [setupFinished, setSetupFinished] = useState(false);
  const [surveySubmit, setSurveySubmit] = useState(false);
  const partner = useSelector(getPartner);
  const navigate = useNavigate();
  const [aiSuggestion, setAiSuggestion] = useState("");
  const socket = useRef();
  const socketPing = useRef();
  const { t } = useTranslation();

  const data = [
    { icon: "😊", formtitle: t("Friendly & Conversational") },
    { icon: "💼", formtitle: t("Professional & Authoritative") },
    { icon: "💡", formtitle: t("Innovative & Inspirational") },
    { icon: "😄", formtitle: t("Quirky & Humorous") },
  ];

  const NUM_OF_STEPS = 11;

  const steps = [
    {
      question: t("What is the name of your business?"),
      key: "brandName",
    },
    {
      question: t("Please describe your product or service"),
      key: "serviceDetails",
    },
    {
      question: t(
        "What are the customer pain points who would like to use your service?"
      ),
      key: "customerPersonaInterestDetails",
    },
    {
      question: t("What are your pricing plans?"),
      key: "pricingDetails",
    },
    {
      question: t("What are your benefits?"),
      key: "benefitDetails",
    },
    {
      question: t("Who is your audience?"),
      key: "customerAudienceDetails",
    },
  ];

  useEffect(() => {
    setValues((e) => ({ ...e, themeColor: partner.themeColor }));
  }, [partner]);

  const handleUseSuggestion = useCallback(() => {
    const key = steps[step - 1].key;
    if (aiSuggestion && key) {
      setAiSuggestion("");
      setValues((prev) => ({
        ...prev,
        [key]: aiSuggestion,
      }));
    }
  }, [aiSuggestion, step]);

  // Example of fetching AI suggestions when the component loads or data changes
  useEffect(() => {
    if (step >= 7) return;
    if (step === 1) return;

    setAiSuggestion("");
    if (socket.current) {
      socket.current.close();
    }

    socket.current = new WebSocket(
      `wss://booklified-chat-socket.herokuapp.com`
    );

    socket.current.addEventListener("open", async () => {
      socketPing.current = setInterval(
        () => socket.current.send(JSON.stringify({ id: "PING" })),
        30000
      );
      const content = `We want to build a landing page. Following is the LANDING PAGE DESCRIPTION: ${
        localStorage.describeLandingpage
      }
        
        In order to do this, we are using an AI tool. The AI tool is asking us the following question: ${
          steps[step - 1].question
        }

        Can you provide the answer to this question? Your reply should only include answer without any other information or filler word.

        The question is in English. However, your answer suggestions must be in the same language of the LANDING PAGE DESCRIPTION

        We prefer the answer to be an enumeration of multiple ideas like this:
        1. [Idea 1]
        2. [Idea 2]
        3. [Idea 3]

        Maximum 5 suggestions are allowed
        ...
        `;

      socket.current.send(
        JSON.stringify({
          id: "OPEN_AI_PROMPT",
          payload: {
            content,
            model: "gpt-3.5-turbo",
            partner: partner?._id,
            app_id: "hidec",
            max_tokens: 4096,
          },
        })
      );
    });

    socket.current.addEventListener("message", async (event) => {
      const message = JSON.parse(event.data);
      const response = message.payload?.response;

      if (
        !["sure", "certainly"].some((a) => response.toLowerCase().startsWith(a))
      )
        setAiSuggestion(response);
      if (socketPing.current) clearInterval(socketPing.current);
    });
  }, [step, partner]);

  if (loading && step === NUM_OF_STEPS) return <Skeleton active />;
  return (
    <div
      style={{
        height: "100vh",
        padding: "20px",
      }}
    >
      {/* onboarding step two section */}
      <div className="flex w-full flex-col gap-5 bg-white smx:gap-8">
        {/* navigation section */}
        <div className=" py-4">
          <div className="container-xs flex items-center justify-between gap-5 mdx:p-5">
            {step < 10 && (
              <Img
                src="/images/img_arrow_left.svg"
                alt="arrowleft"
                className="h-[24px] w-[24px] cursor-pointer"
                onClick={() => {
                  setStep((s) => Math.max(1, s - 1));
                }}
              />
            )}
            {step < 9 && (
              <Heading
                size="xs"
                as="h1"
                className="self-end tracking-[-0.50px] !text-[#e30047]"
              >
                {t("{{step1}} of {{step2}}", { step1: step, step2: "8" })}
              </Heading>
            )}
          </div>
        </div>

        {step === 1 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    {t("What is the name of your business?")}
                  </span>
                </Text>
              </div>
              <Textarea
                rows={4}
                shape="round"
                type="text"
                name="name"
                value={values.brandName}
                onChange={(e) => {
                  setValues((x) => ({
                    ...x,
                    brandName: e,
                  }));
                }}
              />
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    {t("Please describe your product or service")}
                  </span>
                </Text>
              </div>
              <Textarea
                rows={4}
                shape="round"
                type="text"
                name="name"
                placeholder={t(
                  "We provide our customers an AI system to automate their lead generation and sales."
                )}
                value={values.serviceDetails}
                onChange={(e) => {
                  setValues((x) => ({
                    ...x,
                    serviceDetails: e,
                  }));
                }}
              />
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    {t(
                      "What are the customer pain points who would like to use your service?"
                    )}
                  </span>
                </Text>
              </div>
              <Textarea
                rows={4}
                shape="round"
                type="text"
                name="name"
                placeholder={t(
                  "The main features are: Creating customized sales automation funnels for clients to streamline their sales process and increase conversions."
                )}
                value={values.customerPersonaInterestDetails}
                onChange={(e) => {
                  setValues((x) => ({
                    ...x,
                    customerPersonaInterestDetails: e,
                  }));
                }}
              />
            </div>
          </>
        )}
        {step === 4 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    {t("What are your pricing plans?")}
                  </span>
                </Text>
              </div>
              <Textarea
                rows={4}
                shape="round"
                type="text"
                name="name"
                value={values.pricingDetails}
                onChange={(e) => {
                  setValues((x) => ({
                    ...x,
                    pricingDetails: e,
                  }));
                }}
              />
            </div>
          </>
        )}
        {step === 5 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    {t("What are your benefits?")}
                  </span>
                </Text>
              </div>
              <Textarea
                rows={4}
                shape="round"
                type="text"
                name="name"
                value={values.benefitDetails}
                onChange={(e) => {
                  setValues((x) => ({
                    ...x,
                    benefitDetails: e,
                  }));
                }}
              />
            </div>
          </>
        )}
        {step === 6 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    {t("Who is your audience?")}
                  </span>
                </Text>
              </div>
              <Textarea
                rows={4}
                shape="round"
                type="text"
                name="name"
                value={values.customerAudienceDetails}
                onChange={(e) => {
                  setValues((x) => ({
                    ...x,
                    customerAudienceDetails: e,
                  }));
                }}
              />
            </div>
          </>
        )}
        {step === 7 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    {t("What is your brand's tone and voice?")}
                  </span>
                </Text>
              </div>
              <div className="flex w-[28%] gap-4 mdx:w-full mdx:flex-row smx:flex-col">
                {data.map((d, index) => (
                  <div
                    key={"listproduct" + index}
                    className={`flex w-full flex-col items-center gap-[11px] smx:w-full border border-1 p-2 rounded-lg cursor-pointer ${
                      d.formtitle === values?.brandToneVoice
                        ? "border-gray-900"
                        : ""
                    }`}
                    onClick={() => {
                      setValues((x) => ({
                        ...x,
                        brandToneVoice: d.formtitle,
                      }));
                    }}
                  >
                    <div
                      className={`w-full rounded-[12px] object-cover flex justify-center`}
                    >
                      {d.icon}
                    </div>
                    <Text
                      size="xs"
                      as="p"
                      className="tracking-[-0.13px] text-center"
                    >
                      {d.formtitle}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {step > 1 && step <= 7 && aiSuggestion && (
          <div>
            <Text as="p" className="!font-normal tracking-[-0.16px] flex gap-1">
              <img
                alt=""
                loading="lazy"
                decoding="async"
                data-nimg={1}
                className="w-6 h-6 object-cover"
                style={{ color: "transparent" }}
                src="/images/6056251-200.png"
              />
              <span className="font-medium text-[#4b5563]">
                {t("AI Suggestion:")}
              </span>
            </Text>
            <div className="relative">
              <div
                className="bg-gray-100 min-h-[75px] p-2 my-2"
                dangerouslySetInnerHTML={{
                  __html: aiSuggestion?.replace?.(/\n/g, "<br>"),
                }}
              ></div>
              <button
                onClick={handleUseSuggestion}
                className="absolute end-0 bottom-0 font-bold p-1 mr-2"
              >
                {t("Use")}
              </button>
            </div>
          </div>
        )}

        {step === 8 && (
          <>
            <div className="container-xs mt-10 flex flex-col gap-3 p-5">
              <p className="font-normal tracking-tight text-gray-700">
                <span className="font-medium">
                  {t("Select your brand color:")}
                </span>
              </p>
              <div className="flex flex-wrap gap-2 mt-5">
                {[
                  "#FF5733",
                  "#C70039",
                  "#900C3F",
                  "#571845",
                  "#2E86C1",
                  "#48C9B0",
                  "#F39C12",
                  "other",
                ].map((color, index) =>
                  color === "other" ? (
                    <label key={index} className="flex items-center">
                      <input
                        type="color"
                        value={values.themeColor}
                        onChange={(e) =>
                          setValues({ ...values, themeColor: e.target.value })
                        }
                        className="w-10 h-10 rounded-full cursor-pointer"
                      />
                      <span className="ml-2 text-sm text-gray-600">
                        {t("Other")}
                      </span>
                    </label>
                  ) : (
                    <button
                      key={index}
                      className="w-10 h-10 rounded-full"
                      style={{ backgroundColor: color }}
                      onClick={() =>
                        setValues({ ...values, themeColor: color })
                      }
                    />
                  )
                )}
              </div>
            </div>
          </>
        )}
        {step === 9 && loading && <Skeleton active />}
        {step === 9 && !loading && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    {t("Does it look right?")}
                  </span>
                </Text>
                <Text as="p" className="!font-normal mt-10">
                  <span className="font-medium text-sm text-[#4b5563] ">
                    {t("Company Description")}
                  </span>
                </Text>
                <div>
                  <Typography.Paragraph
                    className="w-full"
                    editable={{
                      onChange: (x) => {
                        if (!x) return;
                        setResult((e) => ({
                          ...e,
                          shortCompanyDescription: x,
                        }));
                      },
                    }}
                  >
                    {result?.shortCompanyDescription}
                  </Typography.Paragraph>
                </div>
                <Text as="p" className="!font-normal mt-10">
                  <span className="font-medium text-sm text-[#4b5563]">
                    {t("Benefits")}
                  </span>
                </Text>
                <div>
                  {result?.benefits?.map?.((item, i) => (
                    <div>
                      <Typography.Paragraph
                        className="w-full"
                        editable={{
                          onChange: (e) => {
                            if (!e) return;
                            const benefits = result.benefits;
                            benefits[i] = e;
                            setResult((e) => ({
                              ...e,
                              benefits,
                            }));
                          },
                        }}
                      >
                        • {item}
                      </Typography.Paragraph>
                    </div>
                  ))}
                </div>

                <Text as="p" className="!font-normal mt-10">
                  <span className="font-medium text-sm text-[#4b5563]">
                    {t("Customer Difficulties")}
                  </span>
                </Text>
                <div>
                  {result?.customerDifficulties?.map?.((item, i) => (
                    <div>
                      <Typography.Paragraph
                        className="w-full"
                        editable={{
                          onChange: (e) => {
                            if (!e) return;
                            const customerDifficulties =
                              result.customerDifficulties;
                            customerDifficulties[i] = e;
                            setResult((e) => ({
                              ...e,
                              customerDifficulties,
                            }));
                          },
                        }}
                      >
                        • {item}
                      </Typography.Paragraph>
                    </div>
                  ))}
                </div>

                <Text as="p" className="!font-normal mt-10">
                  <span className="font-medium text-sm text-[#4b5563]">
                    {t("Pricing Details")}
                  </span>
                </Text>
                <div>
                  <Typography.Paragraph
                    className="w-full"
                    editable={{
                      onChange: (e) => {
                        if (!e) return;
                        setResult((e) => ({
                          ...e,
                          pricingDetails: e,
                        }));
                      },
                    }}
                  >
                    {result?.pricingDetails}
                  </Typography.Paragraph>
                </div>
              </div>
            </div>
          </>
        )}
        {step === 10 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    {t("Which layout do you like more?")}
                  </span>
                </Text>

                <div className="flex justify-center items-center space-x-4 my-8">
                  {[
                    {
                      id: "default",
                      src: "/templates/Group 95.png",
                      alt: t("Default"),
                    },
                    {
                      id: "secondary",
                      src: "/templates/Group 96.png",
                      alt: t("Secondary"),
                    },
                  ].map((image) => (
                    <div
                      key={image.id}
                      className={`cursor-pointer p-1 border-2 ${
                        selectedTemplate === image.id
                          ? "border-blue-500"
                          : "border-gray-300"
                      } rounded-lg`}
                      onClick={() => {
                        setSelectedTemplate(image.id);
                        localStorage.selectedTemplate = image.id;
                      }}
                    >
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="h-48 w-auto"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        {step === 11 && (
          <>
            <div
              className={!surveySubmit ? "flex justify-center mr-[25%]" : ""}
            >
              <Onboarding2 onFinish={() => setSetupFinished(true)} />

              <Modal
                open={!surveySubmit && step === 11}
                closable={false}
                footer={[]}
                style={{ marginRight: "20%" }}
              >
                <div className="animate-bounce-once">
                  <div className="flex flex-col items-start">
                    <Text as="p" className="!font-normal tracking-[-0.16px]">
                      <span className="font-medium text-[#4b5563] mt-5">
                        {t(
                          "While we work on creating a customized sales strategy for your business, please take a few seconds to answer the survey to move on."
                        )}
                      </span>
                    </Text>
                    <Text as="p" className="!font-normal tracking-[-0.16px]">
                      <span className="font-medium text-[#4b5563] mt-5">
                        {t(
                          "We are curious to know more about you to personalize your experience even more!"
                        )}
                      </span>
                    </Text>
                  </div>
                  <MultiStepComponent
                    additionalClassNames={"min-h-[55vh]"}
                    onFinish={(e) => {
                      localStorage.onboardingSurvey = JSON.stringify(e);
                      setSurveySubmit(true);
                    }}
                    steps={[
                      {
                        id: "step1",
                        name: t("Your Purpose"),
                        form: [
                          {
                            fieldName: "websitePurpose",
                            label: t("Who will you be building websites for?"),
                            type: "radio",
                            required: true,
                            options: [
                              {
                                value: "freelancer",
                                label: t("Clients as a freelancer"),
                              },
                              {
                                value: "agency",
                                label: t("Clients as part of an agency"),
                              },
                              {
                                value: "personal",
                                label: t(
                                  "Myself (personal brand or portfolio)"
                                ),
                              },
                              {
                                value: "ownBusiness",
                                label: t("My company or business"),
                              },
                              {
                                value: "employer",
                                label: t("The company I work for"),
                              },
                              { value: "others", label: t("Others") },
                            ],
                          },
                        ],
                      },
                      {
                        id: "step2",
                        name: t("How You Found"),
                        form: [
                          {
                            fieldName: "discoverySource",
                            label: t("How did you hear about SalesAI?"),
                            type: "radio",
                            required: true,
                            options: [
                              {
                                value: "Friend / Colleague",
                                label: t("Friend / Colleague"),
                              },
                              { value: "Twitter", label: t("Twitter") },
                              { value: "Youtube", label: t("Youtube") },
                              { value: "Instagram", label: t("Instagram") },
                              { value: "Tiktok", label: t("Tiktok") },
                              { value: "Facebook", label: t("Facebook") },
                              {
                                value: "Facebook Ads",
                                label: t("Facebook Ads"),
                              },
                              { value: "Google", label: t("Google") },
                              { value: "Google Ads", label: t("Google Ads") },
                              {
                                value: "Other Search Engines",
                                label: t("Other Search Engines"),
                              },
                              { value: "others", label: t("Others") },
                            ],
                          },
                        ],
                      },
                      {
                        id: "step3",
                        name: t("Role Description"),
                        form: [
                          {
                            fieldName: "role",
                            label: t("What best describes your role?"),
                            type: "radio",
                            required: true,
                            options: [
                              {
                                value: "Small to medium size business owner",
                                label: t("Small to medium size business owner"),
                              },
                              { value: "Enterprener", label: t("Enterprener") },
                              {
                                value: "Large business owner",
                                label: t("Large business owner"),
                              },
                              {
                                value: "Marketing agency",
                                label: t("Marketing agency"),
                              },
                              { value: "Marketer", label: t("Marketer") },
                              {
                                value: "Web designer",
                                label: t("Web designer"),
                              },
                              { value: "Others", label: t("Others") },
                            ],
                          },
                        ],
                      },
                    ]}
                  />
                </div>
              </Modal>
            </div>
          </>
        )}

        {/* interest selection section */}
        <div className="flex flex-col pb-16 mdx:pb-5">
          {/* navigation next section */}
          <div className="container-xs mt-[90px]  mdx:p-5 mdx:px-5">
            <Button
              onClick={() => {
                if (step === 7) {
                  setLoading(true);

                  if (socket.current) {
                    socket.current.close();
                  }
                  socket.current = new WebSocket(
                    `wss://booklified-chat-socket.herokuapp.com`
                  );

                  socket.current.addEventListener("open", async () => {
                    socketPing.current = setInterval(
                      () => socket.current.send(JSON.stringify({ id: "PING" })),
                      30000
                    );
                    const content = `Here is the details of a business: ${JSON.stringify(
                      values
                    )}
                          
                          According to the details provided, please generate the following data, and in your answer only send a JSON object as specified below that can be parsed using Javascript JSON.parse. It's imperative that you do not add anything else to your response. Send me only the string that can be safely parsed with JSON.parse.
                          It's imperative that you provide some meaningful results according to provided details of business. Your results should be in accordance with the details provided
              
                          {
                              serviceDetails: String,
                              customerPersonaAge: [String],
                              customerPersonaGender: [String],
                              customerPersonaInterest: [String],
                              customerPersonaIndustry: [String],
                              customerPersonaKeywords: [String],
                              shortCompanyDescription: String,
                              benefits: [String],
                              customerDifficulties: [String],
                              pricingDetails: String,
                          }
                          `;

                    socket.current.send(
                      JSON.stringify({
                        id: "OPEN_AI_PROMPT",
                        payload: {
                          content,
                          model: "gpt-3.5-turbo",
                          partner: partner?._id,
                          app_id: "hidec",
                          max_tokens: 4096,
                        },
                      })
                    );
                  });

                  socket.current.addEventListener("message", async (event) => {
                    const message = JSON.parse(event.data);
                    const response = message.payload?.response
                      ?.replace?.(/```json/g, "")
                      ?.replace?.(/```/g, "");

                    setLoading(false);
                    const result = JSON.parse(response);
                    setResult(result);

                    if (socketPing.current) clearInterval(socketPing.current);
                  });
                }

                if (step === NUM_OF_STEPS) {
                  if (localStorage.demoVacancy) navigate(`/page/demo`);
                  else {
                    const interval = setInterval(() => {
                      if (localStorage.demoVacancy) {
                        clearInterval(interval);
                        navigate(`/page/demo`);
                      }
                    }, 15000);
                  }
                }
                if (step === 10) {
                  if (socket.current) {
                    socket.current.close();
                  }
                  socket.current = new WebSocket(
                    `wss://booklified-chat-socket.herokuapp.com`
                  );

                  socket.current.addEventListener("open", async () => {
                    socketPing.current = setInterval(
                      () => socket.current.send(JSON.stringify({ id: "PING" })),
                      30000
                    );

                    const basePrompt = `${partner?.landingPagePrompt}
                  
                    Target Audience:
                    Persona Age: ${result?.customerPersonaAge ?? ""}
                    Persona Gender: ${result?.customerPersonaGender ?? ""}
                    Persona Interest: ${result?.customerPersonaInterest ?? ""}
                    Persona Industry: ${result?.customerPersonaIndustry ?? ""}
                    Persona Keywords: ${result?.customerPersonaKeywords ?? ""}
              
                    Also consider this information (LANDING PAGE DESCRIPTION) to create the content: ${
                      localStorage.describeLandingpage
                    }
                    
                    The language used in the LANDING PAGE DESCRIPTION should be used when generating text content for the landing page.
                    `;

                    const images = await Promise.all(
                      new Array(6)
                        .fill(0)
                        .map(async () => {
                          try {
                            const response = await PublicService.replicateGen({
                              input: {
                                prompt: `${localStorage.describeLandingpage}
                        
                        For our landing page, we need a picture that describes the promise / value proposition of our business

                        Image without any text
                        `,
                              },
                              id: "bytedance/sdxl-lightning-4step:5f24084160c9089501c1b3545d9be3c27883ae2239b6f412990e82d4a6210f8f",
                            });

                            const replicateUrl = response?.data?.output?.[0];

                            // Check if we have a valid Replicate URL
                            if (replicateUrl) {
                              // Fetch the image from the replicate URL
                              const imageResponse = await fetch(replicateUrl);
                              const blob = await imageResponse.blob();

                              // Create a temporary file to upload to Cloudinary
                              const file = new File(
                                [blob],
                                `replicate_image_${Date.now()}.png`,
                                { type: blob.type }
                              );

                              // Upload the image to Cloudinary
                              const result = await UploadService.upload(file);

                              // Return the Cloudinary secure URL
                              return result?.data?.secure_url || null;
                            }
                            return null;
                          } catch (e) {
                            return null;
                          }
                        })
                        .filter((i) => !!i)
                    );

                    const content = `
                        ${basePrompt}
                  
                        Your answer should be in JSON format. Do not include anything else in your answer. I should be able to process your answer with JSON.parse function in Javascript. This structure should be followed (change the content):
                        ${
                          templates({ images })[
                            localStorage?.selectedTemplate ?? "default"
                          ]
                        }
                  
                        The "id" field can never be anything other than:
                        Hero
                        CustomerPainPoints
                        CallToAction
                        FeatureSlider
                        TestemonialSectionSlider
                        AdminFeatures
                        DivideLine
                        LeadForm
                        Benefits
                        ValueProp
                  
                        It is imminent to have all of the fields in the specified order included in your answer:
                        ${JSON.parse(
                          templates({ images })[
                            localStorage?.selectedTemplate ?? "default"
                          ]
                        )
                          .pageContent.map((a) => a.id)
                          .join("\n")}
                  
                        Do not change any links, images, and buttons from the provided example. Those should be exactly same.
                        `;

                    socket.current.send(
                      JSON.stringify({
                        id: "OPEN_AI_PROMPT",
                        payload: {
                          content,
                          model: "gpt-3.5-turbo",
                          partner: partner?._id,
                          app_id: "hidec",
                          max_tokens: 4096,
                        },
                      })
                    );
                  });

                  socket.current.addEventListener("message", async (event) => {
                    const message = JSON.parse(event.data);
                    const response = message.payload?.response;
                    const data = JSON.parse(response);

                    if (!Array.isArray(data.pageContent)) return;

                    localStorage.demoVacancy = JSON.stringify({
                      name:
                        data?.name ?? moment().format(STANDARD_MOMENT_FORMAT),
                      pageContent: data.pageContent,
                      result,
                      values,
                    });

                    if (socketPing.current) clearInterval(socketPing.current);
                  });
                }

                if (step === 11 && (!setupFinished || !surveySubmit)) return;
                if (step === 9 && loading) return;
                setStep((s) => Math.min(NUM_OF_STEPS, s + 1));
              }}
              color="indigo-500"
              size="sm"
              className={`w-full rounded-[29px] font-bold tracking-[-0.20px] smx:px-5 ${
                (step === 11 && (!setupFinished || !surveySubmit)) ||
                (step === 9 && loading)
                  ? "opacity-50"
                  : ""
              }`}
            >
              {t("Next")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
