/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { UserCircleIcon } from "@heroicons/react/24/solid";
import {
  Select as AntdSelect,
  Button,
  ColorPicker,
  Divider,
  Modal,
  Popconfirm,
  Progress,
  Skeleton,
  Space,
  Switch,
  Tooltip,
  message,
} from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrInfo } from "react-icons/gr";
import { Mention, MentionsInput } from "react-mentions";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import Select from "../../components/Select";
import {
  DEFAULT_CANDIDATE_MESSAGE_VARIABLES,
  ZOOM_ENABLED,
  countries,
} from "../../data/constants";
import {
  getPartner,
  selectDarkMode,
  selectLoading,
} from "../../redux/auth/selectors";
import AuthService from "../../service/AuthService";
import CalendlyService from "../../service/CalendlyService";
import SMTPService from "../../service/SMTPService";
import UploadService from "../../service/UploadService";
import ZoomService from "../../service/ZoomService";
import classNamesBody from "./Message/body.module.css";
import classNames from "./Message/example.module.css";
import SMTPModalComponent from "./SMTPModal";

export const mailOptions = [
  { value: "index", label: "Default" },
  { value: "blank", label: "Clean" },
];

function getColorFun(r, g, b) {
  return (
    "#" +
    ((1 << 24) + (Math.ceil(r) << 16) + (Math.ceil(g) << 8) + Math.ceil(b))
      .toString(16)
      .slice(1)
  );
}

export default function Example() {
  const { t } = useTranslation();
  const [me, setMe] = useState(null);
  const [softValue, setSoftValue] = useState(null);
  const [CVEditModal, setCVEditModal] = useState(null);
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [calendlyToken, setCalendlyToken] = useState(null);
  const [zoomToken, setZoomToken] = useState(null);
  const [SMTPModal, setSMTPModal] = useState(false);
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [smtp, setSmtp] = useState(null);
  const [eventTypes, setEventTypes] = useState(null);
  const loading = useSelector(selectLoading);
  const partner = useSelector(getPartner);
  const darkMode = useSelector(selectDarkMode);

  const fileInput = useRef(null);
  const fileInput31 = useRef(null);
  const fileInput32 = useRef(null);
  const fileInput33 = useRef(null);

  useEffect(() => {
    fileInput.current = document.getElementById("fileInput");
    if (fileInput.current)
      fileInput.current.addEventListener("change", async () => {
        const selectedFile = fileInput.current.files[0]; // Get the selected file
        if (selectedFile) {
          const result = await UploadService.upload(selectedFile, 1);
          await AuthService.updateMe({
            avatar: result.data.secure_url,
          });
          const res = await AuthService.me();
          setMe(res.data.me);
          setOnboardingStatus(res.data.onboardingStatus);

          // You can perform further actions with the selected file here
        } else {
          console.log("No file selected.");
        }
      });
  }, []);

  useEffect(() => {
    fileInput31.current = document.getElementById("fileInput31");
    if (fileInput31.current)
      fileInput31.current.addEventListener("change", async () => {
        const selectedFile = fileInput31.current.files[0]; // Get the selected file
        if (selectedFile) {
          const result = await UploadService.upload(selectedFile, 1);
          await AuthService.updateMe({
            jobPortalHeroImage: result.data.secure_url,
          });
          const res = await AuthService.me();
          setMe(res.data.me);
          setOnboardingStatus(res.data.onboardingStatus);

          // You can perform further actions with the selected file here
        } else {
          console.log("No file selected.");
        }
      });
  }, []);
  useEffect(() => {
    fileInput32.current = document.getElementById("fileInput32");
    if (fileInput32.current)
      fileInput32.current.addEventListener("change", async () => {
        const selectedFile = fileInput32.current.files[0]; // Get the selected file
        if (selectedFile) {
          const result = await UploadService.upload(selectedFile, 1);
          await AuthService.updateMe({
            jobPortalJobsSectionImage: result.data.secure_url,
          });
          const res = await AuthService.me();
          setMe(res.data.me);
          setOnboardingStatus(res.data.onboardingStatus);

          // You can perform further actions with the selected file here
        } else {
          console.log("No file selected.");
        }
      });
  }, []);
  useEffect(() => {
    fileInput33.current = document.getElementById("fileInput33");
    if (fileInput33.current)
      fileInput33.current.addEventListener("change", async () => {
        const selectedFile = fileInput33.current.files[0]; // Get the selected file
        if (selectedFile) {
          const result = await UploadService.upload(selectedFile, 1);
          await AuthService.updateMe({
            logo: result.data.secure_url,
          });
          const res = await AuthService.me();
          setMe(res.data.me);
          setOnboardingStatus(res.data.onboardingStatus);

          // You can perform further actions with the selected file here
        } else {
          console.log("No file selected.");
        }
      });
  }, []);

  const handleUpdate = useCallback(async () => {
    await AuthService.updateMe(softValue);
    const res = await AuthService.me();
    setMe(res.data.me);
    setOnboardingStatus(res.data.onboardingStatus);
    document.dispatchEvent(new CustomEvent("REFRESH.PROFILE"));

    if (
      res.data.onboardingStatus?.profileCompletion === 100 &&
      localStorage.lastVisit
    )
      window.location.href = localStorage.lastVisit;
  }, [softValue]);

  useEffect(() => {
    setSoftValue(me);
    setSmtp(me?.smtp ?? null);
  }, [me]);

  useEffect(() => {
    if (!partner?.calendlyclientId) return;
    CalendlyService.getEventTypes().then(({ data }) => {
      setEventTypes(data.eventTypes);
    });
  }, [partner]);

  useEffect(() => {
    AuthService.me().then((data) => {
      setMe(data.data.me);
      setOnboardingStatus(data.data.onboardingStatus);
    });
  }, []);

  useEffect(() => {
    if (partner?.calendlyclientId)
      CalendlyService.getCurrentToken()
        .then(({ data }) => {
          setCalendlyToken(data);
        })
        .catch(() => setCalendlyToken(null));

    if (ZOOM_ENABLED && partner?.zoomClientId)
      ZoomService.getCurrentToken()
        .then(({ data }) => {
          setZoomToken(data);
        })
        .catch(() => setZoomToken(null));
  }, []);

  const getProps = (fieldKey) => ({
    value: softValue?.[fieldKey],
    onChange: (e) =>
      setSoftValue((current) => ({
        ...current,
        [fieldKey]: e?.target?.value ?? e,
      })),
  });

  const notificationConfig = [
    {
      title: t("Security Alerts"),
      id: "securityalerts",
      description: t(
        "Notifications regarding account security, like login from a new device or location."
      ),
      push: true,
    },
    {
      title: t("Platform Updates"),
      id: "platformupdates",
      description: t(
        "Information about new features, updates, or maintenance schedules."
      ),
    },

    {
      title: t("Newsletter"),
      id: "newsletter",
      description: t(
        "Regular updates on news, insights, and tips from our community."
      ),
    },
    {
      title: t("Support"),
      id: "support",
      description: t("Receive updates on your support inquiries."),
    },
    {
      title: t("New Lead"),
      id: "candidateApplied",
      description: t(
        "Get notified when a new lead has arrived in your funnel."
      ),
    },
  ];

  if (!softValue) return <Skeleton />;

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      <div className="sticky top-16 z-30 flex h-12 shrink-0 items-center  border-b border-gray-200 dark:border-gray-600  bg-white dark:bg-gray-900  shadow-sm dark:shadow-gray-400/50 ">
        <div className="whitespace-nowrap mr-2 mb-2">
          {t("Profile Completion")}
        </div>
        <Progress
          percent={onboardingStatus?.profileCompletion}
          status={onboardingStatus?.profileCompletion < 100 ? "active" : null}
        />
      </div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 ">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-400 ">
            {t("Personal Information")}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            {t("Use a permanent address where you can receive mail.")}
          </p>
        </div>

        <form
          className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className={`"block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 " ${
                    !softValue?.firstName ? "text-red font-semibold" : ""
                  }`}
                >
                  {t("First name")}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                    {...getProps("firstName")}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400  ${
                    !softValue?.lastName ? "text-red font-semibold" : ""
                  }`}
                >
                  {t("Last name")}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                    {...getProps("lastName")}
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
                >
                  {t("Email address")}
                </label>
                <div className="mt-2 relative">
                  <input
                    disabled
                    type="email"
                    className="block w-full bg-gray-200 rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                    {...getProps("email")}
                  />
                  <button
                    onClick={() => setChangeEmailModal(true)}
                    className="absolute end-0 top-0 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {t("Change")}
                  </button>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
                >
                  {t("Phone")}
                </label>
                <div className="mt-2">
                  <PhoneInput
                    defaultCountry="US"
                    inputClass="dark:!bg-gray-900"
                    dropdownClass="dark:!text-black"
                    buttonClass="dark:!bg-gray-900"
                    value={softValue?.["phone"] ?? ""}
                    onChange={(e) =>
                      setSoftValue((current) => ({
                        ...current,
                        ["phone"]: e?.target?.value ?? e,
                      }))
                    }
                  />
                </div>
              </div>

              {me?.role !== "team-member" && (
                <>
                  <div className="col-span-full">
                    <label
                      htmlFor="street-address"
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400`}
                    >
                      {t("Line 1")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="street-address"
                        id="street-address"
                        autoComplete="street-address"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                        {...getProps("line1")}
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="street-address"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
                    >
                      {t("Line 2")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="street-address"
                        id="street-address"
                        autoComplete="street-address"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                        {...getProps("line2")}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2 sm:col-start-1">
                    <label
                      htmlFor="city"
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                    >
                      {t("City")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="city"
                        id="city"
                        autoComplete="address-level2"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                        {...getProps("city")}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="region"
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                    >
                      {t("State / Province")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="region"
                        id="region"
                        autoComplete="address-level1"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                        {...getProps("state")}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="postal-code"
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400`}
                    >
                      {t("ZIP / Postal code")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                        {...getProps("zipCode")}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
                    >
                      {t("Country")}
                    </label>
                    <div className="mt-2">
                      <Select options={countries} {...getProps("country")} />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <div className="flex gap-3 items-center">
                      <label
                        className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                      >
                        {t("Privacy Policy Link")}
                      </label>
                      <Tooltip title="This is the privacy policy of your company that leads should accept when opting-in via your landing pages.">
                        <GrInfo />
                      </Tooltip>
                    </div>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                        {...getProps("privacyPolicyLink")}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button
              onClick={handleUpdate}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t("Save")}
            </button>
          </div>
        </form>
      </div>

      {me?.role !== "team-member" && (
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 ">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-400 ">
              {t("Messaging")}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {t(
                "Configure the replacements for general messaging variables and general email templates here."
              )}
            </p>
          </div>

          <form
            className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="px-4 py-6 sm:p-8">
              <div className="grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <div className="flex gap-3 items-center">
                    <label
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                    >
                      {t("User Calendar Link")}
                    </label>
                    <Tooltip
                      title={t(
                        "This is the replacement for the 'User Calendar Link' variable in your messages."
                      )}
                    >
                      <GrInfo />
                    </Tooltip>
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                      {...getProps("userCalendarLink")}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <div className="flex gap-3 items-center">
                    <label
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                    >
                      {t("Company Name")}
                    </label>
                    <Tooltip title="This is the replacement for the 'Company Name' variable in your messages.">
                      <GrInfo />
                    </Tooltip>
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                      {...getProps("companyName")}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <div className="flex gap-3 items-center">
                    <label
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                    >
                      {t("Company Website")}
                    </label>
                    <Tooltip title="This is the replacement for the 'Company Website' variable in your messages.">
                      <GrInfo />
                    </Tooltip>
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                      {...getProps("companyWebsite")}
                    />
                  </div>
                </div>

                {/* <div className="sm:col-span-6">
                  <div className="flex gap-3 items-center">
                    <label
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                    >
                      CV Message
                    </label>
                    <Tooltip title="This template serves as the standard communication for applicants who opt for a reminder to submit their Curriculum Vitae upon completion of the application form, in cases where submission of a CV is a requisite part of the application process.">
                      <GrInfo />
                    </Tooltip>
                  </div>
                  <button
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      setCVEditModal(true);
                    }}
                  >
                    Click to edit
                  </button>
                </div> */}

                <div className="sm:col-span-4">
                  <Space>
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
                    >
                      {t("Email Template")}
                    </label>
                    <Tooltip title="You can modify the visual design of your automated email communications.">
                      <GrInfo className="text-[#FFA000]" />
                    </Tooltip>
                  </Space>
                  <div className="mt-2">
                    <Select
                      options={mailOptions}
                      {...getProps("mailTemplate")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                onClick={handleUpdate}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t("Save")}
              </button>
            </div>
          </form>
        </div>
      )}

      {me?.role !== "team-member" && (
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 ">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-400 ">
              {t("Ideal Customer Profile")}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {t("Configure the demographics of your ideal customer profile.")}
            </p>
          </div>

          <form
            className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="px-4 py-6 sm:p-8">
              <div className="grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <div className="flex gap-3 items-center">
                    <label
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                    >
                      {t("Describe your service details")}
                    </label>
                  </div>
                  <div className="mt-2">
                    <textarea
                      rows={5}
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                      {...getProps("serviceDetails")}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <div className="flex gap-3 items-center">
                    <label
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                    >
                      {t("Target Age")}
                    </label>
                  </div>
                  <div className="mt-2">
                    <AntdSelect
                      mode="multiple"
                      options={[
                        { value: "0-13", label: "0-13" },
                        { value: "14-18", label: "14-18" },
                        { value: "19-24", label: "19-24" },
                        { value: "25-34", label: "25-34" },
                        { value: "35-44", label: "35-44" },
                        { value: "45-54", label: "45-54" },
                        { value: "55-64", label: "55-64" },
                        { value: "65+", label: "65+" },
                      ]}
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                      {...getProps("customerPersonaAge")}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <div className="flex gap-3 items-center">
                    <label
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                    >
                      {t("Target Gender")}
                    </label>
                  </div>
                  <div className="mt-2">
                    <AntdSelect
                      mode="multiple"
                      options={[
                        { value: "man", label: "Man" },
                        { value: "woman", label: "Woman" },
                        { value: "diverse", label: "Diverse" },
                      ]}
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                      {...getProps("customerPersonaGender")}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <div className="flex gap-3 items-center">
                    <label
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                    >
                      {t("Target Interests")}
                    </label>
                  </div>
                  <div className="mt-2">
                    <AntdSelect
                      mode="tags"
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                      {...getProps("customerPersonaInterest")}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <div className="flex gap-3 items-center">
                    <label
                      className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                    >
                      {t("Target Industries")}
                    </label>
                  </div>
                  <div className="mt-2">
                    <AntdSelect
                      mode="tags"
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                      {...getProps("customerPersonaIndustry")}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <div className="flex gap-3 items-center">
                  <label
                    className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                  >
                    {t("Target Keywords")}
                  </label>
                </div>
                <div className="mt-2">
                  <AntdSelect
                    mode="tags"
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                    {...getProps("customerPersonaKeywords")}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                onClick={handleUpdate}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t("Save")}
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 ">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-400 ">
            {t("Security")}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            {t("Change your password here.")}
          </p>
        </div>

        <form
          className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          onSubmit={async (e) => {
            e.preventDefault();
            const current = e.target[0].value;
            const newPassword = e.target[1].value;
            const repeatNew = e.target[2].value;
            await AuthService.updatePassword({
              current,
              repeatNew,
              newPassword,
            });
            e.target[0].value = "";
            e.target[1].value = "";
            e.target[2].value = "";
          }}
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className={`"block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 " ${
                    !softValue?.firstName ? "text-red font-semibold" : ""
                  }`}
                >
                  {t("Current password")}
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className={`"block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 " ${
                    !softValue?.firstName ? "text-red font-semibold" : ""
                  }`}
                >
                  {t("New password")}
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className={`"block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 " ${
                    !softValue?.firstName ? "text-red font-semibold" : ""
                  }`}
                >
                  {t("Repeat new password")}
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t("Save")}
            </button>
          </div>
        </form>
      </div>

      {me?.role !== "admin" && (
        <>
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 ">
            <div className="px-4 sm:px-0 mt-4">
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-400 ">
                {t("Account")}
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                {t("This is your account information.")}
              </p>
            </div>

            <form
              className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 mt-4"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="px-4 py-6 sm:p-8 ">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="col-span-full">
                    <label
                      htmlFor="photo"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
                    >
                      {t("Photo")}
                    </label>
                    <div className="mt-2 flex items-center gap-x-3">
                      {me?.avatar ? (
                        <img
                          className="h-12 w-12 text-gray-300 rounded-full"
                          src={me.avatar}
                        />
                      ) : (
                        <UserCircleIcon
                          className="h-12 w-12 text-gray-300"
                          aria-hidden="true"
                        />
                      )}
                      <button
                        type="button"
                        className="rounded-md bg-white dark:bg-gray-900 px-2.5 py-1.5 text-sm font-semibold text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => {
                          fileInput?.current?.click?.();
                        }}
                      >
                        {t("Change")}
                      </button>
                    </div>

                    <p className="mt-3 text-sm leading-6 text-gray-600">
                      {t(
                        "Upload a clean profile avatar to personalize your profile."
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                <button
                  onClick={handleUpdate}
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("Save")}
                </button>
              </div>
            </form>
          </div>

          {me?.role !== "team-member" && (
            <div className="grid grid-cols-1 gap-x-8 gap-y-8 ">
              <div className="px-4 sm:px-0 mt-4">
                <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-400 ">
                  {t("Brand")}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  {t(
                    "This section is to tailor the branding of your funnel pages, lead-facing materials (such as funnels and surveys)."
                  )}
                </p>
              </div>

              <form
                className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="px-4 py-6 sm:p-8">
                  <div className=" space-y-10">
                    <div className="">
                      <div className="col-span-full mt-5">
                        <label
                          htmlFor="logo"
                          className="whitespace-nowrap font-bold"
                        >
                          {t("Logo")}
                        </label>
                        <div className="mt-2 flex items-center gap-x-3">
                          {me?.logo ? (
                            <img
                              className="h-24 w-24 text-gray-300"
                              src={me.logo}
                            />
                          ) : (
                            <Skeleton.Image />
                          )}
                          <button
                            type="button"
                            className="rounded-md bg-white dark:bg-gray-900 px-2.5 py-1.5 text-sm font-semibold text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => {
                              fileInput33?.current?.click?.();
                            }}
                          >
                            {t("Change")}
                          </button>
                        </div>
                      </div>
                      <div className="grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3 mt-5">
                        <label className="font-bold whitespace-nowrap">
                          {t("Brand Color")}
                        </label>
                        <ColorPicker
                          value={softValue?.themeColor ?? brandColor}
                          onChange={(e) =>
                            setSoftValue({
                              ...me,
                              themeColor: getColorFun(
                                e.metaColor.r,
                                e.metaColor.g,
                                e.metaColor.b
                              ),
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                  <button
                    onClick={handleUpdate}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {t("Save")}
                  </button>
                </div>
              </form>
            </div>
          )}
        </>
      )}

      {me?.role !== "team-member" && (
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 " id="integrations">
          <div className="px-4 sm:px-0 mt-4">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-400 ">
              {t("Integrations")}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {t("Manage all of your integrations here.")}
            </p>
          </div>

          <form
            className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 mt-4"
            onSubmit={(e) => e.preventDefault()}
          >
            {partner?.calendlyclientId && (
              <>
                <div className="px-4 py-6 sm:p-8 flex gap-3 items-center justify-start">
                  <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-full">
                      <div>
                        <div className="flex items-center justify-start gap-5">
                          <div>
                            <Space>
                              <label
                                className={`"block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 " ${
                                  !calendlyToken?.access_token
                                    ? "text-[#FFA000] font-bold"
                                    : ""
                                }`}
                              >
                                {t("Calendly")}
                              </label>
                              {!calendlyToken?.access_token && (
                                <Tooltip title="By connecting your Calendly account, you can streamline your scheduling with leads. This will also enable you to automatically schedule video meetings.">
                                  <GrInfo className="text-[#FFA000]" />
                                </Tooltip>
                              )}
                            </Space>

                            <div className="mt-2">
                              {calendlyToken?.access_token ? (
                                <Popconfirm
                                  title="Your calendar scheduling functionality will stop working. Are you sure to proceed?"
                                  onConfirm={async () => {
                                    await CalendlyService.disconnectCalendly();
                                    AuthService.me().then((data) => {
                                      setMe(data.data.me);
                                      setOnboardingStatus(
                                        data.data.onboardingStatus
                                      );
                                    });
                                    CalendlyService.getCurrentToken().then(
                                      (data) => {
                                        setCalendlyToken(data.access_token);
                                      }
                                    );
                                  }}
                                >
                                  <button className="px-2 py-1 text-sm border border-red-500 text-red rounded">
                                    {t("Disconnect")}
                                  </button>
                                </Popconfirm>
                              ) : (
                                <button
                                  className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                                  onClick={async () => {
                                    const res =
                                      await CalendlyService.getAuthURI();
                                    window.location.href = res.data.authUri;
                                  }}
                                >
                                  {t("Connect")}
                                </button>
                              )}
                            </div>
                          </div>
                          <img
                            src="/images/logos/calendly2.png"
                            style={{ height: 50 }}
                          />
                        </div>
                      </div>

                      <div>
                        {calendlyToken?.access_token && (
                          <div className="mt-2">
                            <label className="text-sm">
                              {t(
                                "Selecting a default event type for interviews will help you to automate your scheduling and let you focus on the conversations that matter."
                              )}
                            </label>
                            <div className="mt-2">
                              <Select
                                style={{ width: 200 }}
                                value={softValue.preferredCalendlyEvent}
                                onChange={(value) => {
                                  setSoftValue((e) => ({
                                    ...e,
                                    preferredCalendlyEvent: value,
                                  }));
                                }}
                                options={
                                  eventTypes
                                    ? eventTypes.map((eventType) => ({
                                        value: eventType.uri,
                                        label: eventType.name,
                                      }))
                                    : []
                                }
                                className="block w-1/2 rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Divider />
              </>
            )}

            {ZOOM_ENABLED && partner?.zoomClientId && (
              <>
                <div className="px-4 py-6 sm:p-8 flex gap-3 items-center justify-start">
                  <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-full">
                      <div className="flex items-center justify-start gap-3">
                        <label
                          htmlFor="street-address"
                          className={`"block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 " ${
                            !zoomToken?.access_token
                              ? "text-[#FFA000] font-bold"
                              : ""
                          }`}
                        >
                          {t("Zoom")}
                        </label>
                        {!zoomToken?.access_token && (
                          <Tooltip title="By integrating your Zoom account, you can seamlessly engage with your leads via video conference. When a meeting is arranged through Calendly, participants will receive a direct link to join the Zoom session, accessible through our platform for a streamlined connection. You are able to use either Zoom app, or our web app.">
                            <GrInfo className="text-[#FFA000]" />
                          </Tooltip>
                        )}
                      </div>

                      <div className="mt-2">
                        {zoomToken?.access_token ? (
                          <Popconfirm
                            title="Your meeting functionality will stop working. Make sure to remove all 'Interview Meeting Link' variables from your automated messages. Are you sure to proceed?"
                            onConfirm={async () => {
                              await ZoomService.disconnectZoom();
                              AuthService.me().then((data) => {
                                setMe(data.data.me);
                                setOnboardingStatus(data.data.onboardingStatus);
                              });
                              ZoomService.getCurrentToken().then((data) => {
                                setZoomToken(data.access_token);
                              });
                            }}
                          >
                            <button className="px-2 py-1 text-sm border border-red-500 text-red rounded">
                              {t("Disconnect")}
                            </button>
                          </Popconfirm>
                        ) : (
                          <button
                            className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                            onClick={async () => {
                              const res = await ZoomService.getAuthURI();
                              window.location.href = res.data.authUri;
                            }}
                          >
                            {t("Connect")}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <img src="/images/logos/zoom.png" style={{ height: 20 }} />
                </div>
                <Divider />
              </>
            )}

            <div className="px-4 py-6 sm:p-8 ">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full">
                  <div className="flex items-center justify-start gap-3">
                    <label
                      htmlFor="street-address"
                      className={`"block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 " ${
                        !softValue?.smtp?.connectionStatus
                          ? "text-[#FFA000] font-bold"
                          : ""
                      }`}
                    >
                      {t("SMTP")}
                    </label>
                    {!softValue?.smtp?.connectionStatus && (
                      <Tooltip title="By connecting your SMTP, you can send automated or manual emails to your leads from your own inbox.">
                        <GrInfo className="text-[#FFA000]" />
                      </Tooltip>
                    )}
                  </div>

                  <div className="mt-2">
                    {softValue?.smtp?.connectionStatus ? (
                      <Popconfirm
                        title="When you disconnect your SMTP, your emails will no longer be sent from your inbox."
                        onConfirm={async () => {
                          await SMTPService.disconnectSMTP();
                          AuthService.me().then((data) => {
                            setMe(data.data.me);
                            setOnboardingStatus(data.data.onboardingStatus);
                          });
                        }}
                      >
                        <button className="px-2 py-1 text-sm border border-red-500 text-red rounded">
                          {t("Disconnect")}
                        </button>
                      </Popconfirm>
                    ) : (
                      <button
                        className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                        onClick={() => {
                          setSMTPModal(true);
                        }}
                      >
                        {t("Connect")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {[
              // "Google Calendar",
              // "Outlook Calendar",
              // "Google Meets",
              // "Facebook Ads",
              // "Google Adwords",
              // "Apple Search Ads",
              // "Bing Ads",
              // "Linkedin Ads",
              // "Tiktok Ads",
              // "Zapier",
              // "Airtable",
              // "DocuSign",
              // "PandaDoc",
            ].map((item, i) => (
              <div className="px-4 py-6 sm:p-8 " key={i}>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="col-span-full">
                    <div className="flex items-center justify-start gap-3">
                      <label
                        htmlFor="street-address"
                        className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400  text-[#555]`}
                      >
                        {item}
                      </label>
                      <Tooltip title="Coming soon">
                        <GrInfo className="text-[#555]" />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                onClick={handleUpdate}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t("Save")}
              </button>
            </div>
          </form>
        </div>
      )}

      {me?.role !== "team-member" && (
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 ">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-400 ">
              {t("Notifications")}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {t(
                "We'll always let you know about important changes, but you pick what else you want to hear about."
              )}
            </p>
          </div>

          <form
            className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="px-4 py-6 sm:p-8">
              <div className="max-w-2xl space-y-10">
                <fieldset>
                  <legend className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-400 ">
                    {t("By Email")}
                  </legend>
                  <div className="mt-6 space-y-6">
                    {notificationConfig
                      .filter(
                        (n) => !n.push && (!n.scope || n.scope === me?.role)
                      )
                      .map((item, index) => (
                        <div className="relative flex gap-x-3" key={index}>
                          <div className="flex h-6 items-center">
                            <input
                              id={`notification-email-${index}`}
                              name={`notification-email-${index}`}
                              checked={softValue?.notification?.[`${item.id}`]}
                              onChange={(e) => {
                                setSoftValue((v) => ({
                                  ...v,
                                  notification: {
                                    ...v.notification,
                                    [`${item.id}`]: e.target.checked,
                                  },
                                }));
                              }}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="text-sm leading-6">
                            <label
                              htmlFor={`notification-email-${index}`}
                              className="font-medium text-gray-900 dark:text-gray-400 "
                            >
                              {item.title}
                            </label>
                            <p className="text-gray-500">{item.description}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </fieldset>
                {/* <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-400 ">
                  Push Notifications
                </legend>
                <p className="mt-1 text-sm leading-6 text-gray-600 mb-4">
                  These are delivered via SMS to your mobile phone.
                </p>
                {notificationConfig
                  .filter((n) => !!n.push && (!n.scope || n.scope === me?.role))
                  .map((item, index) => (
                    <div className="relative flex gap-x-3 mt-4" key={index}>
                      <div className="flex h-6 items-center">
                        <input
                          id={`notification-phone-${index}`}
                          name={`notification-phone-${index}`}
                          checked={softValue?.notification?.[`${item.id}`]}
                          onChange={(e) => {
                            setSoftValue((v) => ({
                              ...v,
                              notification: {
                                ...v.notification,
                                [`${item.id}`]: e.target.checked,
                              },
                            }));
                          }}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="text-sm leading-6">
                        <label
                          htmlFor={`notification-phone-${index}`}
                          className="font-medium text-gray-900 dark:text-gray-400 "
                        >
                          {item.title}
                        </label>
                        <p className="text-gray-500">{item.description}</p>
                      </div>
                    </div>
                  ))}
              </fieldset> */}
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                onClick={handleUpdate}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t("Save")}
              </button>
            </div>
          </form>
        </div>
      )}

      <SMTPModalComponent
        SMTPModal={SMTPModal}
        setSMTPModal={setSMTPModal}
        handleUpdate={handleUpdate}
        setMe={setMe}
        setOnboardingStatus={setOnboardingStatus}
        smtp={smtp}
        setSmtp={setSmtp}
      />

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!changeEmailModal}
        onCancel={() => setChangeEmailModal(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const newEmail = e.target[0].value;
            const password = e.target[1].value;
            await AuthService.handleUpdateEmailRequest({
              newEmail,
              password,
            });
            setChangeEmailModal(false);
          }}
        >
          <label className="font-bold mt-5">{t("New email")}</label>
          <input
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            placeholder="Enter new email"
          />
          <div className="mt-5">
            <label className="font-bold mt-5 ">{t("Current password")}</label>
            <input
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              placeholder="Enter your current password"
              type="password"
            />
          </div>
          <div className="flex justify-between">
            <div />
            <Button
              className="px-2 py-1 mt-5 text-sm bg-indigo-500 text-white rounded"
              disabled={loading}
              loading={loading}
              htmlType="submit"
            >
              {t("Connect")}
            </Button>
          </div>
        </form>
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!CVEditModal}
        onCancel={() => setCVEditModal(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <div>
          <MentionsInput
            placeholder="Type # to browse variables"
            value={softValue?.["cvReminderEmailSubject"]}
            onChange={(_, value) =>
              setSoftValue((current) => ({
                ...current,
                ["cvReminderEmailSubject"]: value,
              }))
            }
            classNames={classNames}
            a11ySuggestionsListLabel={"Possible variables"}
          >
            <Mention
              trigger="#"
              className={"bg-indigo-100"}
              data={DEFAULT_CANDIDATE_MESSAGE_VARIABLES}
            />
          </MentionsInput>

          <MentionsInput
            placeholder="Type # to browse variables"
            value={softValue?.["cvReminderEmailBody"]}
            onChange={(_, value) =>
              setSoftValue((current) => ({
                ...current,
                ["cvReminderEmailBody"]: value,
              }))
            }
            classNames={classNamesBody}
            a11ySuggestionsListLabel={"Possible variables"}
          >
            <Mention
              trigger="#"
              className={"bg-indigo-100"}
              data={DEFAULT_CANDIDATE_MESSAGE_VARIABLES}
            />
          </MentionsInput>
        </div>

        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            onClick={handleUpdate}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("Save")}
          </button>
        </div>
      </Modal>
    </div>
  );
}
