import React from "react";
import { useTranslation } from "react-i18next";
import MultiStepComponent from "../../../../components/MultiStepComponent";

const PhoneConfiguration = ({ vacancyData, onChange }) => {
  const { t } = useTranslation();

  const steps = [
    {
      id: "step6",
      name: "Twilio",
      form: [
        {
          fieldName: "twilioAuthToken",
          label: t("Twilio Auth Token"),
          type: "input",
          placeholder: t("Enter your Twilio auth token"),
          helpLink:
            "https://console.twilio.com/us1/account/keys-credentials/api-keys",
        },
        {
          fieldName: "twilioSenderPhone",
          label: t("Twilio Sender Phone"),
          type: "input",
          placeholder: t("Enter your Twilio sender phone"),
          helpLink:
            "https://console.twilio.com/us1/develop/phone-numbers/manage/search?isoCountry=US&types[]=Local&types[]=Tollfree&capabilities[]=Sms&capabilities[]=Mms&capabilities[]=Voice&capabilities[]=Fax&searchTerm=&searchFilter=left&searchType=number",
        },
        {
          fieldName: "twilioAccountSID",
          label: t("Twilio Account SID"),
          type: "input",
          placeholder: t("Enter your Account SID"),
          helpLink:
            "https://console.twilio.com/us1/account/keys-credentials/api-keys",
        },
      ],
    },
  ];

  return (
    <>
      <MultiStepComponent
        steps={steps}
        defaultFormData={{
          ...vacancyData,
        }}
        onFinish={async (formData) => {
          onChange(formData);
        }}
      />
    </>
  );
};

export default PhoneConfiguration;
