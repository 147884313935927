import {
  Button,
  Divider,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
  message,
} from "antd";
import Search from "antd/es/input/Search";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrInfo } from "react-icons/gr";
import { IoMdSave } from "react-icons/io";
import { useSelector } from "react-redux";
import { selectDarkMode, selectLoading } from "../../../redux/auth/selectors";
import AuthService from "../../../service/AuthService";
import CrudService from "../../../service/CrudService";
import HiringManagerAddModal from "../ForwardResume/HiringManagerAddModal";
import VariableMessageBox from "../Message/VariableMessageBox";
import VariableSMSBox from "../Message/VariableSMSBox";
import AICallEdit from "./AICallEdit";
import PhoneAutomation from "./PhoneAutomation";
import SurveyFormSetup from "./SurveyFormSetup";

const GeneralWorkflowConfigurator = ({ VacancyId }) => {
  const { t } = useTranslation();
  const [me, setMe] = useState(null);
  const [stages, setStages] = useState(null);
  const [vacancyData, setVacancyData] = useState(null);
  const loading = useSelector(selectLoading);

  const reloadData = useCallback(async () => {
    if (!VacancyId) return;

    AuthService.me().then(({ data }) => setMe(data.me));
    CrudService.search("VacancyStage", 1000, 1, {
      filters: { vacancyId: VacancyId },
      sort: { sort: 1, createdAt: 1 },
    }).then(({ data }) => {
      setStages(data.items);
    });

    CrudService.getSingle("Vacancy", VacancyId).then(({ data }) => {
      setVacancyData(data);
    });
  }, [VacancyId]);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  const handleConfigChange = useCallback(
    async (field, value) => {
      if (!VacancyId) return;

      await CrudService.update("Vacancy", VacancyId, {
        [field]: value,
      }).then(() => reloadData());
    },
    [reloadData, VacancyId]
  );

  if (!me) return <Skeleton active />;
  if (!stages) return <Skeleton active />;
  if (!vacancyData) return <Skeleton active />;

  return (
    <>
      <h2 className="font-bold text-lg mb-8">
        {t("General Workflow Configuration")}
      </h2>

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t("When a lead applies, automatically assign them to the stage")}{" "}
          <span className="text-indigo-500">
            {stages?.find?.((s) => s._id === vacancyData?.onApplyAssignStage)
              ?.name ?? t("None")}
          </span>
        </label>
        <Select
          style={{ width: 200 }}
          loading={loading}
          value={vacancyData?.onApplyAssignStage}
          onChange={(value) => handleConfigChange("onApplyAssignStage", value)}
        >
          <Select.Option key={1} value={null}>
            {t("None")}
          </Select.Option>
          {stages.map((stage) => (
            <Select.Option key={stage._id} value={stage._id}>
              {stage.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Divider />

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t(
            "When you send a meeting request to lead, automatically assign them to the stage"
          )}{" "}
          <span className="text-indigo-500">
            {stages?.find?.(
              (s) => s._id === vacancyData?.onRequestMeetingAssignStage
            )?.name ?? t("None")}
          </span>
        </label>
        <Select
          style={{ width: 200 }}
          loading={loading}
          value={vacancyData?.onRequestMeetingAssignStage}
          onChange={(value) =>
            handleConfigChange("onRequestMeetingAssignStage", value)
          }
        >
          <Select.Option key={1} value={null}>
            {t("None")}
          </Select.Option>
          {stages.map((stage) => (
            <Select.Option key={stage._id} value={stage._id}>
              {stage.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Divider />

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t(
            "When lead scheduled a meeting, automatically assign them to the stage"
          )}{" "}
          <span className="text-indigo-500">
            {stages?.find?.(
              (s) => s._id === vacancyData?.onScheduledMeetingAssignStage
            )?.name ?? t("None")}
          </span>
        </label>
        <Select
          style={{ width: 200 }}
          loading={loading}
          value={vacancyData?.onScheduledMeetingAssignStage}
          onChange={(value) =>
            handleConfigChange("onScheduledMeetingAssignStage", value)
          }
        >
          <Select.Option key={1} value={null}>
            {t("None")}
          </Select.Option>
          {stages.map((stage) => (
            <Select.Option key={stage._id} value={stage._id}>
              {stage.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Divider />

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t(
            "When a scheduled meeting has been cancelled, automatically assign lead to the stage"
          )}{" "}
          <span className="text-indigo-500">
            {stages?.find?.(
              (s) => s._id === vacancyData?.onCancelledMeetingAssignStage
            )?.name ?? t("None")}
          </span>
        </label>
        <Select
          style={{ width: 200 }}
          loading={loading}
          value={vacancyData?.onCancelledMeetingAssignStage}
          onChange={(value) =>
            handleConfigChange("onCancelledMeetingAssignStage", value)
          }
        >
          <Select.Option key={1} value={null}>
            {t("None")}
          </Select.Option>
          {stages.map((stage) => (
            <Select.Option key={stage._id} value={stage._id}>
              {stage.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Divider />

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t(
            "When I mark lead as unqualified, automatically assign them to the stage"
          )}{" "}
          <span className="text-indigo-500">
            {stages?.find?.((s) => s._id === vacancyData?.onRejectedAssignStage)
              ?.name ?? t("None")}
          </span>
        </label>
        <Select
          style={{ width: 200 }}
          loading={loading}
          value={vacancyData?.onRejectedAssignStage}
          onChange={(value) =>
            handleConfigChange("onRejectedAssignStage", value)
          }
        >
          <Select.Option key={1} value={null}>
            {t("None")}
          </Select.Option>
          {stages.map((stage) => (
            <Select.Option key={stage._id} value={stage._id}>
              {stage.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Divider />
    </>
  );
};

const WorkflowConfigurator = ({ workflow, VacancyId }) => {
  const { t } = useTranslation();
  const [workflowData, setWorkflowData] = useState(null);
  const [webhookURL, setWebhookURL] = useState("");
  const [activatedMessagingTemplate, setActivatedMessagingTemplate] =
    useState(null);
  const loading = useSelector(selectLoading);
  const darkMode = useSelector(selectDarkMode);
  const [stages, setStages] = useState(null);
  const [addHiringManagerModal, setAddHiringManagerModal] = useState(null);
  const [hiringManagers, setHiringManagers] = useState([]);
  const [smsModal, setSMSModal] = useState(null);

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    const totalMinutes = days * 24 * 60 + hours * 60 + minutes;
    handleConfigChange("idleMinutes", totalMinutes);
  }, [days, hours, minutes]);

  const reloadData = useCallback(() => {
    if (!!workflow?.stageId) {
      CrudService.getSingle("VacancyStage", workflow.stageId).then(
        ({ data }) => {
          setWorkflowData(data);

          if (data?.idleMinutes) {
            const totalMinutes = data.idleMinutes;
            const calculatedDays = Math.floor(totalMinutes / (24 * 60));
            const remainingMinutesAfterDays = totalMinutes % (24 * 60);
            const calculatedHours = Math.floor(remainingMinutesAfterDays / 60);
            const calculatedMinutes = remainingMinutesAfterDays % 60;

            setDays(calculatedDays);
            setHours(calculatedHours);
            setMinutes(calculatedMinutes);
          }
        }
      );
    }

    CrudService.search("HiringManagerContact", 10000000, 1, {
      sort: { createdAt: 1 },
    }).then(({ data }) => {
      setHiringManagers(data.items);
    });
  }, [workflow]);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  useEffect(() => {
    if (!VacancyId) return;

    CrudService.search("VacancyStage", 1000, 1, {
      filters: { vacancyId: VacancyId },
      sort: { sort: 1, createdAt: 1 },
    }).then(({ data }) => {
      setStages(data.items);
    });
  }, [VacancyId]);

  useEffect(() => {
    setWebhookURL(workflowData?.onEnterPushWebhookURL ?? "");
  }, [workflowData]);

  const handleConfigChange = useCallback(
    async (field, value) => {
      if (!workflow?.stageId) return;

      await CrudService.update("VacancyStage", workflow.stageId, {
        [field]: value,
      }).then(() => reloadData());
    },
    [reloadData, workflow]
  );

  if (workflow === "general")
    return <GeneralWorkflowConfigurator VacancyId={VacancyId} />;
  if (!workflowData) return <Skeleton active />;
  if (!stages) return <Skeleton active />;

  return (
    <>
      <h2 className="font-bold text-lg mb-8">
        {t("Workflow Configuration for")}{" "}
        <span className="text-indigo-500">{workflowData?.name}</span>
      </h2>
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t("When lead enters stage {{stageName}}, send them", {
            stageName: workflowData?.name,
          })}{" "}
          <span className="text-indigo-500">{t("an automated email")}</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterSendEmail}
          onChange={(value) => handleConfigChange("onEnterSendEmail", value)}
        />
      </div>

      <Space>
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            if (loading) return;
            setActivatedMessagingTemplate("SendEmail");
          }}
        >
          {t("Change Email Template")}
        </button>
      </Space>

      <Divider />

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t("When lead enters stage {{stageName}}, send them", {
            stageName: workflowData?.name,
          })}{" "}
          <span className="text-indigo-500">{t("an automated SMS")}</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterSendSMS}
          onChange={(value) => handleConfigChange("onEnterSendSMS", value)}
        />
      </div>

      <Space>
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            if (loading) return;
            setSMSModal("onEnterSendSMSBody");
          }}
        >
          {t("Change SMS Template")}
        </button>
      </Space>

      <Divider />

      <PhoneAutomation
        workflowData={workflowData}
        workflow={workflow}
        handleConfigChange={handleConfigChange}
      />

      <Divider />

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t(
            "When lead enters stage {{stageName}}, push lead data into an external webhook",
            { stageName: workflowData?.name }
          )}
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterPushWebhook}
          onChange={(value) => handleConfigChange("onEnterPushWebhook", value)}
        />
      </div>

      <h2 className="text-md font-semibold">{t("Webhook URL")}</h2>
      <Search
        enterButton={<IoMdSave />}
        className="dark:text-gray-900"
        placeholder="https://webhook.site/..."
        value={webhookURL}
        onChange={(e) => setWebhookURL(e.target.value)}
        onSearch={async (e) => {
          handleConfigChange("onEnterPushWebhookURL", webhookURL).then(() =>
            message.success(t("Webhook URL successfully updated"))
          );
        }}
      />

      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t("When lead enters stage {{stageName}}, mark them as", {
            stageName: workflowData?.name,
          })}{" "}
          <span className="text-indigo-500">{t("engaged")}</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsEngaged}
          onChange={(value) =>
            handleConfigChange("onEnterMarkAsEngaged", value)
          }
        />
      </div>
      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t("When lead enters stage {{stageName}}, mark them as", {
            stageName: workflowData?.name,
          })}{" "}
          <span className="text-indigo-500">{t("nurtured")}</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsQualified}
          onChange={(value) =>
            handleConfigChange("onEnterMarkAsQualified", value)
          }
        />
      </div>
      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t("When lead enters stage {{stageName}}, mark them as", {
            stageName: workflowData?.name,
          })}{" "}
          <span className="text-indigo-500">{t("not nurtured")}</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsNotQualified}
          onChange={(value) =>
            handleConfigChange("onEnterMarkAsNotQualified", value)
          }
        />
      </div>

      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t("When lead enters stage {{stageName}}, mark them as", {
            stageName: workflowData?.name,
          })}{" "}
          <span className="text-indigo-500">
            {t("scheduled for sales call")}
          </span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsScheduledForMeeting}
          onChange={(value) =>
            handleConfigChange("onEnterMarkAsScheduledForMeeting", value)
          }
        />
      </div>
      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t("When lead enters stage {{stageName}}, mark them as", {
            stageName: workflowData?.name,
          })}{" "}
          <span className="text-indigo-500">
            {t("not scheduled for sales call")}
          </span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsNotScheduledForMeeting}
          onChange={(value) =>
            handleConfigChange("onEnterMarkAsNotScheduledForMeeting", value)
          }
        />
      </div>

      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t("When lead enters stage {{stageName}}, mark them as", {
            stageName: workflowData?.name,
          })}{" "}
          <span className="text-indigo-500">{t("converted")}</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsHired}
          onChange={(value) => handleConfigChange("onEnterMarkAsHired", value)}
        />
      </div>
      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          {t("When lead enters stage {{stageName}}, mark them as", {
            stageName: workflowData?.name,
          })}{" "}
          <span className="text-indigo-500">{t("lost")}</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsNotHired}
          onChange={(value) =>
            handleConfigChange("onEnterMarkAsNotHired", value)
          }
        />
      </div>

      <Divider />
      <div className="mb-5">
        <div className=" flex gap-3 items-center">
          <label className="font-bold">
            {t(
              "When leads stay too long in stage {{stageName}}, move them into",
              { stageName: workflowData?.name }
            )}{" "}
            <span className="text-indigo-500">
              {stages?.find?.((s) => s._id === workflowData?.onIdleAssignStage)
                ?.name ?? t("None")}{" "}
            </span>
          </label>
          <Select
            style={{ width: 200 }}
            loading={loading}
            value={workflowData?.onIdleAssignStage || null}
            onChange={(value) => handleConfigChange("onIdleAssignStage", value)}
          >
            <Select.Option key={1} value={null}>
              {t("None")}
            </Select.Option>
            {stages.map((stage) => (
              <Select.Option key={stage._id} value={stage._id}>
                {stage.name}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="flex w-full justify-end items-center gap-1">
          {workflowData?.onIdleAssignStage && (
            <>
              <div>
                <div>{t("Days")}</div>
                <InputNumber
                  min={0}
                  value={days}
                  onChange={(value) => setDays(value ?? 0)}
                  placeholder={t("Days")}
                  style={{ width: "70px" }}
                />
              </div>
              <div>
                <div>{t("Hours")}</div>
                <InputNumber
                  min={0}
                  value={hours}
                  onChange={(value) => setHours(value ?? 0)}
                  placeholder={t("Hours")}
                  style={{ width: "70px" }}
                />
              </div>
              <div>
                <div>{t("Minutes")}</div>
                <InputNumber
                  min={0}
                  value={minutes}
                  onChange={(value) => setMinutes(value ?? 0)}
                  placeholder={t("Minutes")}
                  style={{ width: "70px" }}
                />
              </div>
              <Tooltip
                title={t(
                  "Candidates will be moved after {{time}} of idle time waiting in this stage.",
                  {
                    time: `${days > 0 ? t("{{days}} day(s)", { days }) : ""}${
                      hours > 0 ? t("{{hours}} hour(s)", { hours }) : ""
                    }${
                      minutes > 0 ? t("{{minutes}} minute(s)", { minutes }) : ""
                    }`,
                  }
                )}
              >
                <GrInfo />
              </Tooltip>
            </>
          )}
        </div>
      </div>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!activatedMessagingTemplate}
        onCancel={() => setActivatedMessagingTemplate(null)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <VariableMessageBox
          workflowData={workflowData}
          defaultSubject={
            workflowData?.[`${activatedMessagingTemplate}Subject`]
          }
          defaultBody={workflowData?.[`${activatedMessagingTemplate}Body`]}
          onSend={async (subject, body) => {
            setActivatedMessagingTemplate(null);
            if (workflow?.stageId)
              await CrudService.update("VacancyStage", workflow.stageId, {
                [`${activatedMessagingTemplate}Subject`]: subject,
                [`${activatedMessagingTemplate}Body`]: body,
              }).then(() => reloadData());
          }}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!smsModal}
        onCancel={() => setSMSModal(null)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <VariableSMSBox
          workflowData={workflowData}
          defaultBody={workflowData?.[`${smsModal}`]}
          onSend={async (body) => {
            setSMSModal(null);
            if (workflow?.stageId)
              await CrudService.update("VacancyStage", workflow.stageId, {
                [`${smsModal}`]: body,
              }).then(() => reloadData());
          }}
          disableTemplate={smsModal === "onEnterCallScript"}
        />
      </Modal>
    </>
  );
};

export default WorkflowConfigurator;
