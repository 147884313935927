import { Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { FaGithub } from "react-icons/fa6";
import { SiGofundme, SiPatreon } from "react-icons/si";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getPartner, selectUser } from "../../redux/auth/selectors";
import { Container } from "./Container";
import { Logo } from "./Logo";
import { NavLink } from "./NavLink";

export function Footer({ data }) {
  const { t } = useTranslation();
  const partner = data || useSelector(getPartner);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const socialMediaMap = {
    facebook: <FaFacebook size={25} />,
    twitter: <FaTwitter size={25} />,
    linkedin: <FaLinkedin size={25} />,
    instagram: <FaInstagram size={25} />,
    youtube: <FaYoutube size={25} />,
    pinterest: <FaPinterest size={25} />,
    tiktok: <FaTiktok size={25} />,
    github: <FaGithub size={25} />,
    gofundme: <SiGofundme size={25} />,
    patreon: <SiPatreon size={25} />,
  };

  return (
    <footer className="bg-slate-50">
      <Container>
        <div className="py-16">
          <Link to="/" aria-label={t("Home")}>
            <Logo className="mx-auto h-10 w-auto" />
          </Link>
          <nav className="mt-10 text-sm" aria-label={t("quick links")}>
            <div className="-my-1 flex flex-col justify-start gap-2">
              {(partner?.termlyPrivacyDataID || partner?.PrivacyText) && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(`${user ? "/dashboard" : ""}/legal/privacy`);
                  }}
                >
                  {t("Privacy")}
                </div>
              )}
              {(partner?.termlyTermsDataID || partner?.TermsText) && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(`${user ? "/dashboard" : ""}/legal/terms`);
                  }}
                >
                  {t("Terms")}
                </div>
              )}
              {(partner?.termlyAcceptableUseDataID ||
                partner?.AcceptableUseText) && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(`${user ? "/dashboard" : ""}/legal/acceptableUse`);
                  }}
                >
                  {t("Acceptable Use")}
                </div>
              )}
              {(partner?.termlyCookieDataID || partner?.CookieText) && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(`${user ? "/dashboard" : ""}/legal/cookie`);
                  }}
                >
                  {t("Cookies")}
                </div>
              )}
              {(partner?.termlyDisclaimerDataID || partner?.DisclaimerText) && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(`${user ? "/dashboard" : ""}/legal/disclaimer`);
                  }}
                >
                  {t("Disclaimer")}
                </div>
              )}
              {partner?.termlyConsentBannerID && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    window.displayPreferenceModal();
                    return false;
                  }}
                  id="termly-consent-preferences"
                >
                  {t("Consent Preferences")}
                </div>
              )}
              {partner?.termlyDSARForm && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    window.open(
                      `https://app.termly.io/notify/${partner?.termlyDSARForm}`
                    );
                  }}
                >
                  {t("DSAR")}
                </div>
              )}
              {partner?.termlyDSARForm && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    window.open(
                      `https://app.termly.io/notify/${partner?.termlyDSARForm}`
                    );
                  }}
                >
                  {t("Do Not Sell or Share My Personal information")}
                </div>
              )}
              {partner?.termlyDSARForm && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    window.open(
                      `https://app.termly.io/notify/${partner?.termlyDSARForm}`
                    );
                  }}
                >
                  {t("Limit the Use Of My Sensitive Personal Information")}
                </div>
              )}
            </div>
          </nav>
        </div>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <div className="flex gap-x-6">
            {partner?.socialMedia
              ?.filter?.((media) => !!socialMediaMap?.[media.platform])
              ?.map?.((media) => (
                <Link
                  key={media.platform}
                  to={media.link}
                  target="_blank"
                  className="group dark:text-black"
                  aria-label={t(media.platform)}
                >
                  {socialMediaMap[media.platform]}
                </Link>
              ))}
          </div>
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            {t("Copyright © {{year}} {{brandName}}. All rights reserved.", {
              year: new Date().getFullYear(),
              brandName: partner?.brandName,
            })}
          </p>
        </div>
        <div>
          <p>{t("AAWAI Corporation")}</p>
          <p>{t("5-15-10-902, Yoyogi, Shibuya, Tokyo, Japan")}</p>
        </div>
      </Container>
    </footer>
  );
}
