import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPartner } from "../../redux/auth/selectors";

export function Footer() {
  const { t } = useTranslation();
  const partner = useSelector(getPartner);
  const navigate = useNavigate();

  return (
    <footer>
      <Divider />
      <nav className="mt-10 text-sm" aria-label="quick links">
        <div className="-my-1 flex justify-start gap-x-4">
          {(partner?.termlyPrivacyDataID || partner?.PrivacyText) && (
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/dashboard/legal/privacy");
              }}
            >
              {t("Privacy")}
            </div>
          )}
          {(partner?.termlyTermsDataID || partner?.TermsText) && (
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/dashboard/legal/terms");
              }}
            >
              {t("Terms")}
            </div>
          )}
          {(partner?.termlyAcceptableUseDataID ||
            partner?.AcceptableUseText) && (
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/dashboard/legal/acceptableUse");
              }}
            >
              {t("Acceptable Use")}
            </div>
          )}
          {(partner?.termlyCookieDataID || partner?.CookieText) && (
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/dashboard/legal/cookie");
              }}
            >
              {t("Cookies")}
            </div>
          )}
          {(partner?.termlyDisclaimerDataID || partner?.DisclaimerText) && (
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/dashboard/legal/disclaimer");
              }}
            >
              {t("Disclaimer")}
            </div>
          )}
        </div>
      </nav>
    </footer>
  );
}
