import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Alert,
  Button,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  message,
} from "antd";
import { GrInfo } from "react-icons/gr";
import { Mention, MentionsInput } from "react-mentions";
import ATSService from "../../../service/ATSService";
import CrudService from "../../../service/CrudService";
import classNamesBody from "./body.module.css";

const CandidateRejectBox = ({ candidateId, onSend }) => {
  const { t } = useTranslation();
  const [body, setBody] = useState(
    localStorage["lastRejectMessage_message"] ?? ""
  );
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [candidateData, setCandidateData] = useState(null);

  useEffect(() => {
    if (!candidateId) return;
    CrudService.getSingle("VacancySubmission", candidateId).then(({ data }) => {
      if (data) setCandidateData(data);
    });
  }, [candidateId]);

  const handleReset = () => {
    setBody("");
    onSend();
  };

  const reloadTemplates = useCallback(async () => {
    await CrudService.search("RejectReasonTemplate", 10000000, 1, {
      sort: { createdAt: 1 },
    }).then(({ data }) => {
      setTemplates(data.items);
    });
  }, []);

  useEffect(() => {
    reloadTemplates();
  }, [reloadTemplates]);

  useEffect(() => {
    const selected = templates.find((te) => te._id === selectedTemplate);
    if (selected) {
      setBody(selected.message);
    }
  }, [selectedTemplate, templates]);

  if (!candidateData) return <Skeleton active />;
  return (
    <>
      <label className="font-bold">{t("Template")}</label>
      <div className="w-full flex items-center">
        <Select
          className="grow"
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e)}
          showSearch
          filterOption={(input, option) => {
            const label = option.label
              .replace(/\@\[/g, "")
              .replace(/\]$$(.)*$$/g, "")
              .toLowerCase();
            return label.includes(input.toLowerCase());
          }}
        >
          {templates.map((te) => (
            <Select.Option
              key={te._id}
              value={te._id}
              label={
                te.message
                  .replace(/\@\[/g, "")
                  .replace(/\]$$(.)*$$/g, "")
                  .slice(0, 40) || "-"
              }
            >
              <Space className="flex justify-between">
                <div>
                  {te.message
                    .replace(/\@\[/g, "")
                    .replace(/\]$$(.)*$$/g, "")
                    .slice(0, 40) || "-"}
                </div>
                <div>
                  <Popconfirm
                    title={t("Sure to delete?")}
                    onConfirm={async () => {
                      setLoading(true);
                      try {
                        await CrudService.delete(
                          "RejectReasonTemplate",
                          te._id
                        );
                        await reloadTemplates();
                        setSelectedTemplate(null);
                      } catch (e) {
                      } finally {
                        setLoading(false);
                      }
                    }}
                  >
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      size="small"
                      danger
                      disabled={loading}
                    >
                      {t("Delete")}
                    </Button>
                  </Popconfirm>
                </div>
              </Space>
            </Select.Option>
          ))}
        </Select>
        <button
          onClick={async () => {
            if (!body)
              return message.error(
                t(
                  "Empty rejection reason cannot need to be stored as a template"
                )
              );

            setLoading(true);
            try {
              const current = await CrudService.create("RejectReasonTemplate", {
                message: body,
              });
              await reloadTemplates();
              if (current?.data?.result?._id)
                setSelectedTemplate(current?.data?.result?._id);
            } catch (e) {
            } finally {
              setLoading(false);
            }
          }}
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          disabled={loading}
        >
          {!loading ? t("Save Current") : <Spin>{t("Save Current")}</Spin>}
        </button>
      </div>

      <br />
      <label className="font-bold">{t("Lead")}</label>
      {candidateData?.formData && (
        <div>
          {candidateData?.formData?.firstname}{" "}
          {candidateData?.formData?.lastname}{" "}
          <a
            href={`mailto:${candidateData?.formData?.email}`}
          >{`<${candidateData?.formData?.email}>`}</a>
        </div>
      )}

      <br />

      <div className="flex items-center justify-between w-full">
        <label className="font-bold">{t("Rejection Reason")}</label>
        <Tooltip
          title={t(
            "Provide a reason on why the lead was not a great fit. This text can be used in your email automations as a variable."
          )}
        >
          <GrInfo />
        </Tooltip>
      </div>

      <MentionsInput
        placeholder={t("Type # to browse variables")}
        value={body}
        onChange={(_, value) => setBody(value)}
        classNames={classNamesBody}
        a11ySuggestionsListLabel={t("Possible variables")}
      >
        <Mention
          trigger="#"
          className={"bg-indigo-100"}
          data={[
            { id: "candidateFirstname", display: t("Lead's Firstname") },
            { id: "candidateLastname", display: t("Lead's Lastname") },
            { id: "candidateEmail", display: t("Lead's Email") },
            { id: "candidatePhone", display: t("Lead's Phone") },
            { id: "companyName", display: t("Company Name") },
            { id: "companyWebsite", display: t("Company Website") },
            { id: "companyAddress", display: t("Company Address") },
            { id: "userCalendarLink", display: t("User Calendar Link") },
            { id: "currentDate", display: t("Current Date") },
            { id: "currentTime", display: t("Current Time") },
            { id: "userFirstname", display: t("User Firstname") },
            { id: "userLastname", display: t("User Lastname") },
            { id: "userEmail", display: t("User Email") },
            { id: "userPhone", display: t("User Phone") },
          ]}
        />
      </MentionsInput>

      {!localStorage?.informationMessageVariables001 && (
        <Alert
          type="info"
          message={t(
            "To explore available messaging variables, simply type '#' followed by your keyword, and a list of suggestions will appear for your convenience."
          )}
          closable
          onClose={() => (localStorage.informationMessageVariables001 = "true")}
        />
      )}
      <br />

      {!localStorage?.informationCandidateRejection001 && (
        <Alert
          type="info"
          message={t(
            "The rejection reason entered will be saved as a lead variable. No automated communication will occur without a custom workflow setup by you. For instance, a workflow could move leads to a 'unqualified' stage and trigger an email with the stored rejection reason. This process is entirely optional and customizable."
          )}
          closable
          onClose={() =>
            (localStorage.informationCandidateRejection001 = "true")
          }
        />
      )}

      <button
        onClick={async () => {
          setLoading(true);
          try {
            await ATSService.rejectCandidate({
              candidateId,
              rejectionReason: body,
              rejected: true,
            });

            handleReset();
            localStorage["lastRejectMessage_message"] = body;
          } catch (e) {
          } finally {
            setLoading(false);
          }
        }}
        className="px-2 py-1 text-sm bg-indigo-500 text-white rounded mt-5"
        disabled={loading}
      >
        {!loading ? t("Save") : <Spin>{t("Save")}</Spin>}
      </button>
    </>
  );
};

export default CandidateRejectBox;
