import Cookies from "js-cookie";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../redux/auth/actions";
import { store } from "../../redux/store";
import AuthService from "../../service/AuthService";

import { useSelector } from "react-redux";
import { getPartner, selectLoading } from "../../redux/auth/selectors";
import { Button } from "../Landing/Button";
import { TextField } from "../Landing/Fields";
import { Logo } from "../Landing/Logo";
import { SlimLayout } from "../Landing/SlimLayout";

const Login = () => {
  const partner = useSelector(getPartner);
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await AuthService.requestPasswordReset({ email: e.target[0].value });
    localStorage.forgotEmail = e.target[0].value;
    navigate("/auth/passwordreset");
  };

  return (
    <div className="flex h-[100vh] flex-col">
      <SlimLayout>
        <div className="flex">
          <Link href="/" aria-label={t("Home")}>
            <Logo className="h-10 w-auto" />
          </Link>
        </div>
        <h2 className="mt-20 text-lg font-semibold text-gray-900 dark:text-gray-400 ">
          {t("Password reset")}
        </h2>
        <p className="mt-2 text-sm text-gray-700 dark:text-gray-300 ">
          {t("Forgot your password? Type your email, and we will send an OTP.")}
        </p>
        <form
          action="#"
          className="mt-10 grid grid-cols-1 gap-y-8"
          onSubmit={handleSubmit}
        >
          <TextField
            label={t("Email address")}
            name="email"
            type="email"
            autoComplete="email"
            required
          />

          <div>
            <Button
              type="submit"
              variant="solid"
              color="blue"
              className="w-full"
              loading={loading}
            >
              <span>
                {t("Request OTP")} <span aria-hidden="true">&rarr;</span>
              </span>
            </Button>
          </div>
          <div className="text-center text-sm">
            <Link to="/auth/login" className="text-indigo-500 font-semibold">
              {t("Back to login")}
            </Link>
          </div>
        </form>
      </SlimLayout>
    </div>
  );
};

export default Login;
