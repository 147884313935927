import {
  Button,
  Divider,
  Input,
  List,
  Popconfirm,
  Skeleton,
  Space,
  Upload,
  message,
} from "antd";
import { fabric } from "fabric";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactPhotoEditor } from "react-photo-editor";
import "react-photo-editor/dist/style.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { countries } from "../../../data/constants";
import { login } from "../../../redux/auth/actions";
import { selectLoading, selectUser } from "../../../redux/auth/selectors";
import { store } from "../../../redux/store";
import AuthService from "../../../service/AuthService";
import CrudService from "../../../service/CrudService";
import GoogleService from "../../../service/GoogleService";
import PublicService from "../../../service/PublicService";
import UploadService from "../../../service/UploadService";
import UserService from "../../../service/UserService";

const sanitizeKey = (key) => key.replace(/\//g, "__").replace(/\./g, "_dot_");

const getContrastColor = (hex) => {
  // Convert hex to RGB
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  // Calculate brightness (standard formula)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  // Return black or white based on brightness
  return brightness > 125 ? "#000000" : "#ffffff";
};

// Custom debounce function
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const ImageEditorComponent = ({
  image,
  background = "#3498db",
  handleSave,
  defaultValue,
  context,
}) => {
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [text, setText] = useState("");
  const [selectedObject, setSelectedObject] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(background);
  const [textSize, setTextSize] = useState(21);
  const [customTextColor, setCustomTextColor] = useState(
    getContrastColor(background)
  );
  const [backgroundSize, setBackgroundSize] = useState({
    width: 160,
    height: 60,
  });
  const textColor = getContrastColor(background);

  useEffect(() => {
    const canvasInstance = new fabric.Canvas(canvasRef.current, {
      height: 400,
      width: 600,
    });
    setCanvas(canvasInstance);

    if (defaultValue) {
      // Load from JSON if defaultValue is provided
      try {
        const parsed = JSON.parse(defaultValue);
        canvasInstance.loadFromJSON(
          parsed,
          canvasInstance.renderAll.bind(canvasInstance)
        );
      } catch (e) {
        fabric.Image.fromURL(image, (img) => {
          img.scaleToWidth(600);
          canvasInstance.setBackgroundImage(
            img,
            canvasInstance.renderAll.bind(canvasInstance),
            {
              crossOrigin: "anonymous",
            }
          );
        });
      }
    } else {
      // Otherwise, load the background image
      fabric.Image.fromURL(image, (img) => {
        img.scaleToWidth(600);
        canvasInstance.setBackgroundImage(
          img,
          canvasInstance.renderAll.bind(canvasInstance),
          {
            crossOrigin: "anonymous",
          }
        );
      });
    }

    const debouncedSave = debounce(() => {
      if (canvasInstance) {
        const jsonData = JSON.stringify(canvasInstance.toJSON());
        handleSave(image, jsonData); // Save the canvas JSON
      }
    }, 1000);

    canvasInstance.on("object:modified", debouncedSave);
    canvasInstance.on("object:added", debouncedSave);

    canvasInstance.on("selection:created", ({ selected }) => {
      setSelectedObject(selected);
    });
    canvasInstance.on("selection:cleared", () => {
      setSelectedObject(null);
    });

    return () => {
      canvasInstance.dispose();
    };
  }, [image]);

  // Add text with rounded background to canvas
  const addText = (tx) => {
    const myText = tx ?? text;
    if (myText.trim()) {
      const padding = 10;

      const textObj = new fabric.Textbox(myText, {
        fontSize: textSize,
        fontFamily: "Arial",
        fill: customTextColor,
        textAlign: "center",
        originX: "center",
        originY: "center",
      });

      const backgroundRect = new fabric.Rect({
        width: backgroundSize.width,
        height: backgroundSize.height,
        fill: backgroundColor,
        rx: 10,
        ry: 10,
        originX: "center",
        originY: "center",
      });

      const group = new fabric.Group([backgroundRect, textObj], {
        left: 100,
        top: 50,
      });

      canvas.add(group);
      setText("");
    } else {
      message.warning(t("Please enter some text to add."));
    }
  };

  // Remove selected caption
  const removeSelectedObject = useCallback(() => {
    if (selectedObject) {
      for (const obj of selectedObject) {
        canvas.remove(obj);
      }
      canvas.discardActiveObject();
      canvas.renderAll();
      setSelectedObject(null);
    } else {
      message.warning(t("No caption selected to delete."));
    }
  }, [canvas, selectedObject, t]);

  return (
    <div className="">
      <div className="toolbar">
        <Space direction="vertical" className="w-full">
          <Space>
            <Input
              value={text}
              placeholder={t("Enter caption text")}
              onChange={(e) => setText(e.target.value)}
            />
            <Button onClick={() => addText()}>{t("Add Text")}</Button>
            <Button
              onClick={async () => {
                const res = await PublicService.aiPrompt({
                  content: `context: ${context}
                  
                  Please generate a short ad banner headline for my context. The language should be same as the language used in the context. The headline should not be more than 2 words. The headline should make sense and be good marketing content. Your response to me should not have anything else than the generated headline.
                  `,
                });

                addText(res.data?.output);
              }}
            >
              {t("Auto Text")}
            </Button>
            {selectedObject && (
              <Button danger onClick={removeSelectedObject}>
                {t("Delete Selection")}
              </Button>
            )}
          </Space>

          <Space>
            <Input
              type="color"
              value={backgroundColor}
              onChange={(e) => setBackgroundColor(e.target.value)}
              style={{ width: 100 }}
            />
            <span>Background Color</span>

            <Input
              type="color"
              value={customTextColor}
              onChange={(e) => setCustomTextColor(e.target.value)}
              style={{ width: 100 }}
            />
            <span>Text Color</span>

            <Input
              type="number"
              value={textSize}
              onChange={(e) => setTextSize(Number(e.target.value))}
              style={{ width: 80 }}
            />
            <span>Text Size</span>

            <Input
              type="number"
              value={backgroundSize.width}
              onChange={(e) =>
                setBackgroundSize((prev) => ({
                  ...prev,
                  width: Number(e.target.value),
                }))
              }
              style={{ width: 80 }}
            />
            <span>Width</span>

            <Input
              type="number"
              value={backgroundSize.height}
              onChange={(e) =>
                setBackgroundSize((prev) => ({
                  ...prev,
                  height: Number(e.target.value),
                }))
              }
              style={{ width: 80 }}
            />
            <span>Height</span>
          </Space>
        </Space>
      </div>

      <div
        className="canvas-container w-min"
        style={{ border: "1px solid #ccc", marginTop: "16px" }}
      >
        <canvas ref={canvasRef} />
      </div>
    </div>
  );
};

const CreateAdCreatives = () => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(null);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [generatedImageData, setGeneratedImageData] = useState({});
  const [funnel, setFunnel] = useState(null);
  const location = useLocation();
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();
  const canvasRef = useRef(null);

  useEffect(() => {
    const getData = (pref_id) => {
      const id = pref_id ?? searchParams.get("id");
      if (id) {
        setFormData(null);

        CrudService.getSingle("AdCampaign", id).then(async (res) => {
          if (res.data) {
            setFormData(res.data);
            const funnel = await CrudService.getSingle(
              "Vacancy",
              res.data.vacancy
            );
            setFunnel(funnel);
          }

          setGeneratedImages(res.data?.adImages ?? []);
          setGeneratedImageData(res.data?.adImageData ?? {});
        });
      }
    };

    getData();
  }, [location, user]);

  const handleSave = async (imageUrl, jsonData) => {
    const id = searchParams.get("id");

    const sanitizedUrl = sanitizeKey(imageUrl);

    // Save to server
    await CrudService.update("AdCampaign", id, {
      [`adImageData.${sanitizedUrl}`]: jsonData,
    });

    // Update local state
    setGeneratedImageData((x) => ({
      ...x,
      [sanitizedUrl]: jsonData,
    }));
  };

  const handleFinish = useCallback(async () => {
    if (loading) return;

    const id = searchParams.get("id");
    const res = await CrudService.getSingle("AdCampaign", id);
    const data = res.data;

    let finalAdImages = [];

    // Usage Example in Fabric.js Canvas Processing

    const customImageLoader = async (element, callback) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; // Set CORS

      img.onload = () => {
        callback(new fabric.Image(img)); // Create Fabric image from loaded image
      };

      img.onerror = (err) => {
        console.error("Image loading failed:", err);
        callback(null); // Handle loading failure
      };

      // Fetch the image as a Blob
      try {
        const response = await fetch(element.src);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob); // Create a Blob URL
        img.src = url; // Set the image source to the Blob URL
      } catch (error) {
        console.error("Error fetching image:", error);
        callback(null); // If fetching fails, call callback with null
      }
    };

    const replaceImageInSVG = async (svgString) => {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgString, "image/svg+xml");

      const images = svgDoc.getElementsByTagName("image");
      const promises = [];

      for (const image of images) {
        const src = image.getAttribute("xlink:href");
        promises.push(
          fetch(src)
            .then((response) => response.blob())
            .then((blob) => {
              const reader = new FileReader();
              return new Promise((resolve) => {
                reader.onloadend = () => {
                  image.setAttribute("xlink:href", reader.result); // Set base64 image
                  resolve();
                };
                reader.readAsDataURL(blob); // Convert blob to base64
              });
            })
        );
      }

      await Promise.all(promises); // Wait for all images to load
      return new XMLSerializer().serializeToString(svgDoc); // Convert SVG DOM back to string
    };

    for (const imageUrl of data.adImages) {
      const sanitizedUrl = sanitizeKey(imageUrl);
      const canvasData = data.adImageData?.[sanitizedUrl];

      if (canvasData) {
        const fabricCanvas = new fabric.Canvas(null, {
          width: 600,
          height: 400,
        });

        // Usage in loadFromJSON
        await new Promise((resolve) => {
          const parsed = JSON.parse(canvasData);
          fabricCanvas.loadFromJSON(parsed, () => {
            fabricCanvas.renderAll();
            resolve();
          }); // Use the custom loader
        });

        // Now, you can convert to data URL

        await new Promise(async (resolve) => {
          const svgString = fabricCanvas.toSVG();
          const modifiedSvgString = await replaceImageInSVG(svgString);

          // Step 1: Create an off-screen canvas
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set canvas dimensions based on the SVG
          const svgBlob = new Blob([modifiedSvgString], {
            type: "image/svg+xml;charset=utf-8",
          });
          const url = URL.createObjectURL(svgBlob);

          // Create an Image object to render the SVG
          const img = new Image();
          img.onload = async () => {
            // Step 2: Set canvas dimensions to the SVG size
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the SVG onto the canvas
            ctx.drawImage(img, 0, 0);

            // Step 3: Convert the canvas to a PNG data URL
            const pngDataUrl = canvas.toDataURL("image/png");

            // Convert the PNG data URL to a Blob
            const pngBlob = await (await fetch(pngDataUrl)).blob();

            // Step 4: Create a File object from the Blob for uploading
            const file = new File(
              [pngBlob],
              `static_ad_image_${Date.now()}.png`,
              {
                type: "image/png",
              }
            );

            // Upload to Cloudinary
            const uploadResult = await UploadService.upload(file);
            finalAdImages.push(uploadResult.data.secure_url);

            // Cleanup
            URL.revokeObjectURL(url); // Free up the URL object
            fabricCanvas.dispose();
            resolve();
          };

          img.src = url; // Set the source to the Blob URL
        });
      } else {
        finalAdImages.push(imageUrl);
      }
    }

    console.log(finalAdImages);

    try {
      await UserService.extendTokenTime();
    } catch (e) {
      await AuthService.updateMe({ silent: true, metaAccessToken: "" });

      const me = await AuthService.me();
      store.dispatch(login(me.data.me));
      navigate("/dashboard/createAd?defaultStep=2");
    }

    const funnel = await CrudService.getSingle("Vacancy", data.vacancy);
    if (funnel.data?.length < 1)
      return message.error(t("At least 1 image is required"));

    if (user?.metaAccessToken) {
      await UserService.postAd({
        adImages: finalAdImages,
        adBudget: data?.monthlyBudget / 30,
        promote: funnel.data,
        adHeadline: data?.adHeadline,
        adDescription: data?.adDescription,
        adPrimaryText: data?.adPrimaryText,
      }).then(async (res) => {
        await CrudService.update("AdCampaign", data._id, {
          campaign: res.data?.campaign?.id,
        });
      });
    }

    if (user?.connectGoogle) {
      await GoogleService.createAd({
        days: 30,
        adDescription: [data?.adDescription],
        adHeadline: [data?.adHeadline],
        vacancyLocation:
          countries.find((c) => c.value === user.country)?.label ??
          "United States",
        path1: "thesalesai",
        path2: "page",
        final_urls: [`https://thesalesai.ai/page/${funnel?.data?._id}`],
        budget: data?.monthlyBudget / 30,
      }).then(async (res) => {
        await CrudService.update("AdCampaign", data._id, {
          campaignGoogle: res.data?.myCampaignId,
          googleCustomerId: res.data?.customerId,
        });
      });
    }

    await CrudService.update("AdCampaign", data._id, {
      enabled: true,
    });

    navigate("/dashboard/adautomation");
  }, [loading, navigate, searchParams, user, canvasRef, t]);

  const generateImage = async () => {
    const id = searchParams.get("id");
    const res = await CrudService.getSingle("AdCampaign", id);
    const data = res.data;
    if (data.adImages.length > 4)
      return message.error(t("Maximum 5 creatives are allowed"));
    const funnel = await CrudService.getSingle("Vacancy", data.vacancy);

    const response = await PublicService.replicateGen({
      input: {
        prompt: `${JSON.stringify(funnel.data.pageContent).slice(0, 1000)}

For this landing page, we need a picture that describes that serves as an ad creative. Can you generate it?

The generated ad creative must not have any text or letters
`,
      },
      id: "bytedance/sdxl-lightning-4step:5f24084160c9089501c1b3545d9be3c27883ae2239b6f412990e82d4a6210f8f",
    });

    const generatedImage = response?.data?.output?.[0];

    if (generatedImage) {
      // Fetch the image from the replicate URL
      const imageResponse = await fetch(generatedImage);
      const blob = await imageResponse.blob();

      // Create a temporary file to upload to Cloudinary
      const file = new File([blob], `replicate_image_${Date.now()}.png`, {
        type: blob.type,
      });

      // Upload the image to Cloudinary
      const result = await UploadService.upload(file);
      // Return the Cloudinary secure URL
      // result?.data?.secure_url

      setGeneratedImages([...generatedImages, result?.data?.secure_url]);
      await addImageToCampaignImages(result?.data?.secure_url);
    }
  };

  const handleFileUpload = useCallback(
    async ({ file }) => {
      if (generatedImages.length >= 5)
        return message.error(t("Maximum 5 creatives are allowed"));

      try {
        const result = await UploadService.upload(file);
        const imageUrl = result.data.secure_url;
        setGeneratedImages([...generatedImages, imageUrl]);
        await addImageToCampaignImages(imageUrl);
      } catch (error) {
        message.error(t("Failed to upload image"));
      }
    },
    [generatedImages, t]
  );

  const addImageToCampaignImages = async (imageUrl) => {
    const id = searchParams.get("id");
    setFormData((formData) => ({
      ...formData,
      adImages: [...(formData?.adImages || []), imageUrl],
    }));

    const res = await CrudService.getSingle("AdCampaign", id);
    await CrudService.update("AdCampaign", id, {
      adImages: [...(res.data?.adImages || []), imageUrl],
    });
  };

  const deleteImage = async (imageUrl) => {
    const id = searchParams.get("id");

    // Fetch the latest data from the server
    const res = await CrudService.getSingle("AdCampaign", id);

    // Remove the image URL and the corresponding adImageData entry
    const updatedAdImages = res.data?.adImages.filter(
      (image) => image !== imageUrl
    );

    const updatedAdImageData = { ...res.data?.adImageData };
    delete updatedAdImageData[sanitizeKey(imageUrl)];

    // Update local state
    setFormData((prevFormData) => ({
      ...prevFormData,
      adImages: updatedAdImages,
      adImageData: updatedAdImageData,
    }));

    // Update the server
    await CrudService.update("AdCampaign", id, {
      adImages: updatedAdImages,
      adImageData: updatedAdImageData,
    });

    // Update the UI image list
    setGeneratedImages(updatedAdImages);
    setGeneratedImageData(updatedAdImageData);
  };

  if (!formData) return <Skeleton active />;

  return (
    <div className="p-4">
      <Button type="primary" onClick={generateImage} className="mb-4">
        {t("Generate New Image")}
      </Button>
      <Upload
        accept="image/*"
        showUploadList={false}
        customRequest={handleFileUpload}
      >
        <Button type="dashed" className="mb-4">
          {t("Upload Image")}
        </Button>
      </Upload>

      {generatedImages.map((image) => (
        <div key={image}>
          <ImageEditorComponent
            image={image}
            handleSave={handleSave}
            defaultValue={generatedImageData?.[sanitizeKey(image)]}
            background={user?.themeColor}
            context={JSON.stringify(funnel?.data?.pageContent)?.slice?.(
              0,
              4000
            )}
          />

          <Popconfirm
            title={t("Are you sure you want to delete this image?")}
            onConfirm={() => deleteImage(image)}
            okText={t("Yes")}
            cancelText={t("No")}
          >
            <Button danger className="mt-2">
              {t("Delete")}
            </Button>
          </Popconfirm>

          <Divider />
        </div>
      ))}
      <Popconfirm
        title={t("Are you sure you want to publish the campaign?")}
        onConfirm={handleFinish}
        okText={t("Yes")}
        cancelText={t("No")}
      >
        <Button type="primary" className="mt-4">
          {t("Publish Campaign")}
        </Button>
      </Popconfirm>

      <canvas ref={canvasRef} />
    </div>
  );
};

export default CreateAdCreatives;
