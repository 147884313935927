import {
  Card,
  Col,
  Divider,
  Progress,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdRefresh } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { currencies } from "../../data/currencies";
import { getPartner } from "../../redux/auth/selectors";
import PartnerService from "../../service/PartnerService";

const { Option } = Select;

const segmentDetails = {
  H: { segmentNum: 8, timeframeName: "hour" },
  D: { segmentNum: 7, timeframeName: "day" },
  W: { segmentNum: 4, timeframeName: "week" },
  M: { segmentNum: 6, timeframeName: "month" },
  A: { segmentNum: 5, timeframeName: "year" },
};

const PartnerStats = () => {
  const { t } = useTranslation();
  const [mainStats, setMainStats] = useState({});
  const [segmentedStats, setSegmentedStats] = useState([]);
  const [userSurveyData, setUserSurveyData] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [timeframe, setTimeframe] = useState("D");
  const partner = useSelector(getPartner);

  const isLoading = useMemo(
    () => loading1 || loading3 || loading4,
    [loading1, loading3, loading4]
  );

  const chartData = [...segmentedStats].reverse().map((segment) => ({
    name: t("{{timeframe}} {{idx}} ago", {
      timeframe: t(segmentDetails[timeframe].timeframeName, {
        count: segment.idx - 1,
      }),
      idx: segment.idx === 1 ? t("This") : segment.idx - 1,
    }),
    Clicks: segment.clicks,
    Conversions: segment.conversions,
    Applicants: segment.applicants,
    Qualified: segment.qualified,
    Meetings: segment.meetings,
    Hires: segment.hires,
    Users: segment.users,
  }));

  const loadData = useCallback(() => {
    setLoading1(true);
    setLoading3(true);
    setLoading4(true);

    PartnerService.getNumbers()
      .then(({ data }) => {
        setMainStats(data);
      })
      .finally(() => {
        setLoading1(false);
      });
    PartnerService.getSegmentedNumbers(timeframe)
      .then(({ data }) => {
        setSegmentedStats(data);
      })
      .finally(() => {
        setLoading3(false);
      });
    PartnerService.getUserStatistics()
      .then(({ data }) => {
        setUserSurveyData(data);
      })
      .finally(() => {
        setLoading4(false);
      });
  }, [timeframe]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const totalUsers = userSurveyData.totalUsers || 1;

  const calculatePercentage = (count) =>
    ((count / totalUsers) * 100).toFixed(2);

  return (
    <>
      <div>
        <h1 className="font-bold mb-4 text-lg">{t("SaaS Statistics")}</h1>

        <Row gutter={[16, 16]}>
          <Col span={24} className="mb-2">
            <Card>
              <div className="mb-2 flex justify-between">
                <h2 className="font-semibold">{t("Signup surveys")}</h2>
                {isLoading ? (
                  <Spin />
                ) : (
                  <MdRefresh
                    size={20}
                    onClick={loadData}
                    className="cursor-pointer"
                  />
                )}
              </div>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Statistic
                    title={t("Total Users")}
                    value={userSurveyData.totalUsers}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
              </Row>

              {userSurveyData?.websitePurposeCounts && (
                <>
                  <Divider />
                  <h3>{t("Website Purpose")}</h3>
                </>
              )}
              <Row gutter={[16, 16]}>
                {userSurveyData?.websitePurposeCounts &&
                  Object.entries(userSurveyData.websitePurposeCounts).map(
                    ([key, count]) => (
                      <Col span={12} key={key}>
                        <Statistic
                          title={t(key)}
                          value={t("{{count}} ({{percentage}}%)", {
                            count,
                            percentage: calculatePercentage(count),
                          })}
                          valueStyle={{ color: "#52c41a" }}
                        />
                      </Col>
                    )
                  )}
              </Row>

              {userSurveyData?.websitePurposeCounts && (
                <>
                  <Divider />
                  <h3>{t("Discovery Source")}</h3>
                </>
              )}
              <Row gutter={[16, 16]}>
                {userSurveyData?.discoverySourceCounts &&
                  Object.entries(userSurveyData.discoverySourceCounts).map(
                    ([key, count]) => (
                      <Col span={12} key={key}>
                        <Statistic
                          title={t(key)}
                          value={t("{{count}} ({{percentage}}%)", {
                            count,
                            percentage: calculatePercentage(count),
                          })}
                          valueStyle={{ color: "#faad14" }}
                        />
                      </Col>
                    )
                  )}
              </Row>

              {userSurveyData?.websitePurposeCounts && (
                <>
                  <Divider />
                  <h3>{t("Role")}</h3>
                </>
              )}
              <Row gutter={[16, 16]}>
                {userSurveyData?.roleCounts &&
                  Object.entries(userSurveyData.roleCounts).map(
                    ([key, count]) => (
                      <Col span={12} key={key}>
                        <Statistic
                          title={t(key)}
                          value={t("{{count}} ({{percentage}}%)", {
                            count,
                            percentage: calculatePercentage(count),
                          })}
                          valueStyle={{ color: "#1890ff" }}
                        />
                      </Col>
                    )
                  )}
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24} className="mb-2">
            <Card>
              <div className="mb-2 flex justify-between">
                <h2 className="font-semibold ">
                  {t("Your overall performance")}
                </h2>
                {isLoading ? (
                  <Spin />
                ) : (
                  <MdRefresh
                    size={20}
                    onClick={loadData}
                    className="cursor-pointer"
                  />
                )}
              </div>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Statistic
                    title={t("Total Users")}
                    value={mainStats.users}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title={t("Monthly Active Users")}
                    value={mainStats.monthlyActiveUsers}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title={t("Subscribed Users")}
                    value={mainStats.payingUsers}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title={t("Users Subscribed Once")}
                    value={mainStats.usersPaidOnce}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title={t("Monthly Recurring Revenue")}
                    value={mainStats.currentMRR}
                    prefix={
                      currencies.find((c) => c.iso === partner?.currency)
                        ?.symbol ?? "$"
                    }
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <div className="ant-statistic css-dev-only-do-not-override-qk40m6">
                    <div className="ant-statistic-title">
                      {t("Paid User Ratio")}
                    </div>
                    <Progress
                      type="circle"
                      size={60}
                      percent={mainStats.paidUserRatio}
                      status="active"
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="ant-statistic css-dev-only-do-not-override-qk40m6">
                    <div className="ant-statistic-title">{t("Churn Rate")}</div>
                    <Progress
                      type="circle"
                      size={60}
                      percent={mainStats.churn}
                      status="active"
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24} className="mb-2">
            <Card>
              <div className="mb-2 flex justify-between">
                <h2 className="font-semibold ">
                  {t(
                    "This is the overall performance across all recruiters on your SaaS"
                  )}
                </h2>
                {isLoading ? (
                  <Spin />
                ) : (
                  <MdRefresh
                    size={20}
                    onClick={loadData}
                    className="cursor-pointer"
                  />
                )}
              </div>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Statistic
                    title={t("Clicks")}
                    value={mainStats.clicks}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title={t("Conversions")}
                    value={mainStats.applicants}
                    valueStyle={{ color: "#52c41a" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title={t("Qualified")}
                    value={mainStats.qualified}
                    valueStyle={{ color: "#fa8c16" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title={t("Meetings")}
                    value={mainStats.meetings}
                    valueStyle={{ color: "#722ed1" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title={t("Customers")}
                    value={mainStats.hires}
                    valueStyle={{ color: "#eb2f96" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24} className="mb-2">
            <Card>
              <div className="flex justify-between mb-2">
                <h2 className="font-semibold">
                  {t(
                    "How your SaaS performed during the last {{segmentNum}} {{timeframeName}}s",
                    {
                      segmentNum: segmentDetails[timeframe].segmentNum,
                      timeframeName: t(
                        segmentDetails[timeframe].timeframeName,
                        { count: segmentDetails[timeframe].segmentNum }
                      ),
                    }
                  )}
                </h2>
                <Space>
                  <Select
                    defaultValue={timeframe}
                    style={{ width: 150 }}
                    onChange={(value) => setTimeframe(value)}
                  >
                    <Option value="H">
                      {t("Last {{count}} hours", {
                        count: segmentDetails["H"].segmentNum,
                      })}
                    </Option>
                    <Option value="D">
                      {t("Last {{count}} days", {
                        count: segmentDetails["D"].segmentNum,
                      })}
                    </Option>
                    <Option value="W">
                      {t("Last {{count}} weeks", {
                        count: segmentDetails["W"].segmentNum,
                      })}
                    </Option>
                    <Option value="M">
                      {t("Last {{count}} months", {
                        count: segmentDetails["M"].segmentNum,
                      })}
                    </Option>
                    <Option value="A">
                      {t("Last {{count}} years", {
                        count: segmentDetails["A"].segmentNum,
                      })}
                    </Option>
                  </Select>

                  {isLoading ? (
                    <Spin />
                  ) : (
                    <MdRefresh
                      size={20}
                      onClick={loadData}
                      className="cursor-pointer"
                    />
                  )}
                </Space>
              </div>
              <div className="max-w-full overflow-auto">
                <LineChart width={800} height={400} data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Users" stroke="#8884d8" />
                  <Line type="monotone" dataKey="Clicks" stroke="#8884d8" />
                  <Line
                    type="monotone"
                    dataKey="Conversions"
                    stroke="#82ca9d"
                  />
                  <Line type="monotone" dataKey="Applicants" stroke="#ff7300" />
                  <Line type="monotone" dataKey="Qualified" stroke="#ff3860" />
                  <Line type="monotone" dataKey="Meetings" stroke="#ffaa00" />
                  <Line type="monotone" dataKey="Hires" stroke="#00bfff" />
                </LineChart>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PartnerStats;
