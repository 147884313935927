import { LoadingOutlined } from "@ant-design/icons";
import { PlusIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Alert, Divider, Progress, Spin } from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/actions";
import { store } from "../../redux/store";
import AuthService from "../../service/AuthService";

const twoColors = {
  "0%": "#108ee9",
  "100%": "#87d068",
};

const randomNumber = () => Math.floor(Math.random() * 20) + 1;

export default function Onboarding({ onFinish }) {
  const [me, setMe] = useState(null);
  const [percent, setPercent] = useState(0);
  const [informationIdx, setInformationIdx] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const informations = [
    t("We are adjusting the funnels to your ideal customer profile"),
    t("We are setting up your messaging"),
    t("We are creating a phone call strategy"),
    t("We are generating your online marketing strategy"),
    t("We are tweaking your email messaging"),
    t("We are analyzing market trends to tailor your strategy"),
    t("We are crafting compelling ad copy for your campaigns"),
    t("We are refining your brand's visual identity"),
    t("We are researching keywords to boost your SEO"),
    t("We are conducting competitor analysis to identify opportunities"),
    t("We are drafting engaging email sequences"),
    t("We are creating captivating graphics for your content"),
    t("We are setting up tracking pixels for accurate analytics"),
    t("We are enhancing your product descriptions for conversions"),
    t("We are segmenting your audience for targeted marketing"),
    t("We are brainstorming viral campaign ideas"),
    t("We are optimizing your product photos for eCommerce"),
    t("We are conducting A/B tests for better campaign performance"),
    t("We are developing interactive content for engagement"),
    t("We are building landing pages for lead generation"),
    t("We are setting up automated workflows for efficiency"),
    t("We are exploring new advertising channels for growth"),
    t("We are designing eye-catching banners for promotions"),
    t("We are crafting compelling value propositions"),
    t("We are curating user-generated content for authenticity"),
    t("We are translating your content for international markets"),
    t("We are designing infographics for data visualization"),
    t("We are setting up retargeting campaigns for lost leads"),
    t("We are conducting user testing for UX improvements"),
    t("We are creating case studies to showcase success stories"),
    t("We are optimizing your email subject lines for opens"),
    t("We are developing video content for engagement"),
    t("We are conducting social listening for brand sentiment"),
    t("We are optimizing your content for search engines"),
    t("We are designing promotional flyers for events"),
    t("We are optimizing your landing page loading speed"),
    t("We are crafting FAQs to address common queries"),
    t("We are conducting usability testing for website navigation"),
    t("We are setting up Google Analytics goals for tracking"),
  ];

  useEffect(() => {
    AuthService.me().then((data) => setMe(data.data.me));
  }, []);

  useEffect(() => {
    setInterval(
      () => setPercent((p) => Math.min(100, p + randomNumber())),
      3500
    );
  }, []);

  useEffect(() => {
    if (percent === 100) {
      if (onFinish) {
        onFinish();
      } else {
        AuthService.updateMe({ onboardAnimation: true, silent: true }).then(
          () => {
            navigate("/auth/subscription");
          }
        );
      }
    }

    setInformationIdx((i) => i + 1);
  }, [percent, onFinish]);

  return (
    <div className=" flex items-center justify-center">
      <div className="text-center mt-10 ">
        <h3 className="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-400 ">
          {t("Onboarding")}
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          {t("We are setting up your customized marketing system.")}
        </p>

        <div className="mt-6">
          <Progress
            type="dashboard"
            percent={percent}
            strokeColor={twoColors}
          />
        </div>

        <Divider />

        <Alert
          message={t("Information")}
          description={informations[informationIdx % informations.length]}
          type="info"
          showIcon
          icon={
            percent === 100 ? (
              <></>
            ) : (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            )
          }
        />
      </div>
    </div>
  );
}
