import Cookies from "js-cookie";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Select from "../../components/Select";
import { login } from "../../redux/auth/actions";
import { store } from "../../redux/store";
import AuthService from "../../service/AuthService";

import { Checkbox } from "antd";
import { useSelector } from "react-redux";
import { getPartner, selectLoading } from "../../redux/auth/selectors";
import ATSService from "../../service/ATSService";
import { Button } from "../Landing/Button";
import { TextField } from "../Landing/Fields";
import { Logo } from "../Landing/Logo";
import { SlimLayout } from "../Landing/SlimLayout";

const Login = () => {
  const partner = useSelector(getPartner);
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const [firstName, lastName, email, password] = new Array(4)
        .fill(0)
        .map((_, i) => e.target[i].value);

      await AuthService.register({
        firstName,
        lastName,
        email,
        password,
        onboarding:
          localStorage.demoVacancy && localStorage.demoVacancy !== "undefined"
            ? JSON.parse(localStorage.demoVacancy)
            : {},
        onboardingSurvey: localStorage.onboardingSurvey
          ? JSON.parse(localStorage.onboardingSurvey)
          : {},
      });

      const result = await AuthService.login({
        email,
        password,
      });
      if (!result?.data?.accessToken)
        return message.error(t("Could not load user data"));

      Cookies.set("accessToken", result?.data?.accessToken);
      Cookies.set("refreshToken", result?.data?.refreshToken);

      const me = await AuthService.me();
      if (!me?.data) return message.error(t("Could not load user data"));

      store.dispatch(login(me.data.me));

      if (
        localStorage.demoVacancy &&
        localStorage.demoVacancy !== "undefined"
      ) {
        const vacancy = JSON.parse(localStorage.demoVacancy);

        await ATSService.createVacancy({
          vacancyTemplate: vacancy,
        });

        localStorage.demoVacancy = undefined;
      }

      localStorage.iJustSignedUp = "x";
      navigate("/auth/otpemail");
    },
    [navigate, t]
  );

  return (
    <>
      <div className="flex h-[100vh] flex-col">
        <SlimLayout>
          <div className="flex">
            <Link href="/" aria-label={t("Home")}>
              <Logo className="h-10 w-auto" />
            </Link>
          </div>
          <h2 className="mt-20 text-lg font-semibold text-gray-900 dark:text-gray-400 ">
            {t("Get started")} {partner?.trialDays > 0 ? t("for free") : ""}
          </h2>
          <p className="mt-2 text-sm text-gray-700 dark:text-gray-300 ">
            {t("Already registered?")}{" "}
            <Link
              to="/auth/login"
              className="font-medium text-blue-600 hover:underline"
            >
              {t("Sign in")}
            </Link>{" "}
            {t("to your account.")}
          </p>
          <form
            action="#"
            className="mt-10 grid grid-cols-1 gap-y-8"
            onSubmit={handleSubmit}
          >
            <TextField
              label={t("First name")}
              name="first_name"
              type="text"
              autoComplete="given-name"
              required
            />
            <TextField
              label={t("Last name")}
              name="last_name"
              type="text"
              autoComplete="family-name"
              required
            />
            <TextField
              className="col-span-full"
              label={t("Email address")}
              name="email"
              type="email"
              autoComplete="email"
              required
            />
            <TextField
              className="col-span-full"
              label={t("Password")}
              name="password"
              type="password"
              autoComplete="new-password"
              required
            />

            {
              <div className="flex gap-2">
                <Checkbox className="col-span-full" required />
                <label>
                  {t("I accept the")}{" "}
                  <a href="/legal/privacy" target="_blank">
                    {t("privacy policy")}
                  </a>{" "}
                  {t("and")}{" "}
                  <a href="/legal/terms" target="_blank">
                    {t("terms of service")}
                  </a>
                </label>
              </div>
            }
            <div>
              <Button
                type="submit"
                variant="solid"
                color="blue"
                className="w-full"
                loading={loading}
              >
                <span>
                  {t("Sign up")} <span aria-hidden="true">&rarr;</span>
                </span>
              </Button>
            </div>
          </form>
        </SlimLayout>
      </div>
    </>
  );
};

export default Login;
