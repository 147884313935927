import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Modal,
  Popconfirm,
  Select,
  Tooltip,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrInfo } from "react-icons/gr";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { selectUser } from "../../../../redux/auth/selectors";
import CrudService from "../../../../service/CrudService";
import VariableMessageBox from "../../Message/VariableMessageBox";

const { Content } = Layout;
const { Option } = Select;

export default function EmailSequencing() {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [triggers, setTriggers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const user = useSelector(selectUser);

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);

  useEffect(() => {
    const totalMinutes = days * 24 * 60 + hours * 60 + minutes;
    setTotalMinutes(totalMinutes);
  }, [days, hours, minutes]);

  const fetchTriggers = useCallback(async () => {
    const id = searchParams.get("id");
    if (!id) return;
    if (!user) return;
    try {
      const response = await CrudService.search("EmailTrigger", 1000, 1, {
        sort: { createdAt: 1 },
        filters: { vacancy_id: id, user_id: user._id },
      });
      setTriggers(response.data.items);
    } catch (error) {
      console.error("Error fetching triggers:", error);
      message.error(t("Failed to fetch triggers"));
    }
  }, [searchParams, user, t]);

  useEffect(() => {
    fetchTriggers();
  }, [fetchTriggers]);

  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  const handleAddTrigger = async (values) => {
    try {
      await CrudService.create("EmailTrigger", {
        ...values,
        vacancy_id: searchParams.get("id"),
        user_id: user._id,
      });
      setIsModalVisible(false);
      fetchTriggers();
      message.success(t("Trigger added successfully"));
    } catch (error) {
      console.error("Error adding trigger:", error);
      message.error(t("Failed to add trigger"));
    }
  };

  const handleTriggerClick = (trigger) => {
    setSelectedTrigger(trigger);
    if (trigger?.totalMinutes) {
      const totalMinutes = trigger.totalMinutes;
      const calculatedDays = Math.floor(totalMinutes / (24 * 60));
      const remainingMinutesAfterDays = totalMinutes % (24 * 60);
      const calculatedHours = Math.floor(remainingMinutesAfterDays / 60);
      const calculatedMinutes = remainingMinutesAfterDays % 60;

      setDays(calculatedDays);
      setHours(calculatedHours);
      setMinutes(calculatedMinutes);
    } else {
      setDays(0);
      setHours(0);
      setMinutes(0);
    }

    setIsEmailModalVisible(true);
  };

  const handleEmailSave = useCallback(
    async (subject, body) => {
      setIsEmailModalVisible(false);

      if (selectedTrigger) {
        try {
          await CrudService.update("EmailTrigger", selectedTrigger._id, {
            emailSubject: subject,
            emailBody: body,
            totalMinutes,
          });
          fetchTriggers();
          message.success(t("Email template saved successfully"));
        } catch (error) {
          console.error("Error saving email template:", error);
          message.error(t("Failed to save email template"));
        }
      }
    },
    [totalMinutes, t]
  );

  const handleDeleteTrigger = async (triggerId) => {
    try {
      await CrudService.delete("EmailTrigger", triggerId);
      fetchTriggers();
      message.success(t("Trigger deleted successfully"));
    } catch (error) {
      console.error("Error deleting trigger:", error);
      message.error(t("Failed to delete trigger"));
    }
  };

  return (
    <Layout className="min-h-screen">
      <Content className="p-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={showModal}
            className="mb-4"
          >
            {t("Add New Trigger")}
          </Button>
          <div className="flex flex-col items-center">
            {triggers.map((trigger, index) => (
              <React.Fragment key={trigger._id}>
                <div className="flex items-center justify-between bg-blue-100 p-4 rounded-lg mb-4 w-64">
                  <div
                    className="flex-grow text-center cursor-pointer"
                    onClick={() => handleTriggerClick(trigger)}
                  >
                    {t(trigger.type)}
                  </div>
                  <Popconfirm
                    title={t("Are you sure you want to delete this trigger?")}
                    onConfirm={() => handleDeleteTrigger(trigger._id)}
                    okText={t("Yes")}
                    cancelText={t("No")}
                  >
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      className="text-red-500 hover:text-red-700"
                    />
                  </Popconfirm>
                </div>
                {index < triggers.length - 1 && (
                  <div className="h-8 w-px bg-gray-300 mb-4"></div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </Content>
      <Modal
        title={t("Add New Trigger")}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form onFinish={handleAddTrigger}>
          <Form.Item
            name="type"
            label={t("Trigger Type")}
            rules={[
              { required: true, message: t("Please select a trigger type") },
            ]}
          >
            <Select placeholder={t("Select a trigger type")}>
              <Option value="Optin">{t("Optin")}</Option>
              <Option value="Engagement">{t("Engagement")}</Option>
              <Option value="Nurtured">{t("Nurtured")}</Option>
              <Option value="Appointment">{t("Appointment")}</Option>
              <Option value="Converted">{t("Converted")}</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("Add Trigger")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={t("Edit Email Template")}
        open={isEmailModalVisible}
        onCancel={() => setIsEmailModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        {selectedTrigger && (
          <>
            <br />
            <label className="font-bold ">{t("Delay")}</label>
            <div className="mb-10 flex w-full justify-start items-center gap-1">
              <div>
                <div>{t("Days")}</div>
                <InputNumber
                  min={0}
                  value={days}
                  onChange={(value) => setDays(value ?? 0)}
                  placeholder={t("Days")}
                  style={{ width: "70px" }}
                />
              </div>
              <div>
                <div>{t("Hours")}</div>
                <InputNumber
                  min={0}
                  value={hours}
                  onChange={(value) => setHours(value ?? 0)}
                  placeholder={t("Hours")}
                  style={{ width: "70px" }}
                />
              </div>
              <div>
                <div>{t("Minutes")}</div>
                <InputNumber
                  min={0}
                  value={minutes}
                  onChange={(value) => setMinutes(value ?? 0)}
                  placeholder={t("Minutes")}
                  style={{ width: "70px" }}
                />
              </div>
              <Tooltip
                title={t(
                  "Leads will receive the message {{timing}} after trigger.",
                  {
                    timing:
                      days > 0 || hours > 0 || minutes > 0
                        ? t(
                            "after {{days}} day(s) {{hours}} hour(s) {{minutes}} minute(s)",
                            { days, hours, minutes }
                          )
                        : t("immediately"),
                  }
                )}
              >
                <GrInfo className="mt-5 " />
              </Tooltip>
            </div>
            <VariableMessageBox
              workflowData={selectedTrigger}
              defaultSubject={selectedTrigger.emailSubject}
              defaultBody={selectedTrigger.emailBody}
              onSend={handleEmailSave}
            />
          </>
        )}
      </Modal>
    </Layout>
  );
}
