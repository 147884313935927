import { Spin } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { Wave } from "react-animated-text";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STANDARD_MOMENT_FORMAT } from "../../../data/constants";
import { getPartner, selectUser } from "../../../redux/auth/selectors";
import ATSService from "../../../service/ATSService";
import PublicService from "../../../service/PublicService";
import UploadService from "../../../service/UploadService";
import { templates } from "./templates";
import WelcomeUser from "./WelcomeUser";

const CreateVacancy = ({ GenerateSectionOnly = false }) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [hideWave, setHideWave] = useState(false);
  const describeRef = useRef();
  const partner = useSelector(getPartner);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const socket = useRef();
  const socketPing = useRef();

  const handleSubmit = useCallback(() => {
    if (!user || !Cookies.get("accessToken")) {
      localStorage.describeLandingpage = text;
      navigate("/onboarding");
    }

    socket.current = new WebSocket(
      `wss://booklified-chat-socket.herokuapp.com`
    );

    socket.current.addEventListener("open", async () => {
      setLoading(true);

      const images = await Promise.all(
        new Array(6)
          .fill(0)
          .map(async () => {
            try {
              const response = await PublicService.replicateGen({
                input: {
                  prompt: `
                      Target Audience:
      Persona Age: ${user?.customerPersonaAge ?? ""}
      Persona Gender: ${user?.customerPersonaGender ?? ""}
      Persona Interest: ${user?.customerPersonaInterest ?? ""}
      Persona Industry: ${user?.customerPersonaIndustry ?? ""}
      Persona Keywords: ${user?.customerPersonaKeywords ?? ""}

      Also consider this information to create the content: ${text}

        
        For our landing page, we need a picture that describes the promise / value proposition of our business

        Image without any text
        `,
                },
                id: "bytedance/sdxl-lightning-4step:5f24084160c9089501c1b3545d9be3c27883ae2239b6f412990e82d4a6210f8f",
              });

              const replicateUrl = response?.data?.output?.[0];

              // Check if we have a valid Replicate URL
              if (replicateUrl) {
                // Fetch the image from the replicate URL
                const imageResponse = await fetch(replicateUrl);
                const blob = await imageResponse.blob();

                // Create a temporary file to upload to Cloudinary
                const file = new File(
                  [blob],
                  `replicate_image_${Date.now()}.png`,
                  { type: blob.type }
                );

                // Upload the image to Cloudinary
                console.log(file);
                const result = await UploadService.upload(file);
                console.log(result?.data);
                // Return the Cloudinary secure URL
                return result?.data?.secure_url || null;
              }
              return null;
            } catch (e) {
              return null;
            }
          })
          .filter((i) => !!i)
      );

      console.log(images);

      socketPing.current = setInterval(
        () => socket.current.send(JSON.stringify({ id: "PING" })),
        30000
      );

      const content = `
      ${partner?.landingPagePrompt}

      Target Audience:
      Persona Age: ${user?.customerPersonaAge ?? ""}
      Persona Gender: ${user?.customerPersonaGender ?? ""}
      Persona Interest: ${user?.customerPersonaInterest ?? ""}
      Persona Industry: ${user?.customerPersonaIndustry ?? ""}
      Persona Keywords: ${user?.customerPersonaKeywords ?? ""}

      Also consider this information to create the content: ${text}

      Your answer should be in JSON format. Do not include anything else in your answer. I should be able to process your answer with JSON.parse function in Javascript. This structure should be followed (change the content):
      ${
        templates({ user, images })[localStorage?.selectedTemplate ?? "default"]
      }

      The "id" field can never be anything other than:
      Hero
      CustomerPainPoints
      CallToAction
      FeatureSlider
      TestemonialSectionSlider
      AdminFeatures
      DivideLine
      LeadForm
      Benefits
      ValueProp

      It is imminent to have all of the fields in the specified order included in your answer:
      ${JSON.parse(
        templates({ user, images })[localStorage?.selectedTemplate ?? "default"]
      )
        .pageContent.map((a) => a.id)
        .join("\n")}

      Do not change any links, images, and buttons from the provided example. Those should be exactly same.
      `;

      socket.current.send(
        JSON.stringify({
          id: "OPEN_AI_PROMPT",
          payload: {
            content,
            model: "gpt-3.5-turbo",
            partner: partner?._id,
            app_id: "hidec",
            max_tokens: 4096,
          },
        })
      );
    });

    socket.current.addEventListener("message", async (event) => {
      const message = JSON.parse(event.data);
      const response = message.payload?.response;

      const data = JSON.parse(response);
      if (!Array.isArray(data.pageContent)) return;

      const res = await ATSService.createVacancy({
        vacancyTemplate: {
          name: data?.name ?? moment().format(STANDARD_MOMENT_FORMAT),
          pageContent: data.pageContent,
        },
      });

      if (res.data?.vacancy?._id) navigate(`/page/${res.data.vacancy._id}`);

      if (socketPing.current) clearInterval(socketPing.current);
      setLoading(false);
    });
  }, [user, partner, socket, socketPing, text, navigate, t]);

  const GenerateSection = (
    <div className="w-full mx-4">
      <div className="glowing-border bg-white w-full h-[48px] pl-2 pr-2 gap-1 flex flex-row items-center rounded-[8px] relative before:absolute before:rounded-[9px] before:border-black_e before:inset-[-1px] before:border-[1px] before:pointer-events-none shadow-try-ai-input hover:shadow-try-ai-input-2 transition ease-in-out duration-300 animated-border-box">
        <img
          alt=""
          loading="lazy"
          width={96}
          height={96}
          decoding="async"
          data-nimg={1}
          className="w-8 h-8 object-cover"
          style={{ color: "transparent" }}
          src="/images/6056251-200.png"
        />
        <div className="flex-1 relative">
          <input
            className="w-full text-black h-[48px] focus:outline-none text-xs truncate placeholder:text-xs overflow-hidden border-none focus:border-none focus:outline-none focus:shadow-none focus:ring-transparent"
            onChange={(e) => setText(e.target.value.slice(0, 180))}
            value={text}
            ref={describeRef}
            onBlur={() => {
              setHideWave(false);
            }}
          />

          <div
            className={`whitespace-nowrap truncate absolute start-0 end-0 top-0 bottom-0 text-gray-500 flex items-center justify-start ml-2 ${
              hideWave || text ? "hidden" : ""
            }`}
            onClick={() => {
              setHideWave(true);
              describeRef.current.focus();
            }}
            onBlur={() => {
              setHideWave(false);
            }}
          >
            <Wave
              text={t("Describe your business...")}
              class="absolute start-0 end-0 top-0 bottom-0"
              effect="stretch"
              effectChange={2}
            />
          </div>
        </div>
        <div>
          <button
            className="focus:outline-none"
            type="button"
            onClick={handleSubmit}
            disabled={loading}
          >
            <div className="flex lg:inline-flex max-lg:w-full items-center justify-center space-x-1 px-4 py-2  text-invert-primary font-sans font-medium bg-gradient-to-r from-feature-ai-gradient-1 via-feature-ai-gradient-2 to-feature-ai-gradient-3 rounded-xl lg:rounded-full border-none">
              <span className="whitespace-nowrap text-center overflow-hidden font-bold">
                {loading ? <Spin>{t("Generate")}</Spin> : t("Generate")}
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );

  if (GenerateSectionOnly) return <>{GenerateSection}</>;
  return (
    <div>
      <WelcomeUser />

      <div
        className="w-full z-0 max-w-[1048px] py-16 px-8 text-white dark:text-black rounded-xl"
        style={{
          backgroundImage: "url(/images/img_background2.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex-1 flex flex-col items-start justify-start w-full gap-4">
          <div className="flex flex-row px-4">
            <div className=" text-white bg-indigo-500 px-2 py-1 rounded-md text-[12px] md:text-[20px]  font-semibold">
              {t("Generate landing page with AI")}
            </div>
          </div>
          {GenerateSection}
        </div>
      </div>
    </div>
  );
};

export default CreateVacancy;
