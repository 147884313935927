import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./style/index.scss";

import enUS from "antd/locale/en_US";
import jaJP from "antd/locale/ja_JP";
import Cookies from "js-cookie";

import { ConfigProvider, theme } from "antd";
import { useSelector } from "react-redux";
import RouteInit from "./RouteInit";
import { brandColor } from "./data/constants";
import { getPartner, selectDarkMode } from "./redux/auth/selectors";
const AntdConfig = () => {
  const partner = useSelector(getPartner);
  const darkMode = useSelector(selectDarkMode);
  const { defaultAlgorithm, darkAlgorithm } = theme;

  const [lang, setLang] = useState("jp");

  useEffect(() => {
    setLang(Cookies.get("i18next"));
  }, []);
  return (
    <>
      <ConfigProvider
        theme={{
          token: { colorPrimary: partner?.themeColor ?? brandColor },
          algorithm: darkMode ? darkAlgorithm : defaultAlgorithm,
        }}
        locale={lang.includes("en") ? enUS : jaJP}
      >
        <Router>
          <RouteInit />
        </Router>
      </ConfigProvider>
    </>
  );
};

export default AntdConfig;
