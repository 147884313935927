import { Button, Card, Col, Row, Select, Space, Spin, Statistic } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdRefresh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import StatsService from "../../service/StatsService";

const { Option } = Select;

const segmentDetails = {
  H: { segmentNum: 8, timeframeName: "hour" },
  D: { segmentNum: 7, timeframeName: "day" },
  W: { segmentNum: 4, timeframeName: "week" },
  M: { segmentNum: 6, timeframeName: "month" },
  A: { segmentNum: 5, timeframeName: "year" },
};

const PieChartComponent = ({ title, data }) => {
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"];
  return (
    <div>
      <PieChart width={150} height={150}>
        <Pie
          data={data}
          dataKey="count"
          nameKey="_id"
          outerRadius={40}
          cx={100}
          fill="#8884d8"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value, name) => [value, name]} />
        <Legend />
      </PieChart>
    </div>
  );
};

const StatsDashboard = ({ funnelId, vacancyData, back }) => {
  const { t } = useTranslation();
  const [mainStats, setMainStats] = useState({});
  const [segmentedStats, setSegmentedStats] = useState([]);
  const [KPIs, setKPIs] = useState([]);
  const [timeToApply, setTimeToApply] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [timeframe, setTimeframe] = useState("D");
  const navigate = useNavigate();

  const isLoading = useMemo(
    () => loading1 || loading2 || loading3,
    [loading1, loading2, loading3]
  );

  const chartData = [...segmentedStats].reverse().map((segment) => ({
    name: t("{{timeframe}} {{idx}} ago", {
      timeframe: t(segmentDetails[timeframe].timeframeName, {
        count: segment.idx - 1,
      }),
      idx: segment.idx === 1 ? t("This") : segment.idx - 1,
    }),
    Clicks: segment.clicks,
    Conversions: segment.applicants,
    Qualified: segment.qualified,
    Meetings: segment.meetings,
    Hires: segment.hires,
  }));

  const loadData = useCallback(() => {
    setLoading1(true);
    setLoading2(true);
    setLoading3(true);
    setLoading4(true);

    StatsService.getNumbers(funnelId ?? "")
      .then(({ data }) => {
        setMainStats(data);
      })
      .finally(() => {
        setLoading1(false);
      });
    StatsService.getTimeToApply(funnelId ?? "")
      .then(({ data }) => {
        setTimeToApply(data);
      })
      .finally(() => {
        setLoading2(false);
      });
    StatsService.getSegmentedNumbers(funnelId ?? "", timeframe)
      .then(({ data }) => {
        setSegmentedStats(data);
      })
      .finally(() => {
        setLoading3(false);
      });

    StatsService.getSurveys(funnelId ?? "")
      .then(({ data }) => {
        setKPIs(data.KPIs);
      })
      .finally(() => {
        setLoading4(false);
      });
  }, [timeframe, funnelId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const utmList = [
    {
      name: "utmCampaign",
      title: t("UTM Campaign"),
      data: mainStats.utmCampaignAggregation,
    },
    {
      name: "utmSource",
      title: t("UTM Source"),
      data: mainStats.utmSourceAggregation,
    },
    {
      name: "utmMedium",
      title: t("UTM Medium"),
      data: mainStats.utmMediumAggregation,
    },
    {
      name: "utmContent",
      title: t("UTM Content"),
      data: mainStats.utmContentAggregation,
    },
    {
      name: "utmTerm",
      title: t("UTM Term"),
      data: mainStats.utmTermAggregation,
    },
    {
      name: "salesforceUUID",
      title: t("Salesforce UUID"),
      data: mainStats.salesforceUuidAggregation,
    },
  ];

  return (
    <>
      <div>
        {vacancyData && funnelId && (
          <>
            <div
              className="mb-2 flex justify-start gap-2 items-center cursor-pointer text-sm"
              onClick={() => navigate(back ? back : "/dashboard/myvacancies")}
            >
              <MdArrowBackIos size={13} />{" "}
              <div>{back ? t("Back") : t("Back to Funnels")}</div>
            </div>
            <h1 className="font-bold mb-4 text-lg">
              {t("Funnel Statistics: {{name}}", {
                name: vacancyData?.alternativeName || vacancyData?.name,
              })}
            </h1>
          </>
        )}

        <Row gutter={[16, 16]}>
          <Col span={24} className="mb-2">
            <Card>
              <div className="mb-2 flex justify-between">
                <h2 className="font-semibold ">
                  {t("Your overall performance")}
                </h2>{" "}
                {isLoading ? (
                  <Spin />
                ) : (
                  <MdRefresh
                    size={20}
                    onClick={loadData}
                    className="cursor-pointer"
                  />
                )}
              </div>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Statistic
                    title={t("Clicks")}
                    value={`${mainStats?.clicks ?? ""}`}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title={t("Conversions")}
                    value={t("{{count}} ({{percentage}}%)", {
                      count: mainStats?.applicants ?? "",
                      percentage: mainStats.clicks
                        ? (
                            (100 * mainStats.applicants) /
                            mainStats.clicks
                          ).toFixed(1)
                        : "",
                    })}
                    valueStyle={{ color: "#52c41a" }}
                  />
                </Col>

                <Col span={12}>
                  <Statistic
                    title={t("Qualified")}
                    value={t("{{count}} ({{percentage}}%)", {
                      count: mainStats?.qualified ?? "",
                      percentage: mainStats.applicants
                        ? (
                            (100 * mainStats.qualified) /
                            mainStats.applicants
                          ).toFixed(1)
                        : "",
                    })}
                    valueStyle={{ color: "#fa8c16" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title={t("Meetings")}
                    value={t("{{count}} ({{percentage}}%)", {
                      count: mainStats?.meetings ?? "",
                      percentage: mainStats.qualified
                        ? (
                            (100 * mainStats.meetings) /
                            mainStats.qualified
                          ).toFixed(1)
                        : "",
                    })}
                    valueStyle={{ color: "#722ed1" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title={t("Customers")}
                    value={t("{{count}} ({{percentage}}%)", {
                      count: mainStats?.hires ?? "",
                      percentage: mainStats.meetings
                        ? (
                            (100 * mainStats.hires) /
                            mainStats.meetings
                          ).toFixed(1)
                        : "",
                    })}
                    valueStyle={{ color: "#eb2f96" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24} className="mb-2">
            <Card>
              <div className="flex justify-between mb-2">
                <h2 className="font-semibold">
                  {t(
                    "How your funnel{{s}} performed during the last {{count}} {{timeframe}}s",
                    {
                      s: funnelId ? "" : "s",
                      count: segmentDetails[timeframe].segmentNum,
                      timeframe: t(segmentDetails[timeframe].timeframeName, {
                        count: segmentDetails[timeframe].segmentNum,
                      }),
                    }
                  )}
                </h2>
                <Space>
                  <Select
                    defaultValue={timeframe}
                    style={{ width: 150 }}
                    onChange={(value) => setTimeframe(value)}
                  >
                    <Option value="H">
                      {t("Last {{count}} hours", {
                        count: segmentDetails["H"].segmentNum,
                      })}
                    </Option>
                    <Option value="D">
                      {t("Last {{count}} days", {
                        count: segmentDetails["D"].segmentNum,
                      })}
                    </Option>
                    <Option value="W">
                      {t("Last {{count}} weeks", {
                        count: segmentDetails["W"].segmentNum,
                      })}
                    </Option>
                    <Option value="M">
                      {t("Last {{count}} months", {
                        count: segmentDetails["M"].segmentNum,
                      })}
                    </Option>
                    <Option value="A">
                      {t("Last {{count}} years", {
                        count: segmentDetails["A"].segmentNum,
                      })}
                    </Option>
                  </Select>

                  {isLoading ? (
                    <Spin />
                  ) : (
                    <MdRefresh
                      size={20}
                      onClick={loadData}
                      className="cursor-pointer"
                    />
                  )}
                </Space>
              </div>
              <div className="max-w-full overflow-auto">
                <LineChart width={800} height={400} data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey={t("Clicks")}
                    stroke="#8884d8"
                  />
                  <Line
                    type="monotone"
                    dataKey={t("Conversions")}
                    stroke="#ff7300"
                  />
                  <Line
                    type="monotone"
                    dataKey={t("Qualified")}
                    stroke="#ff3860"
                  />
                  <Line
                    type="monotone"
                    dataKey={t("Meetings")}
                    stroke="#ffaa00"
                  />
                  <Line type="monotone" dataKey={t("Hires")} stroke="#00bfff" />
                </LineChart>
              </div>
            </Card>
          </Col>
        </Row>

        {KPIs.length > 0 && (
          <Row gutter={[16, 16]}>
            <Col span={24} className="mb-2">
              <Card>
                <div className="mb-2 flex justify-between">
                  <h2 className="font-semibold">{t("Lead Surveys")}</h2>
                  {isLoading ? (
                    <Spin />
                  ) : (
                    <MdRefresh
                      size={20}
                      onClick={loadData}
                      className="cursor-pointer"
                    />
                  )}
                </div>

                <Row gutter={[16, 16]} className="mt-2">
                  {KPIs.map((kpi) => (
                    <Col span={8} key={kpi.key}>
                      <Card>
                        <div style={{ textAlign: "center" }}>
                          <Statistic
                            title={t(kpi.key)}
                            value={kpi.average.toFixed(2)}
                          />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <p>
                            {t("Min: {{min}} | Max: {{max}}", {
                              min: kpi.min,
                              max: kpi.max,
                            })}
                          </p>
                          <p>
                            {t("Median: {{median}}", { median: kpi.median })}
                          </p>
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card>
            </Col>
          </Row>
        )}

        {utmList.filter((e) => !!e?.data && e?.data?.length > 0).length > 0 && (
          <>
            <div className="mb-2 flex justify-between items-end mt-5">
              <h2 className="font-semibold mt-5">{t("UTM Tracking")}</h2>
              {isLoading ? (
                <Spin />
              ) : (
                <MdRefresh
                  size={20}
                  onClick={loadData}
                  className="cursor-pointer"
                />
              )}
            </div>

            <Row gutter={[16, 16]} className="mt-2">
              {utmList
                .filter((e) => !!e?.data && e?.data?.length > 0)
                .map((utmField) => (
                  <Col span={8} key={utmField.name}>
                    <Card>
                      <div style={{ textAlign: "center" }}>
                        <h3>{utmField.title}</h3>
                        {utmField.data?.length > 0 ? (
                          <PieChartComponent
                            title={utmField.title}
                            data={utmField.data}
                          />
                        ) : (
                          <p>{t("No data available")}</p>
                        )}
                      </div>
                    </Card>
                  </Col>
                ))}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default StatsDashboard;
