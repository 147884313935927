import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { Popconfirm, Skeleton, Space, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import MultiStepComponent from "../../../components/MultiStepComponent";
import MultiStepConfigurator from "../../../components/MultiStepConfigurator";
import { getPartner } from "../../../redux/auth/selectors";
import CalendlyService from "../../../service/CalendlyService";
import CrudService from "../../../service/CrudService";
import getFormPrompt from "./getFormPrompt";
import getFunnelPrompt from "./getFunnelPrompt";

export default function VacancyEdit() {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [vacancyData, setVacancyData] = useState(null);
  const [funnelSteps, setFunnelSteps] = useState([]);
  const [thinking, setThinking] = useState(false);
  const vidRef = useRef();
  const socket = useRef();
  const socketPing = useRef();
  const partner = useSelector(getPartner);
  const [eventTypes, setEventTypes] = useState([]);

  const steps = (eventTypes) => [
    {
      id: "step1",
      name: t("General Information"),
      form: [
        {
          fieldName: "name",
          label: t("Job name"),
          type: "input",
          placeholder: t("Dentist"),
        },
        {
          fieldName: "valueProposition",
          label: t("Scroll stopper"),
          type: "input",
          placeholder: t("Enter the scroll stopper text"),
        },
        {
          fieldName: "heroTitle",
          label: t("Hero title"),
          type: "textarea",
          placeholder: t("Enter title on top of your funnel"),
        },
        {
          fieldName: "requiredSkills",
          label: t("Requirements"),
          type: "textarea",
          placeholder: t("Enter the required skills"),
        },
      ],
    },
    {
      id: "step10",
      name: t("Details"),
      form: [
        {
          fieldName: "location",
          label: t("Preferred Work Location"),
          type: "input",
          placeholder: t("Enter your preferred work location"),
        },
        {
          fieldName: "engagementType",
          label: t("Engagement Type"),
          type: "select",
          options: [
            { value: "Permanent", label: t("Permanent") },
            { value: "Temporary", label: t("Temporary") },
            { value: "Fixed-Term", label: t("Fixed-Term") },
            { value: "Freelance", label: t("Freelance") },
            { value: "Project-based", label: t("Project-based") },
            { value: "Retainer", label: t("Retainer") },
            { value: "Seasonal", label: t("Seasonal") },
            { value: "Internship", label: t("Internship") },
            { value: "Apprenticeship", label: t("Apprenticeship") },
            { value: "Commission-Based", label: t("Commission-Based") },
            { value: "Contract-to-Hire", label: t("Contract-to-Hire") },
            { value: "Partnership", label: t("Partnership") },
          ],
        },
        {
          fieldName: "contractType",
          label: t("Contract Type"),
          type: "select",
          options: [
            { value: "Full-time", label: t("Full-time") },
            { value: "Part-time", label: t("Part-time") },
            { value: "Casual", label: t("Casual") },
            { value: "Zero-Hours", label: t("Zero-Hours") },
            { value: "Profit-share", label: t("Profit-share") },
          ],
        },
        {
          fieldName: "preferredCalendlyEvent",
          label: t("Calendly Event Type"),
          type: "select",
          tooltip: t(
            "Specify a unique Calendly event type for each funnel, or leave blank to use the default account setting for interviews."
          ),
          options: eventTypes.map((eventType) => ({
            value: eventType.uri,
            label: eventType.name,
          })),
        },
      ],
    },
    {
      id: "step3",
      name: t("Benefits & Culture"),
      form: [
        {
          fieldName: "benefitsTitle",
          label: t("Benefits Title"),
          type: "input",
          placeholder: t("Title for Benefits Section"),
        },
        {
          fieldName: "benefitsText",
          label: t("Benefits Description"),
          type: "textarea",
          rows: 10,
          placeholder: t("Describe the benefits of working with us"),
        },
        {
          fieldName: "benefits",
          label: t("Benefits"),
          type: "list",
          defaultForm: [
            {
              fieldName: "title",
              label: t("Title"),
              type: "input",
              placeholder: t("Title of this benefit"),
            },
            {
              fieldName: "description",
              label: t("Description"),
              type: "textarea",
              placeholder: t("Description of this benefit"),
            },
            {
              fieldName: "icon",
              label: t("Icon"),
              type: "input",
              placeholder: t("Emoji"),
            },
          ],
          defaultObject: {
            title: "",
            description: "",
            icon: "",
          },
        },
      ],
    },
    {
      id: "step4",
      name: t("Testimonials"),
      form: [
        {
          fieldName: "testimonialTitle",
          label: t("Testimonial Section Title"),
          type: "input",
          placeholder: t("Enter the title for the testimonial section"),
        },
        {
          fieldName: "teamTestimonials",
          label: t("Testimonials"),
          type: "list",
          defaultForm: [
            {
              fieldName: "author",
              label: t("Fullname"),
              type: "input",
              placeholder: t("Fullname of the team member"),
            },
            {
              fieldName: "authorPosition",
              label: t("Role"),
              type: "input",
              placeholder: t("The position of the team member"),
            },
            {
              fieldName: "testimonial",
              label: t("Testimonial"),
              type: "textarea",
              rows: 5,
              placeholder: t(
                "What does the team member think about the company?"
              ),
            },
          ],
          defaultObject: {
            author: "",
            authorPosition: "",
            testimonial: "",
          },
        },
      ],
    },
    {
      id: "step5",
      name: t("Application Submission"),
      form: [
        {
          fieldName: "CTA",
          label: t("Call to Action"),
          type: "input",
          placeholder: t("Call to action for application submission"),
        },
      ],
    },
    {
      id: "step6",
      name: t("Post-Application"),
      form: [
        {
          fieldName: "thankYouHero",
          label: t("Thank You Message"),
          type: "input",
          placeholder: t("Enter the thank you message post-application"),
        },
        {
          fieldName: "underReviewInfo",
          label: t("Application Under Review Information"),
          type: "textarea",
          rows: 3,
          placeholder: t("Information about the application review process"),
        },
        {
          fieldName: "nextSteps",
          label: t("Next Steps After Application"),
          type: "textarea",
          rows: 3,
          placeholder: t(
            "Describe the next steps after the application is submitted"
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    if (!partner?.calendlyclientId) return;
    CalendlyService.getEventTypes().then(({ data }) => {
      setEventTypes(data.eventTypes);
    });
  }, [partner]);

  useEffect(() => {
    if (vacancyData?.form) setFunnelSteps(vacancyData.form);
  }, [vacancyData]);

  useEffect(() => {
    if (vidRef.current) vidRef.current.play();
  }, [vidRef]);

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id) return;
    if (!partner) return;
    setVacancyData(null);
    setFunnelSteps([]);
    setThinking(false);

    CrudService.getSingle("Vacancy", id).then((res) => {
      if (!res.data) return;
      setVacancyData(res.data);
      if (!res.data?.form) {
        setThinking(true);
        socket.current = new WebSocket(
          `wss://booklified-chat-socket.herokuapp.com`
        );

        socket.current.addEventListener("open", async () => {
          socketPing.current = setInterval(
            () => socket.current.send(JSON.stringify({ id: "PING" })),
            30000
          );
          const content = getFormPrompt({
            jobName: res.data.name,
            description: res.data.description,
            keyBenefits: res.data.keyBenefits,
            requiredSkills: res.data.requiredSkills,
          });
          socket.current.send(
            JSON.stringify({
              id: "OPEN_AI_PROMPT",
              payload: {
                content,
                model: "gpt-3.5-turbo",
                partner: partner._id,
              },
            })
          );

          const content2 = getFunnelPrompt({
            jobName: res.data.name,
            description: res.data.description,
            keyBenefits: res.data.keyBenefits,
            requiredSkills: res.data.requiredSkills,
          });
          socket.current.send(
            JSON.stringify({
              id: "OPEN_AI_PROMPT",
              payload: {
                trackId: "2",
                content: content2,
                model: "gpt-3.5-turbo",
                partner: partner._id,
              },
            })
          );
        });

        socket.current.addEventListener("message", async (event) => {
          const message = JSON.parse(event.data);
          let formData = [];
          const response = message.payload?.response;

          if (message.payload?.trackId === "2") {
            const data = JSON.parse(response.replace(/\n\n/g, " "));
            if (!data?.valueProposition)
              data.valueProposition = t("We are hiring {{jobName}}", {
                jobName: res.data?.name ?? "",
              });
            await CrudService.update("Vacancy", id, data);
            await CrudService.getSingle("Vacancy", id).then((res) => {
              setVacancyData(res.data);
              if (!!res.data?.form && !!res.data?.valueProposition) {
                setThinking(false);
              }
            });
          } else {
            try {
              formData = JSON.parse(
                `[${response
                  .split("[")
                  ?.slice(1)
                  .join("[")
                  .split("]")
                  .slice(0, -1)
                  .join("]")}]`
              );
              if (!Array.isArray(formData)) throw new Error("Not an array");
            } catch (e) {}
            if (socketPing.current) clearInterval(socketPing.current);
            await CrudService.update("Vacancy", id, { form: formData });
            await CrudService.getSingle("Vacancy", id).then((res) => {
              setVacancyData(res.data);
              if (!!res.data?.form && !!res.data?.valueProposition) {
                setThinking(false);
              }
            });
          }
        });
      }
    });
  }, [searchParams, partner, t]);

  if (!vacancyData) return <Skeleton active />;
  if (thinking) return <Skeleton active />;
  if (!funnelSteps) return <Skeleton active />;

  return (
    <>
      <div>
        <MultiStepComponent
          AIEnhancements={true}
          displayUndoRedo
          steps={steps(eventTypes)}
          defaultFormData={{
            ...vacancyData,
          }}
          onFinish={async (formData) => {
            const id = searchParams.get("id");
            if (!id) return;

            await CrudService.update("Vacancy", id, {
              ...formData,
            });

            navigate(`/dashboard/vacancyeditForm?id=${id}`);
          }}
          onNext={async (formData) => {
            const id = searchParams.get("id");
            if (!id) return;

            await CrudService.update("Vacancy", id, {
              ...formData,
            });
          }}
          buttomLineWrapperClass="fixed bottom-0 w-full bg-white dark:bg-gray-900 rounded-t-sm pl-[20px] pt-[20px] pb-[20px] pr-[80px] right-0	bottom-0"
          buttomLineInnerClass="items-end flex flex-col"
        />
      </div>
    </>
  );
}
