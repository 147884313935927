export const currencies = [
  { title: "U.S. dollar", symbol: "$", iso: "USD" },
  { title: "Euro", symbol: "€", iso: "EUR" },
  { title: "Sterling", symbol: "£", iso: "GBP" },
  { title: "Australian dollar", symbol: "A$", iso: "AUD" },
  { title: "Canadian dollar", symbol: "C$", iso: "CAD" },
  { title: "Swiss franc", symbol: "CHF", iso: "CHF" },
  { title: "Indian rupee", symbol: "₹", iso: "INR" },
  { title: "UAE dirham", symbol: "د.إ", iso: "AED" },
  { title: "Japanese Yen", symbol: "¥", iso: "JPY" },
];
