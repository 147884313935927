import {
  Alert,
  Modal,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Spin,
  Table,
  Tabs,
  Tag,
  Timeline,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MultiStepComponent from "../../../../components/MultiStepComponent";
import {
  STANDARD_MOMENT_FORMAT,
  eeoForm,
  personalDataCollection,
} from "../../../../data/constants";
import { getPartner, selectLoading } from "../../../../redux/auth/selectors";
import ATSService from "../../../../service/ATSService";
import CrudService from "../../../../service/CrudService";
import PublicService from "../../../../service/PublicService";
import UploadService from "../../../../service/UploadService";
import CVTemplate from "../../../CandidateCV/CVTemplate";
import { Button } from "../../../Landing/Button";

const { TabPane } = Tabs;

function convertLinksToHTML(text) {
  var urlWithTitleRegex = /\{([^}]+)\}\[([^\]]+)\]/g;

  var replacedText = text.replace(
    urlWithTitleRegex,
    function (match, url, title) {
      return '<a href="' + url + '" target="_blank">' + title + "</a>";
    }
  );

  var urlRegex =
    /(?<!href="|href='|">)(https?:\/\/[^\s\n<]+)(?=[,.!?;]*($|\s|<))/g;

  replacedText = replacedText.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + "</a>";
  });

  return replacedText;
}

const getDocumentStatus = (document) => {
  if (!document?.candidateSignature) return "Pending";
  if (
    !document?.body?.includes?.(
      "@[Hiring Authority Signature](hiringAuthoritySignature)"
    )
  )
    return "Completed";
  if (document?.hiringManagerSignature) return "Completed";
  return "Lead Signed";
};

const getDocumentColor = (document) => {
  if (!document?.candidateSignature) return "orange";
  if (
    !document?.body?.includes?.(
      "@[Hiring Authority Signature](hiringAuthoritySignature)"
    )
  )
    return "green";
  if (document?.hiringManagerSignature) return "green";
  return "blue";
};

function downloadAndShowPDF(pdfUrl, setLocalPdfUrl) {
  fetch(pdfUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const localUrl = URL.createObjectURL(blob);
      setLocalPdfUrl(localUrl);
    })
    .catch((error) => console.error("Error downloading the PDF:", error));
}

const LOG_LOAD_PAGINATION = 25;

const DetailsModal = ({ candidateId }) => {
  const { t } = useTranslation();
  const partner = useSelector(getPartner);

  const [AILoading, setAILoading] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [vacancyData, setVacancyData] = useState(null);
  const [candidateTimeline, setCandidateTimeline] = useState([]);
  const [candidateTimelineTotal, setCandidateTimelineTotal] = useState(0);
  const [currentLogsPage, setCurrentLogsPage] = useState(1);
  const [CV, setCV] = useState(null);
  const [interviewScripts, setInterviewScripts] = useState([]);
  const [candidateDocuments, setCandidateDocuments] = useState([]);
  const [surveySubmission, setSurveySubmission] = useState(null);
  const [alternativePDFLocalURL, setAlternativePDFLocalURL] = useState(null);
  const [AIInstructions, setAIInstructions] = useState("");
  const [activeTab, setActiveTab] = useState("phoneCalls");
  const [callDetailModal, setCallDetailModal] = useState(null);
  const socket = useRef(null);
  const socketPing = useRef(null);
  const loading = useSelector(selectLoading);

  const fileInput = useRef(null);

  useEffect(() => {
    if (!candidateId) return;

    fileInput.current = document.getElementById("fileInput34");
    if (fileInput.current)
      fileInput.current.addEventListener("change", async () => {
        const selectedFile = fileInput.current.files[0];
        if (selectedFile) {
          const result = await UploadService.upload(selectedFile, 5);

          await ATSService.submitCV(candidateId, result.data.secure_url);

          CrudService.search("CV", 1, 1, {
            filters: { candidate: candidateId, submitted: true },
          }).then(({ data }) => {
            if (data.items?.[0]) setCV(data.items?.[0]);
            else setCV(false);
          });
          fileInput.current.files[0] = "";
        } else {
          console.log(t("No file selected."));
        }
      });
  }, [candidateId, t]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (CV?.alternativeUrl)
      downloadAndShowPDF(alternativePDFLocalURL, setAlternativePDFLocalURL);
  }, [CV]);

  useEffect(() => {
    if (!candidateId) return;

    CrudService.search("VacancySubmission", 1, 1, {
      filters: { _id: candidateId },
      populate: {
        path: "emailSent.user_id",
        select: "firstName lastName email",
      },
      populate2: {
        path: "smsSent.user_id",
        select: "firstName lastName email",
      },
    }).then(({ data }) => {
      if (data.items?.[0]) {
        setCandidate(data.items[0]);
        CrudService.search("Vacancy", 1, 1, {
          filters: { _id: data.items?.[0].VacancyId },
        }).then(({ data }) => {
          if (data.items?.[0]) setVacancyData(data.items?.[0]);
        });
      } else setCandidate(false);
    });

    CrudService.search("CV", 1, 1, {
      filters: { candidate: candidateId, submitted: true },
    }).then(({ data }) => {
      if (data.items?.[0]) setCV(data.items?.[0]);
      else setCV(false);
    });

    CrudService.search("SurveySubmission", 1, 1, {
      filters: { VacancySubmissionId: candidateId },
    }).then(({ data }) => {
      if (data.items?.[0]) setSurveySubmission(data.items?.[0]);
      else setSurveySubmission(false);
    });

    CrudService.search("InterviewScript", 1000, 1, {
      filters: { candidate: candidateId },
    }).then(({ data }) => {
      setInterviewScripts(data.items);
    });

    CrudService.search("CandidateLogs", LOG_LOAD_PAGINATION, 1, {
      filters: { candidate: candidateId },
      sort: { createdAt: -1 },
      populate: "user_id",
    }).then(({ data }) => {
      setCandidateTimeline(data.items);
      setCandidateTimelineTotal(data.total);
    });

    ATSService.getCandidateDocuments(candidateId).then(({ data }) =>
      setCandidateDocuments(data)
    );
  }, [candidateId]);

  const onLogsLoadMore = () => {
    CrudService.search(
      "CandidateLogs",
      LOG_LOAD_PAGINATION,
      currentLogsPage + 1,
      {
        filters: { candidate: candidateId },
        sort: { createdAt: -1 },
        populate: "user_id",
      }
    ).then(({ data }) => {
      setCandidateTimeline((a) => [...a, ...data.items]);
      setCandidateTimelineTotal(data.total);
    });
    setCurrentLogsPage((a) => a + 1);
  };

  const columnsPhoneCall = [
    {
      title: t("Timestamp"),
      key: "call.createdAt",
      dataIndex: "call.createdAt",
      render: (_, record) => (
        <>{moment(record?.call?.createdAt).format(STANDARD_MOMENT_FORMAT)}</>
      ),
    },
    {
      title: t("Recording"),
      key: "recordingUrl",
      dataIndex: "recordingUrl",
      render: (url) => <>{url && <audio src={url} controls />}</>,
    },
    {
      title: t("Details"),
      key: "details",
      dataIndex: "details",
      render: (_, record) => (
        <Button type="primary" onClick={() => setCallDetailModal(record)}>
          {t("Details")}
        </Button>
      ),
    },
  ];

  const requestInterview = () => {
    if (AILoading) return;
    if (candidate === null) return;
    if (CV === null) return;
    if (surveySubmission === null) return;

    socket.current = new WebSocket(
      `wss://booklified-chat-socket.herokuapp.com`
    );

    socket.current.addEventListener("open", async () => {
      socketPing.current = setInterval(
        () => socket.current.send(JSON.stringify({ id: "PING" })),
        30000
      );

      const content = `Hello, I am in the process of interviewing a lead and require your expertise in marketing to enhance the meeting structure and content. Please provide me with the following:

A breakdown of the meeting stages, including:
- The name of each step.
- A concise description for each step.
- The recommended duration for each step.
- A curated list of high-quality meeting questions that are:
-- Tailored specifically to the job position in question.
-- Based on the lead's application details.
-- Reflective of the lead's CV and experiences.
-- Explanation of the intent behind each question and the anticipated response from a suitable lead.

Please ensure that the questions are designed to elicit responses that demonstrate the lead's suitability for the role, their technical competencies, problem-solving abilities, and cultural fit. The goal is to create an meeting experience that is both thorough and insightful, allowing for an accurate assessment of the lead's potential for the position.

Here is the lead data:
${JSON.stringify(candidate)}

Here is the CV data:
${
  CV?.alternativeUrl
    ? (await PublicService.getPDFText(CV.alternativeUrl))?.data?.text?.slice?.(
        0,
        600
      )
    : JSON.stringify(CV)
}


${AIInstructions}

It is imperative that your reply contains nothing beyond the required meeting script. Don't start your response by answering to me. Instead get right into the script. Do not add anything else into your answer.
`;

      setAILoading(true);
      socket.current.send(
        JSON.stringify({
          id: "OPEN_AI_PROMPT",
          payload: {
            content,
            model: "gpt-3.5-turbo",
            partner: partner._id,
          },
        })
      );
    });

    socket.current.addEventListener("message", async (event) => {
      const message = JSON.parse(event.data);
      const response = message.payload?.response;

      await CrudService.create("InterviewScript", {
        message: response,
        candidate: candidateId,
      });
      await CrudService.search("InterviewScript", 1000, 1, {
        filters: { candidate: candidateId },
      }).then(({ data }) => {
        setInterviewScripts(data.items);
      });
      setAILoading(false);
      if (socketPing.current) clearInterval(socketPing.current);
    });
  };

  if (candidate === null) return <Skeleton active />;
  if (CV === null) return <Skeleton active />;
  if (surveySubmission === null) return <Skeleton active />;

  return (
    <>
      <h2 className="text-lg font-bold mb-1">{t("Lead Folder")}</h2>
      <div className="flex justify-between gap-5 mt-2 mb-5 items-center">
        <h3 className="text-md font-semibold mb-2 whitespace-nowrap">
          {candidate?.formData?.firstname} {candidate?.formData?.lastname}
        </h3>

        <Select
          mode="tags"
          className="w-full mb-1"
          size="small"
          placeholder={t("Tags")}
          value={candidate?.tags}
          onChange={async (e) => {
            setCandidate((x) => ({
              ...x,
              tags: e,
            }));
            await CrudService.update("VacancySubmission", candidate._id, {
              tags: e,
              searchIndex: `${candidate?.searchIndex ?? ""} ${e}`,
            });
          }}
        />
      </div>

      {candidate?.rejected && (
        <Alert
          type="error"
          message={t(
            "This lead has been marked as unqualified. Provided reason: {{reason}}",
            {
              reason: candidate?.rejectionReason ?? "",
            }
          )}
        />
      )}

      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab={t("AI Call Results")} key="phoneCalls">
          {candidate?.phoneCalls?.length > 0 ? (
            <>
              <Table
                rowKey="call_id"
                columns={columnsPhoneCall}
                dataSource={candidate.phoneCalls.sort(
                  (a, b) => new Date(b.end_at) - new Date(a.end_at)
                )}
                loading={loading}
              />

              <Modal
                open={callDetailModal}
                onCancel={() => setCallDetailModal(false)}
                footer={[]}
              >
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {t("Interview Notes")}
                    </h3>
                  </div>
                  <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-white px-4 py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("Summary")}
                        </dt>
                        <dd
                          className="mt-1 text-sm text-gray-900"
                          dangerouslySetInnerHTML={{
                            __html:
                              callDetailModal?.summary?.replace?.(
                                /\n/g,
                                "<br>"
                              ) ?? "",
                          }}
                        />
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          {t("Transcript")}
                        </dt>
                        <dd
                          className="mt-1 text-sm text-gray-900 space-y-4"
                          dangerouslySetInnerHTML={{
                            __html:
                              callDetailModal?.transcript?.replace?.(
                                /\n/g,
                                "<br>"
                              ) ?? "",
                          }}
                        />
                      </div>
                    </dl>
                  </div>
                </div>
              </Modal>
            </>
          ) : (
            <Alert type="info" message={t("No logs")} />
          )}
        </TabPane>
        <TabPane tab={t("Edit Contact")} key="EditContact">
          {candidate?.formData && (
            <MultiStepComponent
              steps={[
                {
                  id: "contact",
                  name: t("Contact Information"),
                  form: [...personalDataCollection],
                },
              ]}
              defaultFormData={
                candidate?.formData
                  ? {
                      ...candidate?.formData,
                      phone: `${candidate?.formData?.phone}` ?? "",
                    }
                  : []
              }
              loading={loading}
              displaySteps={false}
              finishText={t("Save")}
              wrapperClassName="px-5"
              onFinish={async (formData) => {
                await ATSService.updateCandidateDetails(candidateId, formData);
                await CrudService.search(
                  "CandidateLogs",
                  LOG_LOAD_PAGINATION,
                  1,
                  {
                    filters: { candidate: candidateId },
                    sort: { createdAt: -1 },
                    populate: "user_id",
                  }
                ).then(({ data }) => {
                  setCandidateTimeline(data.items);
                  setCandidateTimelineTotal(data.total);
                  setCurrentLogsPage(1);
                });
                await CrudService.search("VacancySubmission", 1, 1, {
                  filters: { _id: candidateId },
                }).then(({ data }) => {
                  if (data.items?.[0]) setCandidate(data.items?.[0]);
                  else setCandidate(false);
                });
              }}
            />
          )}
        </TabPane>
        <TabPane tab={t("Application Form")} key="ApplicationForm">
          {candidate?.formData && candidate?.form ? (
            <MultiStepComponent
              displaySteps={true}
              steps={[
                ...(Array.isArray(candidate.form)
                  ? candidate.form
                  : typeof candidate.form === "object"
                  ? Object.values(candidate.form)
                  : []),
                vacancyData?.eeodc
                  ? {
                      id: "eeodc",
                      name: t("EEODC"),
                      form: [
                        {
                          type: "custom",
                          CustomInputComponent: () => (
                            <>
                              <h2 className="font-bold text-lg">
                                {t("EEO Data Collection")}
                              </h2>
                              <Alert
                                type="info"
                                message={t("EEO_DATA_COLLECTION_MESSAGE")}
                              />
                            </>
                          ),
                        },
                        ...eeoForm,
                      ],
                    }
                  : null,
                {
                  id: "contact",
                  name: t("Contact Information"),
                  form: [...personalDataCollection],
                },
              ].filter((a) => !!a)}
              defaultFormData={candidate?.formData ? candidate?.formData : []}
              readOnly
            />
          ) : (
            <Alert
              type="info"
              message={t("Lead has not submitted the application form")}
            />
          )}
        </TabPane>

        <TabPane tab={t("Email History")} key="emailSent">
          {candidate?.emailSent?.length > 0 ? (
            <>
              <div className="flex flex-col space-y-4">
                {candidate.emailSent
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((sms) => (
                    <div key={sms._id}>
                      <div
                        className="bg-indigo-500 font-bold text-white py-2 px-4 rounded-t-lg mt-2"
                        dangerouslySetInnerHTML={{
                          __html: sms.subject?.replace?.(/\n/g, "<br>"),
                        }}
                      ></div>
                      <div
                        className="bg-indigo-500 text-white py-2 px-4 rounded-b-lg "
                        dangerouslySetInnerHTML={{
                          __html: convertLinksToHTML(sms.body)?.replace?.(
                            /\n/g,
                            "<br>"
                          ),
                        }}
                      ></div>
                      <div className="text-right text-xs text-gray-400">
                        <div className="text-right text-xs text-gray-400">
                          {sms?.user_id?.email ? (
                            <>
                              {sms?.user_id?.firstName ?? ""}{" "}
                              {sms?.user_id?.lastName ?? ""} (
                              {sms?.user_id?.email ?? ""})
                            </>
                          ) : (
                            t("System")
                          )}
                        </div>
                      </div>
                      <div className="text-right text-xs text-gray-400 mt-1">
                        {moment(sms.timestamp).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <Alert
              type="info"
              message={t("Lead did not receive any emails from you")}
            />
          )}
        </TabPane>
        <TabPane tab={t("SMS History")} key="smsSent">
          {candidate?.smsSent?.length > 0 ? (
            <>
              <div className="flex flex-col space-y-4">
                {candidate.smsSent
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((sms) => (
                    <div key={sms._id}>
                      <div
                        className="bg-indigo-500 text-white py-2 px-4 rounded-lg mt-2"
                        dangerouslySetInnerHTML={{
                          __html: sms.text?.replace?.(/\n/g, "<br>"),
                        }}
                      ></div>
                      <div className="text-right text-xs text-gray-400">
                        {sms?.user_id?.email ? (
                          <>
                            {sms?.user_id?.firstName ?? ""}{" "}
                            {sms?.user_id?.lastName ?? ""} (
                            {sms?.user_id?.email ?? ""})
                          </>
                        ) : (
                          t("System")
                        )}
                      </div>
                      <div className="text-right text-xs text-gray-400 mt-1">
                        {moment(sms.timestamp).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <Alert
              type="info"
              message={t("Lead did not receive any SMS messages from you")}
            />
          )}
        </TabPane>

        <TabPane tab={t("Tracking")} key="Tracking">
          <div className="p-4">
            <table className="w-full border-collapse">
              <tbody>
                {Object.entries(candidate?.tracking ?? {})
                  .filter((e) => e?.[0] !== "_id")
                  .map(([key, value]) => (
                    <tr
                      key={key}
                      className="border-t border-gray-200 dark:border-gray-600 "
                    >
                      <td className="py-2 pr-4 font-semibold">{t(key)}:</td>
                      <td className="py-2">{value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </TabPane>

        <TabPane tab={t("Logs")} key="Logs">
          {candidateTimeline.length > 0 ? (
            <div>
              <div>
                <Timeline
                  items={candidateTimeline.map((te) => ({
                    color: te.color,
                    children: (
                      <>
                        <div>{te.message ?? ""}</div>
                        <div className="text-sm">
                          {t("Performed by")}: {te.user_id?.firstName ?? ""}{" "}
                          {te.user_id?.lastName ?? ""} (
                          {te.user_id?.email ?? ""})
                        </div>
                        <div className="text-xs">
                          {moment(te.createdAt).format(STANDARD_MOMENT_FORMAT)}
                        </div>
                      </>
                    ),
                  }))}
                />
              </div>

              {candidateTimelineTotal >=
                LOG_LOAD_PAGINATION * currentLogsPage && (
                <div className="flex justify-center mt-5">
                  <Button loading={loading} onClick={onLogsLoadMore}>
                    {t("Load more")}
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <Alert type="info" message={t("Nothing to show")} />
          )}
        </TabPane>
      </Tabs>
    </>
  );
};

export default DetailsModal;
