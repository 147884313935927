import { Rate, Space } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AiOutlineSchedule } from "react-icons/ai";
import {
  FaBusinessTime,
  FaCheck,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";
import { FaNoteSticky, FaPhone, FaRegCalendarCheck } from "react-icons/fa6";
import { IoFileTrayStackedOutline } from "react-icons/io5";
import { MdBlock, MdOutlineMail, MdOutlineTextsms } from "react-icons/md";
import { RiShareForwardBoxFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { getPartner } from "../../../../redux/auth/selectors";

export default function ({
  children: card,
  dragging,
  allowRemoveCard,
  onCardRemove,
  onCardOptionClick,
}) {
  const partner = useSelector(getPartner);
  const { t } = useTranslation();

  return (
    <div
      className={`react-kanban-card dark:bg-gray-700 ${
        card.rejected ? "bg-red-100 dark:bg-red-800" : ""
      } ${dragging ? "react-kanban-card--dragging" : ""}`}
    >
      <span>
        <div className="react-kanban-card__title">
          <span>{card.fullname}</span>
          {allowRemoveCard && (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => onCardRemove(card)}
            >
              ×
            </span>
          )}
          <Space>
            <a
              title={t("Send Email")}
              onClick={() => onCardOptionClick(card.id, "email")}
            >
              <MdOutlineMail />
            </a>
            {partner?.calendlyclientId && (
              <a
                href="#"
                title={t("Schedule")}
                onClick={() => onCardOptionClick(card.id, "schedule")}
              >
                <FaRegCalendarCheck />
              </a>
            )}

            {card.phone && partner?.twilioAccountSID && (
              <a
                href="#"
                title={t("Send SMS")}
                onClick={() => onCardOptionClick(card.id, "sms")}
              >
                <MdOutlineTextsms />
              </a>
            )}

            {card.phone && (
              <a
                href={`https://wa.me/${card.phone}`}
                target="_blank"
                title={t("Whatsapp")}
              >
                <FaWhatsapp />
              </a>
            )}

            {card.phone &&
              partner?.twilioAccountSID &&
              partner?.twilioTwimlAppSID && (
                <div
                  className="cursor-pointer"
                  title={t("Call")}
                  onClick={() => onCardOptionClick(card.id, "phone")}
                >
                  <FaPhone />
                </div>
              )}
            {!card.rejected && (
              <a
                href="#"
                title={t("Reject")}
                onClick={() => onCardOptionClick(card.id, "reject")}
              >
                <MdBlock />
              </a>
            )}
            {card.rejected && (
              <a
                href="#"
                title={t("Undo Reject")}
                onClick={() => onCardOptionClick(card.id, "undo-reject")}
              >
                <FaCheck />
              </a>
            )}
          </Space>
        </div>
      </span>
      <div className="react-kanban-card__description">
        <div className="flex justify-between items-end">
          <Rate
            value={card.stars ?? 0}
            onChange={(e) => onCardOptionClick(card.id, "stars-pick", { e })}
          />
          <Space>
            <a
              href="#"
              title={t("Lead Notes")}
              onClick={(e) => onCardOptionClick(card.id, "open-note")}
            >
              <FaNoteSticky />
            </a>
            <a
              href="#"
              title={t("Details")}
              onClick={(e) => onCardOptionClick(card.id, "details-modal")}
            >
              <IoFileTrayStackedOutline />
            </a>
            <a
              href="#"
              title={t("LinkedIn")}
              onClick={(e) => {
                window.open(
                  card?.linkedInUrl?.includes?.("https://")
                    ? `${
                        card?.linkedInUrl?.includes?.("https") ? "" : "https://"
                      }${card?.linkedInUrl}`
                    : `https://www.linkedin.com/search/results/all/?keywords=${card.fullname}`
                );
              }}
            >
              <FaLinkedin />
            </a>
          </Space>
        </div>
        <div>
          <Space>
            <FaBusinessTime />
            <div>
              {moment(card.createdAt).calendar(null, {
                sameDay: t("Today"),
                lastDay: t("Yesterday"),
                lastWeek: "dddd",
                sameElse: "MMM D, YYYY",
              })}
            </div>
          </Space>
        </div>
        {card?.scheduledAt && new Date(card.scheduledAt) > new Date() && (
          <div>
            <Space>
              <AiOutlineSchedule />
              <div>
                {moment(card.scheduledAt).calendar(null, {
                  sameDay: t("Today at") + " LT",
                  lastDay: t("Yesterday"),
                  lastWeek: "dddd",
                  sameElse: "MMM D, YYYY",
                })}
              </div>
            </Space>
          </div>
        )}
      </div>
    </div>
  );
}
