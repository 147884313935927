import { Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function ColumnTitle({ allowRenameColumn, onClick, children: title }) {
  return allowRenameColumn ? (
    <span style={{ cursor: "pointer" }} onClick={onClick}>
      {title}
    </span>
  ) : (
    <span>{title}</span>
  );
}

function useRenameMode(state) {
  const [renameMode, setRenameMode] = useState(state);

  function toggleRenameMode() {
    setRenameMode(!renameMode);
  }

  return [renameMode, toggleRenameMode];
}

export default function ({
  children: column,
  allowRemoveColumn,
  onColumnRemove,
  allowRenameColumn,
  onColumnRename,
}) {
  const [renameMode, toggleRenameMode] = useRenameMode(false);
  const [titleInput, setTitleInput] = useState("");
  const { t } = useTranslation();

  function handleRenameColumn(event) {
    event.preventDefault();

    onColumnRename(column, titleInput);
    toggleRenameMode();
  }

  function handleRenameMode() {
    setTitleInput(column.title);
    toggleRenameMode();
  }

  return (
    <div className="react-kanban-column-header">
      {renameMode ? (
        <form onSubmit={handleRenameColumn}>
          <span>
            <input
              type="text"
              value={titleInput}
              className="dark:bg-gray-900"
              onChange={({ target: { value } }) => setTitleInput(value)}
              autoFocus
            />
          </span>
          <span>
            <button
              className="react-kanban-column-header__button"
              type="submit"
            >
              {t("Rename")}
            </button>
            <button
              className="react-kanban-column-header__button"
              type="button"
              onClick={handleRenameMode}
            >
              {t("Cancel")}
            </button>
          </span>
        </form>
      ) : (
        <>
          <ColumnTitle
            allowRenameColumn={allowRenameColumn}
            onClick={handleRenameMode}
          >
            <Typography.Paragraph
              editable={
                column.id === "UNCATEGORIZED"
                  ? null
                  : {
                      onChange: (e) => onColumnRename(column.id, e),
                      text: column.title,
                    }
              }
            >
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: 150,
                }}
              >
                {column.title}
              </div>
            </Typography.Paragraph>
          </ColumnTitle>
          {allowRemoveColumn && (
            <span onClick={() => onColumnRemove(column)}>×</span>
          )}
        </>
      )}
    </div>
  );
}
