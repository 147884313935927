import Cookies from "js-cookie";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../redux/auth/actions";
import { store } from "../../redux/store";
import AuthService from "../../service/AuthService";

import { useSelector } from "react-redux";
import { getPartner, selectLoading } from "../../redux/auth/selectors";
import ATSService from "../../service/ATSService";
import { Button } from "../Landing/Button";
import { TextField } from "../Landing/Fields";
import { Logo } from "../Landing/Logo";
import { SlimLayout } from "../Landing/SlimLayout";

const Login = () => {
  const partner = useSelector(getPartner);
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await AuthService.login({
      email: e.target[0].value,
      password: e.target[1].value,
    });
    if (!result?.data?.accessToken) return;

    Cookies.set("accessToken", result?.data?.accessToken);
    Cookies.set("refreshToken", result?.data?.refreshToken);

    const me = await AuthService.me();
    if (!me?.data) return message.error(t("Could not load user data"));

    store.dispatch(login(me.data.me));

    if (localStorage.demoVacancy && localStorage.demoVacancy !== "undefined") {
      const vacancy = JSON.parse(localStorage.demoVacancy);

      await ATSService.createVacancy({
        vacancyTemplate: vacancy,
      });

      localStorage.demoVacancy = undefined;
    }

    navigate("/dashboard/vacancy");
  };

  return (
    <div className="flex h-[100vh] flex-col">
      <SlimLayout>
        <div className="flex">
          <Link href="/" aria-label={t("Home")}>
            <Logo className="h-10 w-auto" />
          </Link>
        </div>
        <h2 className="mt-20 text-lg font-semibold text-gray-900 dark:text-gray-400 ">
          {t("Sign in to your account")}
        </h2>
        <p className="mt-2 text-sm text-gray-700 dark:text-gray-300 ">
          {t("Don't have an account?")}{" "}
          <Link
            to="/auth/register"
            className="font-medium text-blue-600 hover:underline"
          >
            {t("Sign up")}
          </Link>{" "}
          {partner?.trialDays > 0
            ? t("for a {{trialDays}} day free trial.", {
                trialDays: partner.trialDays,
              })
            : t("to get started.")}
        </p>
        <form
          action="#"
          className="mt-10 grid grid-cols-1 gap-y-8"
          onSubmit={handleSubmit}
        >
          <TextField
            label={t("Email address")}
            name="email"
            type="email"
            autoComplete="email"
            required
          />
          <TextField
            label={t("Password")}
            name="password"
            type="password"
            autoComplete="current-password"
            required
          />
          <div>
            <Button
              type="submit"
              variant="solid"
              color="blue"
              className="w-full"
              loading={loading}
            >
              <span>
                {t("Sign in")} <span aria-hidden="true">&rarr;</span>
              </span>
            </Button>
          </div>
          <div className="text-center text-sm">
            <Link to="/auth/forgot" className="text-indigo-500 font-semibold">
              {t("Forgot password?")}
            </Link>
          </div>
        </form>
      </SlimLayout>
    </div>
  );
};

export default Login;
