import { Popconfirm, Skeleton, Space, Spin, Typography } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { STANDARD_MOMENT_FORMAT } from "../../data/constants";
import { selectLoading } from "../../redux/auth/selectors";
import ATSService from "../../service/ATSService";
import CrudService from "../../service/CrudService";
import PublicService from "../../service/PublicService";

const FunnelSteps = ({
  handleNavigate,
  nextStepDrawer,
  handleNextStepSelect,
}) => {
  const { t } = useTranslation();
  let { id } = useParams();

  const [steps, setSteps] = useState(null);
  const loading = useSelector(selectLoading);

  useEffect(() => {
    if (!id || id === "undefined" || id === "demo") return;

    PublicService.getFunnelSteps(id).then((res) => {
      setSteps(res.data?.vacancies);
    });
  }, [id]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = useCallback(
    async (result) => {
      if (loading) return;

      if (!result.destination) {
        return;
      }

      const newOrder = reorder(
        steps,
        result.source.index,
        result.destination.index
      );

      setSteps(newOrder);

      await ATSService.setFunnelSteps(id, newOrder);

      await PublicService.getFunnelSteps(id).then((res) => {
        setSteps(res.data?.vacancies);
      });
    },
    [loading, steps, id]
  );

  const removeItem = async (index) => {
    if (loading) return;
    const newOrder = [...steps];
    newOrder.splice(index, 1);

    setSteps(newOrder);

    await ATSService.setFunnelSteps(id, newOrder);

    await PublicService.getFunnelSteps(id).then((res) => {
      setSteps(res.data?.vacancies);
    });
  };

  const addItem = useCallback(async () => {
    if (loading) return;
    const res = await ATSService.createVacancy({
      vacancyTemplate: {
        isSecondary: true,
        name: moment().format(STANDARD_MOMENT_FORMAT),
        funnelStepName: t("Page #{{number}}", { number: steps.length + 1 }),
        pageContent: [],
      },
    });

    await ATSService.setFunnelSteps(id, [
      ...steps.map((a) => a._id),
      res.data.vacancy._id,
    ]);

    await PublicService.getFunnelSteps(id).then((res) => {
      setSteps(res.data?.vacancies);
    });
  }, [loading, steps, t]);

  if (!Array.isArray(steps)) return <Skeleton active />;
  return (
    <>
      <div className="w-full p-5">
        <div className="font-bold text-lg mb-1">
          {nextStepDrawer
            ? t("Select the step to forward user into after form submission")
            : t("Funnel Steps")}
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {steps.map((item, index) => {
                  return (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`flex items-center justify-between p-2 container rounded-md ${
                            item?._id === id && !nextStepDrawer
                              ? "bg-indigo-100"
                              : nextStepDrawer &&
                                item?._id === nextStepDrawer.currentNextStep
                              ? "bg-indigo-100"
                              : ""
                          }`}
                        >
                          <span
                            className={`flex-grow flex items-center space-x-3 mt-2 ${
                              nextStepDrawer
                                ? "justify-center cursor-pointer"
                                : ""
                            }`}
                            onClick={() => {
                              if (handleNextStepSelect)
                                handleNextStepSelect(item._id);
                            }}
                          >
                            {!nextStepDrawer && (
                              <div className="mb-2">
                                <FaAngleUp
                                  className="cursor-pointer"
                                  onClick={async () => {
                                    if (index === 0) return;
                                    const newOrder = reorder(
                                      steps,
                                      index,
                                      index - 1
                                    );
                                    setSteps(newOrder);

                                    await ATSService.setFunnelSteps(
                                      id,
                                      newOrder
                                    );

                                    await PublicService.getFunnelSteps(id).then(
                                      (res) => {
                                        setSteps(res.data?.vacancies);
                                      }
                                    );
                                  }}
                                />
                                <FaAngleDown
                                  className="cursor-pointer"
                                  onClick={async () => {
                                    if (index === steps.length - 1) return;
                                    const newOrder = reorder(
                                      steps,
                                      index,
                                      index + 1
                                    );
                                    setSteps(newOrder);

                                    await ATSService.setFunnelSteps(
                                      id,
                                      newOrder
                                    );

                                    await PublicService.getFunnelSteps(id).then(
                                      (res) => {
                                        setSteps(res.data?.vacancies);
                                      }
                                    );
                                  }}
                                />
                              </div>
                            )}
                            <Typography.Paragraph
                              editable={
                                nextStepDrawer
                                  ? undefined
                                  : {
                                      onChange: async (e) => {
                                        setSteps((v) =>
                                          v.map((a) =>
                                            a._id === item._id
                                              ? {
                                                  ...a,
                                                  funnelStepName: e,
                                                }
                                              : a
                                          )
                                        );

                                        await CrudService.update(
                                          "Vacancy",
                                          item._id,
                                          {
                                            funnelStepName: e,
                                          }
                                        );
                                      },
                                    }
                              }
                            >
                              {t(item?.funnelStepName)}
                            </Typography.Paragraph>
                          </span>

                          {!nextStepDrawer && (
                            <Space>
                              {id !== item?._id && (
                                <Link
                                  to={`/page/${item._id}`}
                                  onClick={handleNavigate}
                                >
                                  <button className="bg-indigo-500 text-white font-bold py-1 px-2 rounded">
                                    {t("Open")}
                                  </button>
                                </Link>
                              )}

                              <Popconfirm
                                title={t("Are you sure?")}
                                onConfirm={() => removeItem(index)}
                              >
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                                  {t("Remove")}
                                </button>
                              </Popconfirm>
                            </Space>
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="flex justify-end w-full">
          {nextStepDrawer ? (
            <button
              onClick={() => handleNextStepSelect(null)}
              className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              <div className="flex gap-1 items-center justify-center">
                {loading && <Spin />}
                <div>{t("Clear Selection")}</div>
              </div>
            </button>
          ) : (
            <button
              onClick={addItem}
              className="mt-4 bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
            >
              <div className="flex gap-1 items-center justify-center">
                {loading && <Spin />}
                <div>{t("Add Step")}</div>
              </div>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default FunnelSteps;
