import { v4 as uuidv4 } from "uuid";

export const templates = ({ user, images = [] }) => ({
  default: `{
      "name": "ランディングページの意味のある名前",
      "pageContent": [
        {
          "id": "HeadlineComponent",
          "data": {
            "description": [
              {
                "id": "4be090b3-b49c-42a6-826a-c1a311225635",
                "type": "DivComponent",
                "components": [
                  {
                    "id": "9fb945d9-317b-4a30-88f6-5742248b6dd3",
                    "type": "TextComponent",
                    "content": {
                      "text": "[ここに短い説明的な見出しを入力してください]",
                      "className": "text-center text-5xl uppercase font-extrabold font-sans"
                    }
                  }
                ],
                "content": {
                  "className": "container-sm mdx:p-5 smx:px-5 pl-8 pr-8 flex gap-6"
                }
              }
            ]
          }
        },
        {
          "id": "Hero",
          "data": {
            "description": [
              {
                "type": "DivComponent",
                "id": "${uuidv4()}",
                "content": {
                  "className": ""
                },
                "components": [
                  {
                    "type": "TextComponent",
                    "id": "${uuidv4()}",
                    "content": {
                      "text": "注目を集める素晴らしい見出し",
                      "className":
                        "text-gray-900_02 text-[45px] font-semibold md:text-[41px] sm:text-[35px] text-gray-900_02"
                    }
                  },
                  {
                    "type": "TextComponent",
                    "id": "${uuidv4()}",
                    "content": {
                      "text": "5～10語のサブライン",
                      "className": "text-[#475467] font-semibold smx:text-sm text-base"
                    }
                  },
                  {
                    "type": "TextComponent",
                    "id": "${uuidv4()}",
                    "content": {
                      "text": "サービスや製品の詳細な説明",
                      "className": "text-[#475467] font-semibold smx:text-sm text-base"
                    }
                  }
                ]
              }],
            "image": "${
              images?.[0 % 6] ?? "/dhimages/img_screen_mockup_replace.png"
            }",
            "video": "https://player.vimeo.com/video/937499561?h=a4a0716db0",
            "benefits": [
              {
                "text": "5～10語のメリット"
              },
              {
                "text": "5～10語のメリット"
              },
              {
                "text": "5～10語のメリット"
              }
            ]
          }
        },
        {
          "id": "CallToAction",
          "data": {
            "headline": "コアバリュープロポジションとそれが顧客にもたらすもの",
            "description": "今すぐ始めるべき理由の詳細な説明",
            "secondaryButtonText": "お問い合わせ",
            "secondaryButtonLink": "#contact",
            "primaryButtonText": "デモを予約する",
            "primaryButtonLink": "${
              user?.userCalendarLink ?? "https://calendly.com/"
            }"
          }
        },
         {
            "id": "CustomerPainPoints",
            "data": {
              "featuredShowcase1Text": "最大のデジタルマーケティング課題を克服する",
              "featuredShowcase2Text":  "適切なオンラインプレゼンスがビジネスの成功を導きます。",
              "featuredShowcase3Text": "複数の広告キャンペーンを運営するには、無数のコピーライティングクリエイティブを作成し、何が効果的かを確認するために絶えずA/Bテストを行う必要があります。",
            "featuredShowcase1Img": "${
              images?.[1 % 6] ?? "/dhimages/img_image_1.png"
            }",
              "featuredShowcase4Text": "ゼロからランディングページを作成することは時間がかかり、技術的に要求されます。",
              "featuredShowcase5Text": "当社のプラットフォームでは、ドラッグアンドドロップの使いやすさで、コーディング不要で数日ではなく数分でランディングページを生成およびカスタマイズできます。",
            "featuredShowcase2Img": "${
              images?.[2 % 6] ?? "/dhimages/img_image_1.png"
            }",
              "featuredShowcase6Text": "ウェビナーのコンテンツ作成から技術的セットアップまで数週間かかります。",
              "featuredShowcase7Text":  "当社のプラットフォームにはAIアバターと事前構築済みのウェビナーテンプレートが含まれており、ライブ配信が簡単でインパクトがあり、視聴者を引き込みます。",
            "featuredShowcase3Img": "${
              images?.[3 % 6] ?? "/dhimages/img_image_1.png"
            }",
              "featuredShowcase8Text": "顧客に響く魅力的なメールシーケンスを作成することは、エンゲージメントと保持に不可欠です。",
              "featuredShowcase9Text": "当社のAIは、個別に調整されたメールシーケンスを作成し、メッセージングが常に適切に行われるように支援します。",
            "featuredShowcase4Img": "${
              images?.[4 % 6] ?? "/dhimages/img_image_1.png"
            }"
            }
          },
      

        {
          "id": "AdminFeatures",
          "data": {
            "title": "機能カテゴリーのタイトル",
            "features": [
              {
                "description": "機能の説明 (5～10語)"
              },
              {
                "description": "機能の説明 (5～10語)"
              },
              {
                "description": "機能の説明 (5～10語)"
              }
            ],
            "image": "${images?.[3 % 6] ?? "/dhimages/img_image_1.png"}"
          }
        },
        {
          "id": "AdminFeatures",
          "data": {
            "title": "機能カテゴリーのタイトル",
            "features": [
              {
                "description": "機能の説明 (5～10語)"
              },
              {
                "description": "機能の説明 (5～10語)"
              },
              {
                "description": "機能の説明 (5～10語)"
              }
            ],
            "imageLeft": "${
              images?.[4 % 6] ?? "/dhimages/img_image_500x594.png"
            }"
          }
        },
        {
          "id": "AdminFeatures",
          "data": {
            "title": "機能カテゴリーのタイトル",
            "features": [
              {
                "description": "機能の説明 (5～10語)"
              },
              {
                "description": "機能の説明 (5～10語)"
              },
              {
                "description": "機能の説明 (5～10語)"
              }
            ],
            "image": "${images?.[5 % 6] ?? "/dhimages/img_image_1.png"}"
          }
        },
        {
          "id": "TestemonialSectionSlider",
          "data": {
            "testimonials": [
              {
                "title": "お客様の声セクションのタイトル",
                "description": "お客様が私たちについて語っている内容の要約",
                "rate": "5.0",
                "totalReviewers": 200
              },
              {
                "title": "お客様の声セクションのタイトル",
                "description": "お客様が私たちについて語っている内容の要約",
                "rate": "5.0",
                "totalReviewers": 58
              }
            ]
          }
        },
   
        {
          "id": "DivideLine"
        },
        {
          "id": "LeadForm",
          "data": {
            "headline": "最新情報に登録する",
            "description": "チームからの最新ニュースをお届けします。",
            "privacyPolicyLink": "${user?.privacyPolicyLink ?? "#"}"
          }
        },
        {
          "id": "DivideLine"
        }
      ]
    }`,
  secondary: `{
      "name": "ランディングページの意味のある名前",
      "pageContent": [
              {
          "id": "CustomComponent",
          "data": {
            "description": [
              {
                "id": "4be090b3-b49c-42a6-826a-c1a311225635",
                "type": "DivComponent",
                "components": [
                  {
                    "id": "9fb945d9-317b-4a30-88f6-5742248b6dd3",
                    "type": "TextComponent",
                    "content": {
                      "text": "[ここに短い説明的な見出しを入力してください]",
                      "className": "text-center text-5xl uppercase font-extrabold font-sans"
                    }
                  }
                ],
                "content": {
                  "className": "container-sm mdx:p-5 smx:px-5 pl-8 pr-8 flex gap-6"
                }
              }
            ]
          }
        },
        {
          "id": "Hero",
          "data": {
            "description": [
              {
                "type": "DivComponent",
                "id": "${uuidv4()}",
                "content": {
                  "className": ""
                },
                "components": [
                  {
                    "type": "TextComponent",
                    "id": "${uuidv4()}",
                    "content": {
                      "text": "注目を集める素晴らしい見出し",
                      "className":
                        "text-gray-900_02 text-[45px] font-semibold md:text-[41px] sm:text-[35px] text-gray-900_02"
                    }
                  },
                  {
                    "type": "TextComponent",
                    "id": "${uuidv4()}",
                    "content": {
                      "text": "5～10語のサブライン",
                      "className": "text-[#475467] font-semibold smx:text-sm text-base"
                    }
                  },
                  {
                    "type": "TextComponent",
                    "id": "${uuidv4()}",
                    "content": {
                      "text": "サービスや製品の詳細な説明",
                      "className": "text-[#475467] font-semibold smx:text-sm text-base"
                    }
                  }
                ]
              }],
            "image": "${
              images?.[0 % 6] ?? "/dhimages/img_screen_mockup_replace.png"
            }",
            "video": "https://player.vimeo.com/video/937499561?h=a4a0716db0",
            "benefits": [
              {
                "text": "5～10語のメリット"
              },
              {
                "text": "5～10語のメリット"
              },
              {
                "text": "5～10語のメリット"
              }
            ]
          }
        },
          {
            "id": "CustomerPainPoints",
            "data": {
              "featuredShowcase1Text": "最大のデジタルマーケティング課題を克服する",
              "featuredShowcase2Text":  "適切なオンラインプレゼンスがビジネスの成功を導きます。",
              "featuredShowcase3Text": "複数の広告キャンペーンを運営するには、無数のコピーライティングクリエイティブを作成し、何が効果的かを確認するために絶えずA/Bテストを行う必要があります。",
            "featuredShowcase1Img": "${
              images?.[1 % 6] ?? "/dhimages/img_image_1.png"
            }",
              "featuredShowcase4Text": "ゼロからランディングページを作成することは時間がかかり、技術的に要求されます。",
              "featuredShowcase5Text": "当社のプラットフォームでは、ドラッグアンドドロップの使いやすさで、コーディング不要で数日ではなく数分でランディングページを生成およびカスタマイズできます。",
            "featuredShowcase2Img": "${
              images?.[2 % 6] ?? "/dhimages/img_image_1.png"
            }",
              "featuredShowcase6Text": "ウェビナーのコンテンツ作成から技術的セットアップまで数週間かかります。",
              "featuredShowcase7Text":  "当社のプラットフォームにはAIアバターと事前構築済みのウェビナーテンプレートが含まれており、ライブ配信が簡単でインパクトがあり、視聴者を引き込みます。",
            "featuredShowcase3Img": "${
              images?.[3 % 6] ?? "/dhimages/img_image_1.png"
            }",
              "featuredShowcase8Text": "顧客に響く魅力的なメールシーケンスを作成することは、エンゲージメントと保持に不可欠です。",
              "featuredShowcase9Text": "当社のAIは、個別に調整されたメールシーケンスを作成し、メッセージングが常に適切に行われるように支援します。",
            "featuredShowcase4Img": "${
              images?.[4 % 6] ?? "/dhimages/img_image_1.png"
            }"
            }
          },
            {
          "id": "FeatureSlider",
          "data": {
            "slides": [
              {
            "image": "${
              images?.[1 % 6] ??
              "/dhimages/img_screen_mockup_replace_221x333.png"
            }",
                "title": "製品/サービスの機能のタイトル",
                "description": "製品/サービスの機能の説明"
              },
              {
            "image": "${
              images?.[2 % 6] ??
              "/dhimages/img_screen_mockup_replace_221x333.png"
            }",
                "title": "製品/サービスの機能のタイトル",
                "description": "製品/サービスの機能の説明"
              }
            ]
          }
        },
          {
            "id": "Challenges",
            "data": {
              "challenges": [
                {
                  "name": "公平なスクリーニング",
                  "content": "最初のコンタクトを取って始めるのは時間がかかります。候補者がいることを確認するためにフォローアップする必要さえあります。心配いりません！ あなたのAIエージェントは、予約、メッセージ送信、フォローアップを行い、プロセスを効率化し、インタビューを最大化し、あらゆるソーシングまたはマーケティングの取り組みを最適化します。",
            "image": "${
              images?.[5 % 6] ??
              "/dhimages/img_screen_mockup_replace_280x420.png"
            }"
                },
                {
                  "name": "効率的なレポート作成",
                  "content": "最初のコンタクトを取って始めるのは時間がかかります。候補者がいることを確認するためにフォローアップする必要さえあります。心配いりません！ あなたのAIエージェントは、予約、メッセージ送信、フォローアップを行い、プロセスを効率化し、インタビューを最大化し、あらゆるソーシングまたはマーケティングの取り組みを最適化します。",
            "image": "${
              images?.[6 % 6] ??
              "/dhimages/img_screen_mockup_replace_280x420.png"
            }"
                }
              ],
              "heading": "Hireheroesはすべてのリクルーターの課題に立ち向かいます",
              "title": "Hireheroesは、コストを削減し、時間を節約し、質の高い候補者を提供し、候補者の体験を向上させるために、AIと自動化を組み合わせています。",
              "subline": "例: 100人の応募者がいる求人投稿"
            }
          },
          {
            "id": "LeadForm",
            "data": {
              "headline": "最新情報に登録する",
              "description": "チームからの最新ニュースをお届けします。",
              "privacyPolicyLink": "${user?.privacyPolicyLink ?? "#"}"
            }
          },
          {
            "id": "AdminFeatures",
            "data": {
              "title": "機能カテゴリーのタイトル",
              "features": [
                {
                  "description": "機能の説明 (5～10語)"
                },
                {
                  "description": "機能の説明 (5～10語)"
                },
                {
                  "description": "機能の説明 (5～10語)"
                }
              ],
              "imageLeft": "${images?.[9 % 6] ?? "/dhimages/img_image_1.png"}"
            }
          },
          {
            "id": "AdminFeatures",
            "data": {
              "title": "機能カテゴリーのタイトル",
              "features": [
                {
                  "description": "機能の説明 (5～10語)"
                },
                {
                  "description": "機能の説明 (5～10語)"
                },
                {
                  "description": "機能の説明 (5～10語)"
                }
              ],
              "image": "${
                images?.[10 % 6] ?? "/dhimages/img_image_500x594.png"
              }"
            }
          },
          {
            "id": "AdminFeatures",
            "data": {
              "title": "機能カテゴリーのタイトル",
              "features": [
                {
                  "description": "機能の説明 (5～10語)"
                },
                {
                  "description": "機能の説明 (5～10語)"
                },
                {
                  "description": "機能の説明 (5～10語)"
                }
              ],
              "imageLeft": "${images?.[11 % 6] ?? "/dhimages/img_image_1.png"}"
            }
          },
          {
            "id": "DivideLine"
          },
          {
            "id": "Benefits",
            "data": {
              "title": "セクション全体のメリットを説明する意味のあるタイトル",
              "description": "コアメリットの説明",
              "benefits": [
                {
                  "emoji": "🚀",
                  "title": "メリットのタイトル (2～5語)"
                },
                {
                  "emoji": "🚀",
                  "title": "メリットのタイトル (2～5語)"
                },
                {
                  "emoji": "🚀",
                  "title": "メリットのタイトル (2～5語)"
                },
                {
                  "emoji": "🚀",
                  "title": "メリットのタイトル (2～5語)"
                }
              ]
            }
          },
          {
            "id": "DivideLine"
          },
          {
            "id": "ValueProp",
            "data": {
              "title": "私たちの約束セクションのタイトル",
              "description": "私たちの約束の説明",
              "image": "${images?.[12 % 6] ?? "/dhimages/img_image.png"}"
            }
          },
          {
            "id": "DivideLine"
          },
          {
            "id": "LeadForm",
            "data": {
              "headline": "最新情報に登録する",
              "description": "チームからの最新ニュースをお届けします。",
              "privacyPolicyLink": "${user?.privacyPolicyLink ?? "#"}"
            }
          },
          {
            "id": "DivideLine"
          }
        ]
    }`,
});
